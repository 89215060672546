(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('quiz', {
			parent : 'entity',
			url : '/quiz',
			data : {
				authorities : [ 'ROLE_ADMIN' ]
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'title,asc',
					squash : true
				}
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/quiz/quiz.html',
					controller : 'QuizController',
					controllerAs : 'vm'
				}
			},
			resolve : {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],               	
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('quiz');
                    return $translate.refresh();
                }]
			}
         })
		.state('quiz.new',{
			parent : 'quiz',
			url : '/new',
			views : {
				'content@' : {
					templateUrl : 'app/entities/quiz/quiz-edit.html',
					controller : 'QuizEditController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : ['$translate','$translatePartialLoader', function($translate, $translatePartialLoader) {
											$translatePartialLoader.addPart('validation');
											$translatePartialLoader.addPart('publishStatus');
											return $translate.refresh();
				} ],
                $uibModalInstance: function(){ return null},
                entity: function () {
                    return {
                        id: null,
                        status:'DRAFT',
                        level:1
					};
				}
			}
		})
        .state('quiz-detail', {
            parent: 'quiz',
            url: '/{token}',
            data: {
                pageTitle: 'arbolesDashboardApp.quiz.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/quiz/quiz-detail.html',
                    controller: 'QuizDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('quiz');
                    $translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'QuizService', function($stateParams, QuizService) {
                    return QuizService.get({token : $stateParams.token}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'apps',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
		.state('quiz-detail.info',{
			url : '/info',
			views : {
				'info@quiz-detail' : {
                    templateUrl: 'app/entities/quiz/quiz-detail-info.html',
                    controller: 'QuizDetailInfoController',
                    controllerAs: 'vo'
				}
			},
			params: {
				tabs: 0
            }
		})       
		.state('quiz-detail.questions',{
			url : '/questions',
			views : {
				'questions@quiz-detail' : {
                    templateUrl: 'app/entities/quiz/quiz-detail-questions.html',
                    controller: 'QuizDetailQuestionsController',
                    controllerAs: 'vo'
				}
			},
			params: {
				tabs: 1,
                pagey: {
                    value: '1',
                    squash: true
                },
                sorty: {
                    value: 'id,desc',
                    squash: true
                }
            },   
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pagey),
                        sort: $stateParams.sorty,
                        predicate: PaginationUtil.parsePredicate($stateParams.sorty),
                        ascending: PaginationUtil.parseAscending($stateParams.sorty)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('quiz');                    
                    $translatePartialLoader.addPart('question');
                    $translatePartialLoader.addPart('answer');                    
                    return $translate.refresh();
                }],                
            }
		});
	}

})();
