(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('product-item-new', {
            url: '/product-item/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-item/product-item-edit.html',
                    controller: 'ProductItemEditController',
                    controllerAs: 'vm'
                }
            },  
            resolve: {
                entity: function () {
                    return {
                        name: null,
                        description: null,
                        address:{ latitude : 45.490945,
                        			longitude : 9.140625},
						categories:[]
                    };
                },      	
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('business');
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('publishStatus');
                    $translatePartialLoader.addPart('global');                	
                    $translatePartialLoader.addPart('validation');
                    return $translate.refresh();
                }]                    
            }  
        })                
        .state('product-item-detail', {
            url: '/product-item/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'arbolesDashboardApp.business.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-item/product-item-detail.html',
                    controller: 'ProductItemDetailController',
                    controllerAs: 'vm'
                }
            },           
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('business');
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();
                }],                  
                entity: ['$stateParams', 'Business', function($stateParams, Business) {
                    return Business.getItem({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'business',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('product-item-edit', {
            url: '/product-item/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-item/product-item-edit.html',
                    controller: 'ProductItemEditController',
                    controllerAs: 'vm'
                }
            },  
            resolve: {
                    entity: ['$stateParams', 'Business', function($stateParams, Business) {
                    		return Business.getItem({id : $stateParams.id}).$promise;
                    }]
            
            }                    
        })        
        .state('product-item-delete', {
            url: '/product-item/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-item/product-item-delete-dialog.html',
                    controller: 'ProductItemDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Business', function(Business) {
                            return Business.getItem({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('business', null, { reload: 'business' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
