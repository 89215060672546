(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('InvitationPublicAcceptController', InvitationPublicAcceptController);


    InvitationPublicAcceptController.$inject = ['$translate', '$window', '$timeout', 'entity', '$state', '$stateParams', 'Invitation'];

    function InvitationPublicAcceptController ($translate, $window, $timeout, entity, $state, $stateParams, Invitation) {
        var vm = this;
        $window.loading_screen.finish();
        vm.invitation = entity;
        var key = $stateParams.token;
        
       onInit();
       
       function onInit(){
    	   if (vm.invitation) {
			   if (vm.invitation.statusTarget == "ACCEPTED" || vm.invitation.statusTarget == "REJECTED") {
				   $state.go('not-found'); // redirect to 404 in case no invitation has been found with the token
			   }
			   else{
				   Invitation.acceptByToken({token : key}).$promise.catch(()=>{$state.go('not-found')});
			   }
           }
    	   else{
       		$state.go('not-found'); // redirect to 404 in case no invitation has been found with the token
       		
    	   }
       }
    }
})();
