(function () {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('Geo', Geo);

    Geo.$inject = ['$resource', 'SERVER_API_URL'];

    function Geo ($resource, SERVER_API_URL) {
        var service = $resource(SERVER_API_URL + 'api/v1/geo', {}, {
            'coordinates': { 
            	method:'POST',            
            	url: SERVER_API_URL + 'api/geo',
            	isArray: true,
            	headers: {
            		'Content-Type': 'application/json'
            	}            	
             },  
             'getLocation':{
 				method:'GET',
 				url: SERVER_API_URL + 'api/v1/geo/location/:locationid',		
 				isArray: false
             },               
             'reverse': { 
             	method:'POST',            
             	url: SERVER_API_URL + 'api/v1/geo/reverse',
            	isArray: true,
            	headers: {
            		'Content-Type': 'application/json'
            	}             	
              },        
              'prediction': { 
               	method:'POST',
               	url: SERVER_API_URL + 'api/v1/geo/prediction',
            	headers: {
            		'Content-Type': 'application/json'
            	},               	
            	isArray: true
              },
              'country': { 
                 	method:'POST',            
                 	url: SERVER_API_URL + 'api/geo/country',
              	isArray: true
                },              
              'cities': { 
            	 method:'POST',            
            	 url: SERVER_API_URL + 'api/geo/cities',
                 isArray: true
              }              
        });

        return service;
    }
})();
