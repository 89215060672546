(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('CategoryDetailController', CategoryDetailController);

    CategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Category', 'Product', 'Photo', 'AuthServerProvider', '$state', '$uibModal', 'pagingParams', 'paginationConstants'];

    function CategoryDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Category, Product, Photo, AuthServerProvider, $state, $uibModal, pagingParams, paginationConstants) {
        var vm = this;

        vm.category = entity;
        vm.subcategories = [];
        vm.previousState = previousState.name;
        vm.editChecked = editChecked;
        vm.goToDelete = goToDelete;
        vm.updatingStatus = false;      
       	vm.showAddSubcategoryModal = showAddSubcategoryModal;
        vm.deleteSubcategory = deleteSubcategory;    
        vm.goToDetail = goToDetail;
		vm.app = AuthServerProvider.getApp();        
        
        function editChecked(){
    		$state.transitionTo('category-edit',{id:vm.category.id});
        }
                

        var categoryUpdate = $rootScope.$on('arbolesDashboardApp:categoryUpdate', function(event, result) {
            vm.category = result;
        });
        
        var unsubscribe = $rootScope.$on('arbolesDashboardApp:subcategoryDelete', function(event, result) {
			reset();
        });   
        
        var categoryAddSubcategory = $rootScope.$on('arbolesDashboardApp:categoryAddSubcategory', function(event, result) {
	    	vm.subcategories.push(result);
        });           
                
        $scope.$on('$destroy', unsubscribe);
        $scope.$on('$destroy', categoryUpdate);
        $scope.$on('$destroy', categoryAddSubcategory);
        
        
        
        function goToDelete(category){
        	if (window.event) {
				window.event.stopPropagation();
			}
        
			var modalInstance = $uibModal
			.open({
				templateUrl : 'app/entities/category/category-delete-dialog.html',
				controller : 'CategoryDeleteController',
				controllerAs : 'vm',
				scope : $scope, // passed current scope to the modal
				backdrop : 'static',
				size : 'lg',
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                    $translatePartialLoader.addPart('category');
	                    return $translate.refresh();
	                }],
	                entity: function() {
						return [category];
					}
	            }
			});
		
        }
        
        var afterDeleted = $scope.$on('arbolesDashboardApp:deleteCategories', function(event, result) {
        	$state.transitionTo('category');
        });        
        
        
        function showAddSubcategoryModal(){
			
			if (window.event) {
				window.event.stopPropagation();
			}
			
			var modalInstance = $uibModal
					.open({
						templateUrl : 'app/entities/category/category-addsubcategory-dialog.html',
						controller : 'CategoryAddSubcategoryController',
						controllerAs : 'vm',
						scope : $scope, // passed current scope to the modal
						backdrop : 'static',
						size : 'lg',
						resolve : {
							entity: vm.category
							
						}									
					});

		}	
        
		function deleteSubcategory(subcategory) {
			console.log('deleteSubcategory');
			if (window.event) {
				window.event.stopPropagation();
			}
			
			var modalInstance = $uibModal
					.open({
						templateUrl : 'app/entities/category/category-deletesubcategory-dialog.html',
						controller : 'CategoryDeleteSubcategoryController',
						controllerAs : 'vm',
						scope : $scope, // passed current scope to the modal
						backdrop : 'static',
						size : 'lg',
						resolve : {
							entity: function() {
									var subcategories = [subcategory];
									return subcategories;
							},
							category: function(){ return vm.category }
						}
					});

		}	                
        
        
        
        
		//--- Listado de subcategorias de una categoria para la pestaña ---
        /*----------------------------- FUNCTIONS--------------------------------*/
        vm.subcategoriesSelected = [];
        vm.loading = false;
        vm.page = pagingParams.page - 1;
        vm.reset = reset ;
        vm.loadPage = loadPage;
        
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;

        vm.selectAll = selectAll;
        vm.unSelectAll = unSelectAll;
        vm.toggleItem = toggleItem;
        vm.isChecked = isChecked;
        vm.syncCheck = syncCheck;
        vm.checkActive = false;
        vm.checkOnlyOneActive = false;
        vm.hasNextPage = true;

        function loadSubcategories() {
        	
            Category.getSubcategories({
		            	page: vm.page-1,
		            	size: vm.itemsPerPage,
		            	sort: sort(),
		            	id: vm.category.id
            			},onSuccessLoadSubcategories,onErrorLoadSubcategories);
        }
        
        function onSuccessLoadSubcategories(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.hasNextPage = headers('X-Has-Next-Page') === "true";
            vm.queryCount = vm.totalItems;

            for (var i = 0; i < data.length; i++) {
                if(!data[i].deleted) {
                    vm.subcategories.push(data[i]);
                }
            }
            
            vm.loading = false;  
        }

        function onErrorLoadSubcategories(error) {
            AlertService.error(error.data.message);
            vm.loading = false;  
        }
        
        function toggleItem(id) {
            var index = vm.subcategoriesSelected.indexOf(id);
            syncCheck(!isChecked(id),id);
        }

        function isChecked(id){
        	for (var i = 0; i < vm.subcategoriesSelected.length; i++){
        		if (vm.subcategoriesSelected[i] == id){
        			return true;
        		}
        	}
        	
        	return false;
        }
        
        function syncCheck(bool,id){
            if(bool){
                // add item
                vm.subcategoriesSelected.push(id);
              } else {
                // remove item
                for (var i = 0; i < vm.subcategoriesSelected.length; i++){
                  if(vm.subcategoriesSelected[i] == id){
                	  	vm.subcategoriesSelected.splice(i,1);
                  }
                }      
              }
            vm.checkActive = vm.subcategoriesSelected.length>0;
            vm.checkOnlyOneActive = vm.subcategoriesSelected.length===1;
        }

        function selectAll() {
            vm.subcategoriesSelected = [];
            for (var i = 0; i < vm.subcategories.length; ++i) {
                vm.subcategoriesSelected.push(vm.subcategories[i].id);
            }

            vm.checkActive = vm.subcategoriesSelected.length>0;
            vm.checkOnlyOneActive = vm.subcategoriesSelected.length===1;
        }

        function unSelectAll() {
            vm.subcategoriesSelected = [];
            vm.checkActive = false;
        		vm.checkOnlyOneActive = false;
        }
        
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }


        function loadPage(page) {
			if (vm.loading) return;
    		
			//console.log('loadPage  ' + page + ' ' + vm.page);
			
			
			//if (page == vm.page) return;
			
			if (page>vm.page){
				vm.loading = true;
				vm.page = page;
				loadSubcategories();
			}
        } 
        

        function selectAll() {
            vm.subcategoriesSelected = [];
            for (var i = 0; i < vm.subcategories.length; ++i) {
                vm.subcategoriesSelected.push(vm.subcategories[i].id);
            }

            vm.checkActive = vm.subcategoriesSelected.length>0;
            vm.checkOnlyOneActive = vm.subcategoriesSelected.length===1;
        }

        function unSelectAll() {
            vm.subcategoriesSelected = [];
            vm.checkActive = false;
        	vm.checkOnlyOneActive = false;
        }
        
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        
        function reset () {
            vm.page = 0;
            vm.subcategories = [];
            loadSubcategories();
        }

        function loadPage(page) {
			if (vm.loading) return;
    		
			if (page>vm.page){
				vm.loading = true;
				vm.page = page;
				loadSubcategories();
			}
        } 
        
        function goToDetail(entity){
			$state.go('category-detail',{id:entity.id});
        }        	
     }
})();
