(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('ParkDetailInfoController', ParkDetailInfoController);

    ParkDetailInfoController.$inject = ['$scope', '$rootScope', '$stateParams', '$state', 'previousState', '$uibModal', 'ParkService'];

    function ParkDetailInfoController($scope, $rootScope, $stateParams, $state, previousState, $uibModal, ParkService) {
        var vo = this;

        vo.park = $scope.$parent.vm.park;
        vo.previousState = previousState.name;
        vo.showDeleteModal = showDeleteModal;
        vo.goToEdit = goToEdit;
        vo.updatingStatus = false;   
        vo.showUpdateStatus = showUpdateStatus;
        vo.updateStatus = updateStatus;        

        
        function goToEdit() {
            $state.transitionTo('park.edit', {token:vo.park.token});
        }  
        
        function showDeleteModal() {
			if (window.event) {
				window.event.stopPropagation();
            }
			
			var modalInstance = $uibModal
                .open({
                    templateUrl : 'app/entities/park/park-delete-dialog.html',
                    controller : 'ParkDeleteController',
                    controllerAs : 'vm',
                    scope : $scope, // passed current scope to the modal
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        dataState : function() {
                            return {
                                createLabel : 'arbolesDashboardApp.invitation.accept'
                            };
                        },
                        translatePartialLoader : [
                                '$translate',
                                '$translatePartialLoader',
                                function($translate,$translatePartialLoader) {
                                    $translatePartialLoader.addPart('park');
                                    return $translate.refresh();
                                } ],
                        entity: () => [vo.park]
                    }
                });

        }
        
        $scope.$on('arbolesDashboardApp:parkDelete', function(event, result) {
        	$state.transitionTo('park');
        });
        
        
        function showUpdateStatus(show){
    		if (show){
    			vo.statusToUpdate = vo.park.status;
    		}
    		vo.updatingStatus = show;
	    }
	    
	    function updateStatus(){
	    		if (vo.park.status != vo.statusToUpdate){
	    			vo.park.status = vo.statusToUpdate;
	    			ParkService.updateStatus({token:vo.park.token},vo.park,onUpdateStatusSuccess,onUpdateStatusError);
	    		}
	    }
	
	    function onUpdateStatusSuccess(data, headers) {
	    		showUpdateStatus(false);
	    }
	    
	    function onUpdateStatusError (data, headers){
	    		console.log('actualización de estado mal!!!');
	    }	                 
    }
})();
