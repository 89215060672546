(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('BusinessActivateSuccessController', BusinessActivateSuccessController);


    BusinessActivateSuccessController.$inject = ['$translate', '$window', '$timeout', '$state'];

    function BusinessActivateSuccessController ($translate, $window, $timeout, $state) {
        var vm = this;
        $window.loading_screen.finish();
        
        vm.login = login;
        
        function login(){
        	$state.go('login');
        }
    }
})();
