(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('NewsDetailController', NewsDetailController);

    NewsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'News', 'Product', 'Photo', '$state', '$uibModal', 'pagingParams', 'paginationConstants'];

    function NewsDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, News, Product, Photo, $state, $uibModal, pagingParams, paginationConstants) {
        var vm = this;

        vm.news = entity;
        vm.previousState = previousState.name;
        vm.showDeleteModal = showDeleteModal;
        vm.goToEdit = goToEdit;
        vm.updatingStatus = false;   
        vm.showUpdateStatus = showUpdateStatus;
        vm.updateStatus = updateStatus;        
        
        function goToEdit() {
            $state.transitionTo('news-edit', {token:vm.news.token});
        }

        function showDeleteModal() {
			if (window.event) {
				window.event.stopPropagation();
            }
			
			var modalInstance = $uibModal
                .open({
                    templateUrl : 'app/entities/news/news-delete-dialog.html',
                    controller : 'NewsDeleteController',
                    controllerAs : 'vm',
                    scope : $scope, // passed current scope to the modal
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        dataState : function() {
                            return {
                                createLabel : 'arbolesDashboardApp.invitation.accept'
                            };
                        },
                        translatePartialLoader : [
                                '$translate',
                                '$translatePartialLoader',
                                function($translate,$translatePartialLoader) {
                                    $translatePartialLoader.addPart('news');
                                    return $translate.refresh();
                                } ],
                        entity: () => [vm.news]
                    }
                });

        }
        
        $scope.$on('arbolesDashboardApp:appDelete', function(event, result) {
        	$state.transitionTo('app');
        });
        
        function goToDelete(news){
        	if (window.event) {
				window.event.stopPropagation();
			}
        
			var modalInstance = $uibModal
			.open({
				templateUrl : 'app/entities/news/news-delete-dialog.html',
				controller : 'NewsDeleteController',
				controllerAs : 'vm',
				scope : $scope, // passed current scope to the modal
				backdrop : 'static',
				size : 'lg',
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                    $translatePartialLoader.addPart('news');
	                    return $translate.refresh();
	                }],
	                entity: function() {
						return [news];
					}
	            }
			});
		
        }
        
        var afterDeleted = $scope.$on('arbolesDashboardApp:deleteNews', function(event, result) {
        	$state.transitionTo('news');
        });  
        
        
        function showUpdateStatus(show){
    		if (show){
    			vm.statusToUpdate = vm.news.status;
    		}
    		vm.updatingStatus = show;
	    }
	    
	    function updateStatus(){
	    		if (vm.news.status != vm.statusToUpdate){
	    			vm.news.status = vm.statusToUpdate;
	    			News.updateStatus(vm.news,onUpdateStatusSuccess,onUpdateStatusError);
	    		}
	    }
	
	    function onUpdateStatusSuccess(data, headers) {
	    		showUpdateStatus(false);
	    }
	    
	    function onUpdateStatusError (data, headers){
	    		console.log('actualización de estado mal!!!');
	    }	          
     }
})();
