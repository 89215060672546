(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('GeoCtrl', GeoCtrl);

    GeoCtrl.$inject = ['$scope', '$rootScope', '$stateParams', '$timeout', '$q', '$log', 'Geo'];

    function GeoCtrl($scope, $rootScope, $stateParams, $timeout, $q, $log, Geo) {
    	   var self = this;

    	    self.simulateQuery = false;
    	    self.isDisabled    = false;

    	    // list of `state` value/display objects

    	    self.querySearch   = querySearch;
    	    self.selectedItemChange = selectedItemChange;
    	    self.searchTextChange   = searchTextChange;

    	    self.newState = newState;

    	    function searchTextChange(text) {
    	        console.log('Text changed to ' + text);
    	      }

    	      function selectedItemChange(item) {
    	       console.log('Item changed to ' + JSON.stringify(item));
    	      }
    	      
    	    function newState(state) {
    	      alert("Sorry! You'll need to create a Constitution for " + state + " first!");
    	    }
    	    
    	    /**
    	     * Search for states... use $timeout to simulate
    	     * remote dataservice call.
    	     */
    	    function querySearch (query) {
    	    	Geo.prediction({location:query, countryCode:'ES'}).$promise;
    	    }
    	    
    	    function loadAll(){
    	    	
    	    }

        var unsubscribe = $rootScope.$on('arbolesApp:addressUpdate', function(event, result) {
            vm.station = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
