(function() {
	'use strict';

	angular.module('arbolesDashboardApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state('question',{
							parent : 'entity',
							url : '/question',
							data : {
								authorities : [ 'ROLE_USER' ]
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/question/question.html',
									controller : 'QuestionController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {	
									value : 'description,asc',
									squash : true
								}
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort)
											};
										} ],
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('question');
											return $translate.refresh();
										} ]
							}
						})
				.state('question.new',{
							parent : 'question',
							url : '/new',
							views : {
								'content@' : {
									templateUrl : 'app/entities/question/question-edit.html',
									controller : 'QuestionEditController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											;
											$translatePartialLoader
													.addPart('validation');
											$translatePartialLoader
													.addPart('publishStatus');
											return $translate.refresh();
										} ],
								$uibModalInstance : function() {
									return null
								},
								entity : function() {
									return {
										id : null,
										order:1,
										points:1
									};
								}
							}
				})
				/*
        .state('question-detail', {
            url: '/quiz/{token}/question/{tokenQuestion}',
            data: {
                pageTitle: 'arbolesDashboardApp.question.detail.title'
            },
            views: {
                'modalcontent@': {
                    templateUrl: 'app/entities/question/question-detail.html',
                    controller: 'QuestionDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('quiz');
                    $translatePartialLoader.addPart('question');
                    $translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();
                }],
				entity: ['$stateParams', 'QuizService', function($stateParams, QuizService) {
                    return QuizService.getQuestion({token: $stateParams.token, tokenQuestion: $stateParams.tokenQuestion}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'apps',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        /*
		.state('question-detail.info',{
			url : '/info',
			views : {
				'info@question-detail' : {
                    templateUrl: 'app/entities/question/question-detail-info.html',
                    controller: 'QuestionDetailInfoController',
                    controllerAs: 'vo'
				}
			},
			params: {
				modaltabs: 0
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('question');
                    $translatePartialLoader.addPart('quiz');
                    return $translate.refresh();
                }]               
            }            
		})       
		.state('question-detail.answers',{
			url : '/answers',
			views : {
				'answers@question-detail' : {
                    templateUrl: 'app/entities/question/question-detail-answers.html',
                    controller: 'QuestionDetailAnswersController',
                    controllerAs: 'vo'
				}
			},
			params: {
				modaltabs: 1,
                pagey: {
                    value: '1',
                    squash: true
                },
                sorty: {
                    value: 'id,desc',
                    squash: true
                }
            },   
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pagey),
                        sort: $stateParams.sorty,
                        predicate: PaginationUtil.parsePredicate($stateParams.sorty),
                        ascending: PaginationUtil.parseAscending($stateParams.sorty)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('question');
                    $translatePartialLoader.addPart('quiz');
                    return $translate.refresh();
                }]               
            }
		})*/				

		;
	}

})();
