(function() {
	'use strict';

	angular.module('arbolesDashboardApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'answer',
						{
							parent : 'entity',
							url : '/answer',
							data : {
								authorities : [ 'ROLE_USER' ]
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/answer/answer.html',
									controller : 'AnswerController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'description,asc',
									squash : true
								}
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort)
											};
										} ],
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('answer');
											return $translate.refresh();
										} ]
							}
						})

				.state(
						'answer.new',
						{
							parent : 'answer',
							url : '/new',
							views : {
								'content@' : {
									templateUrl : 'app/entities/answer/answer-edit.html',
									controller : 'AnswerEditController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											;
											$translatePartialLoader
													.addPart('validation');
											$translatePartialLoader
													.addPart('publishStatus');
											return $translate.refresh();
										} ],
								$uibModalInstance : function() {
									return null
								},
								entity : function() {
									return {
										id : null
									};
								}
							}
						})

		;
	}

})();
