(function () {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('InvitationSignOut', InvitationSignOut);

        InvitationSignOut.$inject = ['$resource'];

    function InvitationSignOut ($resource) {
        return $resource('api/invitation/finish', {}, {});
    }
})();
