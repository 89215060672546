(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('TagPhotoEditController', TagPhotoEditController);

    TagPhotoEditController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'tag', 'TagService'];

    function TagPhotoEditController ($timeout, $scope, $stateParams, $uibModalInstance, entity, tag, TagService) {
    	
        var vm = this;
        
        vm.photo = entity;
        vm.tag = tag;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.save = save;
        vm.isSaving = false;
        
        $scope.image='/content/images/factory.png';
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Photo.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                }); }

        function save () {
            vm.isSaving = true;
            TagService.save(vm.photo, onSaveSuccess, onSaveError);
        }

        function save () {
            vm.isSaving = false;
            if ($scope.tagImage){        		
            	TagService.saveGallery({id : vm.photo.tagId}, {'file': $scope.tagImage,'fileName':$scope.tagImageFilename}, onSaveImgSuccess, onSaveImgError);
	        }else{        
	        	clear();
	        }        
        }
        
        function onSaveImgSuccess (result) {
            $scope.$emit('arbolesDashboardApp:tagPhotoGalleryAdd', result);
            console.log('onSaveImgSuccess -- evento imagen añadida');
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveImgError () {
        	console.log('onSaveImgError');
            vm.isSaving = false;
        }


        $scope.$watch('files', function () {
            if ($scope.files && $scope.files[0] != null){
              $scope.image = $scope.files[0];

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.image);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.tagImage = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.tagImageFilename = $scope.image.name;
              };
            }
        });
        
        $scope.$watch('file', function () {
            if ($scope.file != null) {
                    $scope.files = [$scope.file]; 
                }
         });
    }
})();
