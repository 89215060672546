(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('TermPhotoEditController', TermPhotoEditController);

    TermPhotoEditController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'term', 'TermService'];

    function TermPhotoEditController ($timeout, $scope, $stateParams, $uibModalInstance, entity, term, TermService) {
    	
        var vm = this;
        
   
        

        vm.photo = entity;
        vm.term = term;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.save = save;
        vm.isSaving = false;
        
        $scope.image='/content/images/factory.png';
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        
        

        function confirmDelete (id) {
            Photo.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                }); }

        function save () {
            vm.isSaving = true;
            TermService.save(vm.photo, onSaveSuccess, onSaveError);
        }

        function save () {
            vm.isSaving = false;
            if ($scope.termImage){        		
            	TermService.saveImage({id : vm.photo.termId}, {'file': $scope.termImage,'fileName':$scope.termImageFilename}, onSaveImgSuccess, onSaveImgError);
	        }else{        
	        	clear();
	        }        
        }
        
        function onSaveImgSuccess (result) {
            $scope.$emit('arbolesDashboardApp:termPhotoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveImgError () {
            vm.isSaving = false;
        }


        $scope.$watch('files', function () {
            if ($scope.files && $scope.files[0] != null){
              $scope.image = $scope.files[0];

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.image);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.termImage = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.termImageFilename = $scope.image.name;
              };
            }
        });
        
        $scope.$watch('file', function () {
            if ($scope.file != null) {
                    $scope.files = [$scope.file]; 
                }
         });
    }
})();
