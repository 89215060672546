(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .directive('dateBefore', dateBefore);
    
    
    dateBefore.$inject = [ 'uiDatetimePickerConfig' ];


    function dateBefore (uiDatetimePickerConfig) {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc (scope, element, attrs, ngModel) {
            if (!ngModel) {
                return;
            }

                var isInclusive = attrs.dateOrEquals ? scope.$eval(attrs.dateOrEquals) : false,
                    validate = function (val1, val2) {
                    
                		if (val1 === undefined || val2 === undefined) return;
                        var isArray = val2 instanceof Array;
                        var isValid = true;
         
                        var date1 = moment(val1,[uiDatetimePickerConfig.momentFormat
                    	]).toDate();
                        
                        
                     
                        if (isArray && val2.length > 0) {
                            for (var i = 0; i < val2.length; i++) {
                                if (val2[i] !== undefined) {
                                    var date2 = new Date(val2[i]);
                                    isValid = isValid && (isInclusive ? date1 <= date2 : date1 < date2);
                                }
                                if (!isValid)
                                    break;
                            }
                        }
                        else {
                            if (val2.length > 0 && val2 !== undefined) {
                                var date2 = new Date(val2);
                                isValid = isInclusive ? date1 <= date2 : date1 < date2;
                            }
                        }
                        ngModel.$setValidity('dateBefore', isValid);
                    };
                // Watch the value to compare - trigger validate()
                scope.$watch(attrs.dateBefore, function () {
                    validate(ngModel.$viewValue, scope.$eval(attrs.dateBefore));
                });
     
                ngModel.$parsers.unshift(function (value) {
                    validate(value, scope.$eval(attrs.dateBefore));
                    return value;
                })
                
         
        }
    }

})();
