(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('PasswordResetFinish', PasswordResetFinish);

    PasswordResetFinish.$inject = ['$resource', 'SERVER_API_URL'];

    function PasswordResetFinish($resource, SERVER_API_URL) {
        var service = $resource(SERVER_API_URL + 'api/account/reset_password/finish', {}, {});

        return service;
    }
})();
