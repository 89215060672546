(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('CombinationSearch', CombinationSearch);

    CombinationSearch.$inject = ['$resource', 'SERVER_API_URL'];

    function CombinationSearch($resource, SERVER_API_URL) {
        var resourceUrl =  SERVER_API_URL + 'api/_search/combinations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
