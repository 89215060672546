(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('InvitationPublicRejectController', InvitationPublicRejectController);


    InvitationPublicRejectController.$inject = ['$translate', '$window', '$timeout', 'entity', '$state'];

    function InvitationPublicRejectController ($translate, $window, $timeout, entity, $state) {
        var vm = this;
        $window.loading_screen.finish();
        vm.invitation = entity;
    }
})();
