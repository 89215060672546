(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        	.state('account-signout', {
	            url: '/public/signout',
	            data: {
	                authorities: [],
	                pageTitle: 'register.title'
	            },
	            views: {
	                'global@': {
	                    templateUrl: 'app/public/account/signout.html',
	                    controller: 'SignoutAccountController',
	                    controllerAs: 'vm'
	                },
	                'step@account-signout':{
	                		templateUrl: 'app/public/account/signout-edit-step.html' 	 
	                }
	            },
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');
	                	$translatePartialLoader.addPart('register');
	                    return $translate.refresh();
	                }],
	            	entity: function () {
                        return {
                        	email:null,
                            firstName: null,
                            lastName: null,
                            terms: null
                        };
                    }	            	
	            }
	        })
		    .state("account-signout.step", {
			      url: '/user',
			      views: {
				      'step@account-signout':{
	              			templateUrl: 'app/public/account/signout-edit-step.html' 	 
				      }	    	  
			      },
		          data: {
		               redirect: undefined
		           }	      
			 })
		    .state('account-signout-success', {
	            url: '/public/account-signout/success',
	            data: {
	                authorities: [],
	                pageTitle: 'register.title'
	            },
	            views: {
	                'global@': {
	                    templateUrl: 'app/public/account/signout-success.html',
	                    controller: 'SignoutAccountSuccessController',
	                    controllerAs: 'vm'
	                }
	            },
                data:{
                    redirect: ['UserAccountModel',function(UserAccountModel){
                  	  		  // just check that firstName is in, if not return the state where this is filled
                             if(!UserAccountModel.userAccount.firstName || !UserAccountModel.userAccount.lastName || !UserAccountModel.userAccount.email) {
                                  return 'account-signout.step';
                             }
                     }]
                },
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');
	                    $translatePartialLoader.addPart('invitation');
	                    return $translate.refresh();
	                }]               
	            }
	        }
		    
		    );  	        
        		
    }
})();
