(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('NewsEditController', NewsEditController);

    NewsEditController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$q', 'entity', 'News', 'Product', 'Photo', '$mdToast'];

    function NewsEditController ($timeout, $scope, $state, $stateParams, $q, entity, News, Product, Photo, $mdToast) {
        var vm = this;

        vm.news = entity;
        vm.clear = clear;
        vm.save = save;
		vm.closeValidationErrors = closeValidationErrors;
		vm.showValidationErrors = showValidationErrors;
		        
        $scope.image=vm.news.image?vm.news.image.url:'/content/images/factory.png';
        $scope.banner=vm.news.banner?vm.news.banner.url:'/content/images/factory.png';
        

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
        	if (vm.news.token){
        		$state.transitionTo('news-detail',{token: vm.news.token});
        	}else{
        		$state.transitionTo('news');
        	}
        }

        function save () {
            vm.isSaving = true;
            if (vm.news.token !== null) {
                News.update(vm.news, onSaveSuccess, onSaveError);
            } else {
                News.save(vm.news, onSaveSuccess, onSaveError);
            }
        }
        
        function onSaveSuccess (news) {
            vm.isSaving = false;
            vm.news.token = news.token;
	        if ($scope.newsImage){        		
	        	News.saveImage({token : news.token}, {'file': $scope.newsImage,'fileName':$scope.newsImageFilename}, onSaveImgSuccess, onSaveImgError);
	        }else{
	        	if ($scope.newsBanner){
	        		News.saveBanner({token : news.token}, {'file': $scope.newsBanner,'fileName':$scope.newsBannerFilename}, onSaveBannerSuccess, onSaveBannerError);	
	        	}else{
	        		$state.transitionTo('news-detail',{token: news.token});
	        	}
	        }
        }
                

        function onSaveError () {
            vm.isSaving = false;
        }

        function onSaveImgSuccess(){
        	//$state.go('campaign-detail',{id: campaign.id}, { reload: true, inherit: false });
        	if ($scope.newsBanner){
        		News.saveBanner({token : news.token}, {'file': $scope.newsBanner,'fileName':$scope.newsBannerFilename}, onSaveBannerSuccess, onSaveBannerError);	
        	}else{
        		$state.transitionTo('news-detail',{token: vm.news.token})
        	}
        }
        
        
        function onSaveBannerError(){
        	AlertService.error(error.data.message);
        }   
        
        function onSaveBannerSuccess(){
        	//$state.go('campaign-detail',{id: campaign.id}, { reload: true, inherit: false });
        	$state.transitionTo('news-detail',{token: vm.news.token})
        }
        
        
        function onSaveImgError(){
        	AlertService.error(error.data.message);
        }           
        
        $scope.$watch('files', function () {
            if ($scope.files && $scope.files[0] != null){
              $scope.image = $scope.files[0];

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.image);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.newsImage = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.newsImageFilename = $scope.image.name;
              };
            }
        });
        
        $scope.$watch('file', function () {
          if ($scope.file != null) {
                  $scope.files = [$scope.file]; 
              }
        });          
        
        $scope.$watch('banners', function () {
            if ($scope.banners && $scope.banners[0] != null){
              $scope.banner = $scope.banners[0];

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.banner);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.newsBanner = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.newsBannerFilename = $scope.banner.name;
              };
            }
        });
        
        $scope.$watch('banner', function () {
          if ($scope.banner != null) {
                  $scope.banners = [$scope.file]; 
              }
        });          

    	function showValidationErrors(){
    		angular.forEach($scope.editForm.$error.required, function(field) {
    		    field.$setDirty();
    		});
    		
    		var parentEl = angular.element(document).find('.modal-body');
    		console.log('parentEl');
    		console.log(parentEl);
    		console.log($mdToast);
    		
    		$mdToast.show({
    	          hideDelay   : 5000,
    	          toastClass: 'form-validation',
    	          position    : 'top left',
    	          templateUrl : 'app/entities/news/news-edit-validation.html',
    	          controller: 'FormValidationController',
    	          locals: {
    	        	  	editForm : $scope.editForm
    	          },
    	          parent: parentEl,
    	          //scope: $scope
    	        }).finally(function () {
    	        	 	// isDlgOpen = false;
    			});
    	}
    	
    	function closeValidationErrors () {
            $mdToast
              .hide()
              .then(function() {
                isDlgOpen = false;
              });
    	}               
    }
})();
