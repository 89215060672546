(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('NewsController', NewsController);

    NewsController.$inject = ['DataUtils', '$q', '$translate','$scope', '$state', '$sce', 'News', 'AlertService', 'pagingParams', 'paginationConstants', '$uibModal'];

    function NewsController(DataUtils, $q, $translate, $scope, $state,  $sce, News, AlertService, pagingParams, paginationConstants, $uibModal) {

        var vm = this;

        vm.news = [];
        vm.newsSelected = [];
        vm.loading = false;
        vm.filter = {};        

        vm.page = pagingParams.page - 1;
        vm.reset = reset ;
        vm.loadPage = loadPage;
                
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.editChecked = editChecked;
        
        vm.showFilter = showFilter;
        vm.closeFilter = closeFilter;
        vm.noCloseFilter = noCloseFilter;
        vm.doFilter = doFilter;
		vm.cleanFilter = cleanFilter;

        vm.selectAll = selectAll;
        vm.unSelectAll = unSelectAll;
        vm.toggleItem = toggleItem;
        vm.isChecked = isChecked;
        vm.syncCheck = syncCheck;
        vm.checkActive = false;
        vm.checkOnlyOneActive = false;
        vm.hasNextPage = true;

        vm.showEmpty = false;
        vm.removeTag = removeTag;
        
        vm.goToDetail = goToDetail;
        vm.showDeleteModal = showDeleteModal;
        vm.renderHtml = renderHtml;
                        
     /*----------------------------- LOAD PAGE --------------------------------*/
        function loadNews() {
            if (vm.filter.data) {
                News.filter({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()},
                    vm.filter, onSuccess, onError);
            }else {
                News.query({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            
        }
        
       function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.hasNextPage = headers('X-Has-Next-Page') === "true";
            vm.queryCount = vm.totalItems;
            
            for (var i = 0; i < data.length; i++) {
                if(!data[i].deleted) {
                    vm.news.push(data[i]);
                }
            }
            vm.showEmpty = true;
        	vm.loading = false;            
        }

        function onError(error) {
            AlertService.error(error.data.message);
            vm.loading = false;
            vm.showEmpty = true;
        }

        $scope.$on('arbolesDashboardApp:newsDelete', function(event, result) {
            reset();
        });  
          
        
        
        function toggleItem(news) {
            var index = vm.newsSelected.indexOf(news);
            syncCheck(!isChecked(news),news);
        }

        function isChecked(news){
            for (var i = 0; i < vm.newsSelected.length; i++){
                if (vm.newsSelected[i].token == news.token){
                    return true;
                }
            }
            
            return false;
        }
        
        function syncCheck(bool, news) {
            if(bool){
                // add item
                vm.newsSelected.push(news);
              } else {
                // remove item
                for (var i = 0; i < vm.newsSelected.length; i++){
                  if(vm.newsSelected[i].token == news.token){
                	  vm.newsSelected.splice(i,1);
                  }
                }      
              }
            vm.checkActive = vm.newsSelected.length>0;
            vm.checkOnlyOneActive = vm.newsSelected.length===1;
        }

        function selectAll() {
            vm.newsSelected = [];
            for (var i = 0; i < vm.news.length; ++i) {
                vm.newsSelected.push(vm.news[i]);
            }

            vm.checkActive = vm.newsSelected.length>0;
            vm.checkOnlyOneActive = vm.newsSelected.length===1;
        }

        function unSelectAll() {
            vm.newsSelected = [];
            vm.checkActive = false;
            vm.checkOnlyOneActive = false;
        }
        
        function filterById(news) {
            return vm.filter[news.id] || noFilter(vm.filter);
        }
        
        function showFilter(){
        		vm.showFilters = true;        	
        }
        
        function closeFilter(){
        		vm.showFilters = false;        	
        }
        
        function noCloseFilter($event) {
			if (window.event) {
				window.event.stopPropagation();
			}
		}
        
        function doFilter() {
			vm.filter.data = true;
			reset();
			closeFilter();
		}
		
		function cleanFilter() {
			vm.filter = {};
			vm.filter.data = false;

			reset();
			closeFilter();
		}		
		
	     
                
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        
        function reset () {
            vm.news = [];
            vm.showEmpty = false;
            vm.page = 1;

            loadPage(vm.page);
            unSelectAll();
        }

        function loadPage(page) {
            if (vm.loading) return;
            
            vm.loading = true;
            vm.page = page;
            loadNews(); 
        } 

        function editChecked(){
        	$state.go('news.edit', { token: vm.newsSelected[0].token });
        }
        
        function goToDetail(entity){
    		$state.go('news-detail', { token:entity.token });
        }

        function removeTag(tag) {
            vm.filter = {}
            reset();
        }
        
        function renderHtml(html_code){
        	return $sce.trustAsHtml(html_code);
        }        
        
        function showDeleteModal(news) {
			if (window.event) {
				window.event.stopPropagation();
            }
			
			var modalInstance = $uibModal
                .open({
                    templateUrl : 'app/entities/news/news-delete-dialog.html',
                    controller : 'NewsDeleteController',
                    controllerAs : 'vm',
                    scope : $scope, // passed current scope to the modal
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        dataState : function() {
                            return {
                                createLabel : 'arbolesDashboardApp.invitation.accept'
                            };
                        },
                        translatePartialLoader : [
                                '$translate',
                                '$translatePartialLoader',
                                function($translate,$translatePartialLoader) {
                                    $translatePartialLoader.addPart('news');
                                    return $translate.refresh();
                                } ],
                        entity: () => news
                    }
                });
		}
      
    }

})();
