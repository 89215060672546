(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('CommentSearch', CommentSearch);

    CommentSearch.$inject = ['$resource', 'SERVER_API_URL'];

    function CommentSearch($resource, SERVER_API_URL) {
        var resourceUrl =  SERVER_API_URL + 'api/_search/comments/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
