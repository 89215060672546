(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('QuestionDetailController', QuestionDetailController);


    QuestionDetailController.$inject = ['$scope', '$translate', '$stateParams', 'entity', 'quiz', '$state', '$uibModalInstance', '$timeout', 'AnswerService'];

    function QuestionDetailController ($scope, $translate, $stateParams, entity, quiz, $state, $uibModalInstance, $timeout, AnswerService ) {
        var vmm = this;
        
        vmm.question = entity;
        vmm.quiz = quiz;
        vmm.renderHtml = renderHtml;
        vmm.clear = clear;
        vmm.slice = false;
        
        

		vmm.tabInfo = 0;
		vmm.tabAnswers = 1;
		
		vmm.addAnswer = addAnswer;
		vmm.goToEdit = goToEdit;
		vmm.save = save;
		vmm.back= back;
		vmm.closeValidationErrors = closeValidationErrors;
		vmm.showValidationErrors = showValidationErrors;		

        vmm.tinymceOptions = {
        	    toolbar: 'bold italic | alignleft aligncenter alignright | code',
        	    skin: 'lightgray',
        	    theme : 'modern'
        };
        /*
    	vmm.answer = {
    			id:null,
    			description:null,
    			correct:false,
    			image:null,
    			questionToken: vmm.question.token
    	}        */
        
        $scope.image='/content/images/factory.png'; //vmm.answer.image?vm.answer.image.url:'/content/images/factory.png';

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });        
		
		if($stateParams.modaltabs){
			vmm.currentTab = $stateParams.modaltabs;
		}else{
			vmm.currentTab = vmm.tabInfo;	
		}
		
	    vmm.state = $state.current;
	    
        function clear () {
        	$uibModalInstance.dismiss('cancel');	//-- closing modal window         
        }
        
        function renderHtml(html_code){
        	return $sce.trustAsHtml(html_code);
        }   
        
        function addAnswer(){
        	vmm.slide=true;
        	vmm.answer = {
        			id:null,
        			description:null,
        			correct:false,
        			image:null,
        			questionToken: vmm.question.token
        	}
        	
        	$scope.image='/content/images/factory.png';        	
        }
        
        function goToEdit(answer){
        	vmm.slide = true;
        	vmm.answer = answer;
        	
        	if (vmm.answer.image){
        		$scope.image = vmm.answer.image.url;
        	}else{
        		$scope.image='/content/images/factory.png';	
        	}
        }
        
        

        //-- ANSWER ADD / UPDATE
        function back () {
        	vmm.slide=false;
        	vmm.answer = {
        			id:null,
        			description:null,
        			correct:false,
        			image:null
        	}
        	
        	$scope.image='/content/images/factory.png';
        }
        

        function save () {
            vmm.isSaving = true;
            if (vmm.answer.id !== null) {
                AnswerService.update(vmm.answer, onSaveSuccess, onSaveError);
            } else {
            	AnswerService.save(vmm.answer, onSaveSuccess, onSaveError);
            }
        }
        

        function onSaveSuccess (item) {
            vmm.isSaving = false;
            vmm.answer.id = item.id;
	        if ($scope.answerImage){        		
	        	AnswerService.saveImage({id : item.id}, {'file': $scope.answerImage,'fileName':$scope.answerImageFilename}, onSaveImgSuccess, onSaveImgError);
	        }else{        
	        	//-- mostrar respuesta añadida en el listado
	        	vmm.question.answers.push(vmm.answer);
	        	back();
	        }            
        }

        function onSaveError () {
            vmm.isSaving = false;
        }
        
        function onSaveImgSuccess(){
        	//-- mostrar respuesta añadida en el listado
        	vmm.question.answers.push(vmm.answer);
        	back();
        }
        
        
        function onSaveImgError(){
        	AlertService.error(error.data.message);
        }

        $scope.$watch('files', function () {
        	console.log('watch files, img updaloed');
            if ($scope.files && $scope.files[0] != null){
              $scope.image = $scope.files[0];

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.image);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.answerImage = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.answerImageFilename = $scope.image.name;
              };
            }
        });
        
        $scope.$watch('file', function () {
        	console.log('watch file, img updaloed');
            if ($scope.file != null) {
                    $scope.files = [$scope.file]; 
                }
         });
        
        function showValidationErrors() {
            angular.forEach($scope.editForm.$error.required, function(field) {
				field.$setDirty();
			});

            var parentEl = angular.element(document).find('.animate-slide');

            $mdToast.show({
                  hideDelay   : 5000,
                  toastClass: 'form-validation',
                  position    : 'top left',
                  templateUrl : 'app/entities/answer/answer-edit-validation.html',
                  controller: 'FormValidationController',
                  locals: {
                	  editForm : $scope.editForm
                  },
                  parent: parentEl,
                }).finally(function () {

                });
        } 
        
    	function closeValidationErrors () {
             $mdToast
               .hide()
               .then(function() {
                 isDlgOpen = false;
               });
     	}                   
    }
})();
