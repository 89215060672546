(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        	.state('email-confirmed', {
        		url: '/public/email/confirm/{token}',
        		data: {
        			authorities: [],
        			pageTitle: 'arbolesDashboardApp.email.title'
        		},
        		views: {
        			'global@': {
        				templateUrl: 'app/public/email/email-confirm.html',
        				controller: 'EmailConfirmController',
        				controllerAs: 'vm'
	                }
	            },
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                		$translatePartialLoader.addPart('global');
	                    $translatePartialLoader.addPart('email');
	                    return $translate.refresh();
	                }],
	                entity: ['$stateParams', 'Account', function($stateParams, Account) {
	                    return Account.confirmEmailWithKey({token : $stateParams.token}).$promise.then(function (account) { return account; },
	                    		  function () {return null;});
	                }],                
	            }
        });
    }
})();
