(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('term', {
            parent: 'entity',
            url: '/term',
            data: {
                authorities: ['ROLE_USER'],            
                pageTitle: 'arbolesDashboardApp.term.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/term/term.html',
                    controller: 'TermController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                }
            },            
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],                 
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('term');
                    return $translate.refresh();
                }]
            }
        })
        
        .state('term.edit', {
            parent: 'term',
            url: '/{id}/edit',
            views: {
                'content@': {
                    templateUrl: 'app/entities/term/term-edit.html',
                    controller: 'termEditController',
                    controllerAs: 'vm'
                }
            },   
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {    
                	$translatePartialLoader.addPart('validation');
                	$translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();     
                }],
                entity: ['$stateParams', 'termService', function($stateParams, termService) {
                    return termService.get({id : $stateParams.id}).$promise;
                }]
            }            
        })
        
         .state('term-detail', {
            parent: 'term',
            url: '/{id}/detail',
            data: {
                pageTitle: 'arbolesDashboardApp.term.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/term/term-detail.html',
                    controller: 'TermDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('term');
                    $translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TermService', function($stateParams, TermService) {
                    return TermService.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name,
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })   
		.state('term-detail.info',{
			url : '/info',
			views : {
				'info@term-detail' : {
                    templateUrl: 'app/entities/term/term-detail-info.html',
                    controller: 'TermDetailInfoController',
                    controllerAs: 'vm'
				}
			},
			params: {
				tabs: 0
            }
		})       	
		.state('term-detail.gallery',{
			url : '/gallery',
			views : {
				'gallery@term-detail' : {
                    templateUrl: 'app/entities/term/term-detail-gallery.html',
                    controller: 'TermDetailGalleryController',
                    controllerAs: 'vm'
				}
			},
			params: {
				tabs: 1,
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                }
            },   
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]                  
            }
		}) 	  
             .state('term.new', {
            parent: 'term',
            url: '/new',
            views: {
                'content@': {
                    templateUrl: 'app/entities/term/term-edit.html',
                    controller: 'TermEditController',
                    controllerAs: 'vm'
                }
            },   
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {;
                    $translatePartialLoader.addPart('validation');
                    $translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();
                }],         
                $uibModalInstance: function(){ return null},
                entity: function () {
                    return {
                        id: null,
                        status:'DRAFT'
                    };
                }
            }            
        })  
        
        
              .state('term-detail.photo', {
            parent: 'term',
            url: '/new/gallery',
            views: {
                'content@': {
                    templateUrl: 'app/entities/term/term-detail-photo.html',
                    controller: 'TermDetailPhotoController',
                    controllerAs: 'vm'
                     
				}
			},
			params: {
				tabs: 1,
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                }
            },   
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('photo');
                    return $translate.refresh();
                }],                
            }
		})
        
            
        
        
       
		.state('term.delete', {
            parent: 'term',
            url: '/{id}/delete',
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/term/term-delete-dialog.html',
                    controller: 'TermDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: { 
                        entity: ['TermService', function(TermService) {
                            return TermService.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('term', null, { reload: 'term' });
                }, function() {
                    $state.go('^');
                });
            }]
        });			        
         
        
     }

})();
