(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('ParkDeleteController',ParkDeleteController);

    ParkDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'ParkService'];

    function ParkDeleteController($scope, $uibModalInstance, entity, ParkService) {
        var vm = this;
        
        vm.items = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        let itemIds = [];

        init();

        function init() {
            itemIds = vm.items.map(item => item.id);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        
        
        function confirmDelete() {
            if(itemIds.length > 1) {
            	ParkService.deleteParks({ items: itemIds }, onSuccessDelete, onError);
            }else {
            	ParkService.delete({ id: itemIds[0] }, onSuccessDelete, onError);
            }
        }

        function onSuccessDelete() {
            $scope.$emit('arbolesDashboardApp:parkDelete');
            clear();
        }

        function onError(err) {
            console.error(err);
        }
    }
})();
