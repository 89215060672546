(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('ProductController', ProductController);

    ProductController.$inject = ['DataUtils', '$q', '$translate','$scope', '$state', 'Product', 'AlertService', 'pagingParams', 'paginationConstants', '$uibModal'];

    function ProductController(DataUtils, $q, $translate, $scope, $state, Product, AlertService, pagingParams, paginationConstants, $uibModal) {

        var vm = this;

        vm.products = [];
        vm.productsSelected = [];
        vm.loading = false;
        vm.filter = {};   
        
        vm.page = pagingParams.page - 1;
        vm.reset = reset ;
        vm.loadPage = loadPage;
                
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.editChecked = editChecked;
        
        vm.showFilter = showFilter;
        vm.closeFilter = closeFilter;
        vm.noCloseFilter = noCloseFilter;
        vm.doFilter = doFilter;
		vm.cleanFilter = cleanFilter;

        vm.selectAll = selectAll;
        vm.unSelectAll = unSelectAll;
        vm.toggleItem = toggleItem;
        vm.isChecked = isChecked;
        vm.syncCheck = syncCheck;
        vm.checkActive = false;
        vm.checkOnlyOneActive = false;
        vm.hasNextPage = true;

        vm.showEmpty = false;

        vm.goToDetail = goToDetail;
        vm.showDeleteModal = showDeleteModal;

        /*----------------------------- LOAD PAGE --------------------------------*/
        function loadProducts() {
            if (vm.filter.data) {
                Product.filter({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()},
                    vm.filter, onSuccess, onError);
            }else {
            	Product.query({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            
        }
        
       function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.hasNextPage = headers('X-Has-Next-Page') === "true";
            vm.queryCount = vm.totalItems;
            
            for (var i = 0; i < data.length; i++) {
                if(!data[i].deleted) {
                    vm.products.push(data[i]);
                }
            }
            vm.showEmpty = true;
        	vm.loading = false;            
        }

        function onError(error) {
            AlertService.error(error.data.message);
            vm.loading = false;
            vm.showEmpty = true;
        }

        $scope.$on('arbolesDashboardApp:productsDelete', function(event, result) {
            reset();
        });  
          
        
        
        function toggleItem(product) {
            var index = vm.productsSelected.indexOf(product);
            syncCheck(!isChecked(product),product);
        }

        function isChecked(product){
            for (var i = 0; i < vm.productsSelected.length; i++){
                if (vm.productsSelected[i].id == product.id){
                    return true;
                }
            }
            
            return false;
        }
        
        function syncCheck(bool, product) {
            if(bool){
                // add item
                vm.productsSelected.push(product);
              } else {
                // remove item
                for (var i = 0; i < vm.productsSelected.length; i++){
                  if(vm.productsSelected[i].id == product.id){
                	  vm.productsSelected.splice(i,1);
                  }
                }      
              }
            vm.checkActive = vm.productsSelected.length>0;
            vm.checkOnlyOneActive = vm.productsSelected.length===1;
        }

        function selectAll() {
            vm.productsSelected = [];
            for (var i = 0; i < vm.products.length; ++i) {
                vm.productsSelected.push(vm.products[i]);
            }

            vm.checkActive = vm.productsSelected.length>0;
            vm.checkOnlyOneActive = vm.productsSelected.length===1;
        }

        function unSelectAll() {
            vm.productsSelected = [];
            vm.checkActive = false;
            vm.checkOnlyOneActive = false;
        }
        
        function filterById(userBusiness) {
            return vm.filter[userBusiness.id] || noFilter(vm.filter);
        }
        
        function showFilter(){
        		vm.showFilters = true;        	
        }
        
        function closeFilter(){
        		vm.showFilters = false;        	
        }
        
        function noCloseFilter($event) {
			if (window.event) {
				window.event.stopPropagation();
			}
		}
        
        function doFilter() {
			vm.filter.data = true;
			reset();
			closeFilter();
		}
		
		function cleanFilter() {
			vm.filter = {};
			vm.filter.data = false;

			reset();
			closeFilter();
		}		
		
	     
                
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        
        function reset () {
            vm.products = [];
            vm.showEmpty = false;
            vm.page = 1;

            loadPage(vm.page);
            unSelectAll();
        }

        function loadPage(page) {
            if (vm.loading) return;
            
            vm.loading = true;
            vm.page = page;
            loadProducts(); 
        } 

        function editChecked(){
        	$state.go('product.edit', { id: vm.productsSelected[0].id });
        }
        
        function goToDetail(entity){
    		$state.go('product-detail', { id:entity.id });
        }

        function removeTag(tag) {
            vm.filter = {}
            reset();
        }
        
        function showDeleteModal(products) {
			if (window.event) {
				window.event.stopPropagation();
            }
			
			var modalInstance = $uibModal
                .open({
                    templateUrl : 'app/entities/product/product-delete-dialog.html',
                    controller : 'ProductDeleteController',
                    controllerAs : 'vm',
                    scope : $scope, // passed current scope to the modal
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        dataState : function() {
                            return {
                                createLabel : 'arbolesDashboardApp.invitation.accept'
                            };
                        },
                        translatePartialLoader : [
                                '$translate',
                                '$translatePartialLoader',
                                function($translate,$translatePartialLoader) {
                                    $translatePartialLoader.addPart('product');
                                    return $translate.refresh();
                                } ],
                        entity: () => products
                    }
                });

		}
        
    }
})();
