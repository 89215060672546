(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('TagDetailController', TagDetailController);

    TagDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'TagService', 'Product', 'Photo', '$state', '$uibModal', 'pagingParams', 'paginationConstants'];

    function TagDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, TagService, Product, Photo, $state, $uibModal, pagingParams, paginationConstants) {
        var vm = this;

        vm.tag = entity;
        vm.previousState = previousState.name;
        vm.tabSelected = tabSelected;
        vm.selectInfo = selectInfo;
        vm.selectGallery = selectGallery;

		vm.tabInfo = 0;
		vm.tabGallery = 1;

		 
		if($stateParams.tabs){
			vm.currentTab = $stateParams.tabs;
		}else{
			vm.currentTab = vm.tabInfo;	
		}
		
	    vm.state = $state.current;
	    
	    function tabSelected(){
	    	console.log('tabSelected');
	    	console.log(vm.currentTab);
	    }

	    function selectGallery(){
	    	$state.go('tag-detail.gallery');
	    }	    
	    
	    
	    function selectInfo() {
	    	$state.go('tag-detail.info');
	    }	    
     }
})();
