(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http', '$localStorage', '$sessionStorage', '$q', 'SERVER_API_URL'];

    function AuthServerProvider ($http, $localStorage, $sessionStorage, $q, SERVER_API_URL) {
        var service = {
            getToken: getToken,
            login: login,
            loginWithToken: loginWithToken,
            storeAuthenticationToken: storeAuthenticationToken,
            storeApp:storeApp,
            getApp: getApp,
            logout: logout
        };

        function onSuccessApp(app){
        	storeApp(app);
        }

        function getToken () {
            return $localStorage.authenticationToken || $sessionStorage.authenticationToken;
        }
        
        function getApp () {
        	return $localStorage.app || $sessionStorage.app;
        }        

        function login (credentials) {
            var postLogin = {
            		method: 'POST',
            		url: SERVER_API_URL + 'api/authenticate',
            		headers: {
            			'Content-Type': 'application/json'
            		},
            		data: {
		                login: credentials.username,
		                password: credentials.password,
		                rememberMe: credentials.rememberMe
            		}
            };
            return $http(postLogin).then(function onSuccess(response) {
                var data = response.data;
                var status = response.status;
                var statusText = response.statusText;
                var headers = response.headers;
                var config = response.config;
                
            	authenticateSuccess(data,status,headers);
            })
            .catch(function (err) {
            	console.log('authenticate error ' + err);
            	throw err;
            });
            
            
            


            function authenticateSuccess (data, status, headers) {
                var bearerToken = headers('Authorization');
                /*
                if (angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ') {
                    var jwt = bearerToken.slice(7, bearerToken.length);
                    service.storeAuthenticationToken(jwt, credentials.rememberMe);
                    return jwt;
                }
                */
                if (data.id_token){
                	var jwt = data.id_token;
                	service.storeAuthenticationToken(jwt, credentials.rememberMe);
                    return jwt;
                }
            }
        }

        function loginWithToken(jwt, rememberMe) {
            var deferred = $q.defer();

            if (angular.isDefined(jwt)) {
                this.storeAuthenticationToken(jwt, rememberMe);
                deferred.resolve(jwt);
            } else {
                deferred.reject();
            }

            return deferred.promise;
        }

        function storeAuthenticationToken(jwt, rememberMe) {
            if(rememberMe){
                $localStorage.authenticationToken = jwt;
            } else {
                $sessionStorage.authenticationToken = jwt;
            }
        }

        function storeApp(app){
        	$localStorage.app = app;
        }
        
        function logout () {
            delete $localStorage.authenticationToken;
            delete $sessionStorage.authenticationToken;
        }
        
        return service;
    }
})();
