(function() {
    'use strict';
    angular
        .module('arbolesDashboardApp')
        .factory('StripeService', StripeService);
    
    StripeService.$inject = ['$window'];
    

    function StripeService($window) {
    	
        var service = {
                initStripe: initStripe
        };
        
        return service;
        
        function initStripe(){
//    	$window.Stripe.setPublishableKey('pk_test_SDwALt5rzN7jWAi7UawZs8IO');
    		return $window.Stripe('pk_live_93jhcD07QBXMWiq1z6WxzKVg');
        }
    }
})();