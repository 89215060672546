(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('TermDetailGalleryController', TermDetailGalleryController);

    TermDetailGalleryController.$inject = ['$timeout', '$state', '$scope', '$stateParams', '$rootScope', 'TermService', 'pagingParams', 'paginationConstants', '$mdToast', '$uibModal', 'previousState'];

    function TermDetailGalleryController ($timeout, $state, $scope, $stateParams, $rootScope, TermService, pagingParams, paginationConstants, $mdToast, $uibModal, previousState) {

        var vm = this;
        
		if($stateParams.tabs){
			$scope.$parent.vm.currentTab = $stateParams.tabs;
			loadPage(0);
		}
		
		vm.term = $scope.$parent.vm.term;
        vm.images = [];
        vm.filter = {};
        vm.loading = false;
        vm.showEmpty = true;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page - 1;
        vm.reset = reset ;
        vm.clear = clear;
        vm.loadPage = loadPage;
        vm.hasNextPage = true;
        vm.addGallery = addGallery;
        vm.removeGallery=removeGallery;

        vm.showEmpty = false;
        
   	 	$scope.image=vm.term.image?vm.term.image.url:'/content/images/factory.png';
   	 	
        var photoAdded = $scope.$on('arbolesDashboardApp:termPhotoUpdate', function(event, result){
            reset();
        });  
        $scope.$on('$destroy',photoAdded);   	 	

        

        function loadImages() {
    		TermService.getGallery({
    			id: $stateParams.id,
                page: vm.page,
                size: vm.itemsPerPage
            }, onSuccess, onError);
        }
              
        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.hasNextPage = headers('X-Has-Next-Page') === "true";
            vm.queryCount = vm.totalItems;
            
            for (var i = 0; i < data.length; i++) {
                vm.images.push(data[i]);
            }
            vm.showEmpty = true;
        	vm.loading = false;   
        }

        function onError(error) {
            //AlertService.error(error.data.message);
            vm.loading = false;
            vm.showEmpty = true;
        }
        
        function loadPage(page) {
            if (vm.loading) return;
            
            vm.loading = true;
            vm.page = page;
            loadImages(); 
        }         
        
        function reset () {
            vm.images = [];
            vm.showEmpty = false;
        }
        
     
        
       
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

 
       
        function removeGallery() {
        	  console.log($stateParams.id);
            	TermService.deleteImages({
            		id: $stateParams.id,
            		 page: vm.page,
                     size: vm.itemsPerPage
                }, onSuccessDelete, onError);
            	
               }
       
        
        function onSuccessDelete() {
            $scope.$emit('arbolesDashboardApp:termDelete');
            clear();
        }

      
		function addGallery() {
			var openAddPromoModalInstance = $uibModal
					.open({
						templateUrl 	: 'app/entities/term/term-photo-edit-dialog.html',
						controller : 'TermPhotoEditController',
						controllerAs : 'vm',
						
						backdrop : 'static',
						size : 'xl',
						resolve : {
							translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
								$translatePartialLoader.addPart('photo');
								return $translate.refresh();
							}],    
			                entity: function () {
			                    return {
			                    	id: null,
			                        termId: $stateParams.id
			                    };
			                },    
			                term: function () {
			                    return $scope.$parent.vm.term;
			                }   			                
						}
					});
			
			
			
		}           

    }
     

})();