(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('ProductEditController', ProductEditController);

    ProductEditController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'Product', 'UserApp', 'Good', 'Combination', 'Photo', 'Comment', 'ProductAvailability', 'Size', 'Category', 'Brand', 'Colour', 'Tag', 'Rental'];

    function ProductEditController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, Product, UserApp, Good, Combination, Photo, Comment, ProductAvailability, Size, Category, Brand, Colour, Tag, Rental) {
        var vm = this;

        vm.product = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.userapps = UserApp.query();
        vm.goods = Good.query({filter: 'product-is-null'});
        $q.all([vm.product.$promise, vm.goods.$promise]).then(function() {
            if (!vm.product.good || !vm.product.good.id) {
                return $q.reject();
            }
            return Good.get({id : vm.product.good.id}).$promise;
        }).then(function(good) {
            vm.goods.push(good);
        });
        vm.combinations = Combination.query({filter: 'product-is-null'});
        $q.all([vm.product.$promise, vm.combinations.$promise]).then(function() {
            if (!vm.product.combination || !vm.product.combination.id) {
                return $q.reject();
            }
            return Combination.get({id : vm.product.combination.id}).$promise;
        }).then(function(combination) {
            vm.combinations.push(combination);
        });
        vm.photos = Photo.query();
        vm.comments = Comment.query();
        vm.productavailabilities = ProductAvailability.query();
        vm.sizes = Size.query();
        vm.categories = Category.query();
        vm.brands = Brand.query();
        vm.colours = Colour.query();
        vm.tags = Tag.query();
        vm.rentals = Rental.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.product.id !== null) {
                Product.update(vm.product, onSaveSuccess, onSaveError);
            } else {
                Product.save(vm.product, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('arbolesDashboardApp:productUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.expireDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
