(function() {
    'use strict';
    angular
        .module('arbolesDashboardApp')
        .factory('TermService', TermService);

    TermService.$inject = ['$resource', 'DateUtils', 'SERVER_API_URL'];

    function TermService ($resource, DateUtils, SERVER_API_URL) {
        var resourceUrl =  SERVER_API_URL + 'api/terms/:id';

        return $resource(resourceUrl, {}, {
            'query': { 
            		method: 'GET', 
            		url:  SERVER_API_URL + 'api/terms',
            		isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            
			'getGallery':{ 
				method:'GET',
				url: SERVER_API_URL + 'api/terms/:id/images',
				isArray: true
            },       
            
            'deleteTerm': { method: 'DELETE',
				  
				 headers : {
				'Content-Type' : 'application/json'
					}
			  
			  },
			
                    
            'update': { 
            	method:'PUT',
		        headers: {
		        	'Content-Type': 'application/json'
		        } 
            },             
            'updateStatus': { 
            		method:'PUT', 
            		url:SERVER_API_URL + 'api/terms/:id/status',
                	headers: {
                		'Content-Type': 'application/json'
                	} 
            },
			'saveImage': {
				method: 'PUT',
				url: SERVER_API_URL + 'api/terms/:id/image',
				headers: {
					'Content-Type': 'application/json'
				}
			},
			'deleteImages': {
				method: 'DELETE',
				url: SERVER_API_URL + 'api/terms/:id/images',
				headers: {
					'Content-Type': 'application/json'
				}
			},			
        	'save' : {
				method : 'POST',
				headers : {
					'Content-Type' : 'application/json'
				}
			}
           
        });
    }
})();
