(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('QuizDetailController', QuizDetailController);


    QuizDetailController.$inject = ['$scope', '$translate', '$stateParams', 'entity', '$state', '$sce', 'previousState'];

    function QuizDetailController ($scope, $translate, $stateParams, entity, $state, $sce, previousState ) {
        var vm = this;
        
        vm.quiz = entity;
        vm.previousState = previousState.name;
        vm.selectInfo = selectInfo;
        vm.selectQuestions= selectQuestions;
        vm.renderHtml = renderHtml;

		vm.tabInfo = 0;
		vm.taQuestions = 1;

		
		if($stateParams.tabs){
			vm.currentTab = $stateParams.tabs;
		}else{
			vm.currentTab = vm.tabInfo;	
		}
		
	    vm.state = $state.current;
	    
	    
	    function selectQuestions(){
	    	$state.go('quiz-detail.questions', {tabs:vm.tabQuestions}, {notify:true});	
	    }
	    
	    function selectInfo() {
	    	$state.go('quiz-detail.info', {tabs:vm.tabInfo}, {notify:true});
	    }
	    
        function renderHtml(html_code){
        	return $sce.trustAsHtml(html_code);
        }      	    
    }
})();
