(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('park', {
            parent: 'entity',
            url: '/park',
            data: {
                authorities: ['ROLE_USER'],            
                pageTitle: 'arbolesDashboardApp.park.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/park/park.html',
                    controller: 'ParkController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                }
            },            
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],                 
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('park');
                    return $translate.refresh();
                }]
            }
        })
        .state('park-detail', {
            parent: 'park',
            url: '/{token}/detail',
            data: {
                pageTitle: 'arbolesDashboardApp.park.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/park/park-detail.html',
                    controller: 'ParkDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('park');
                    $translatePartialLoader.addPart('publishStatus');
                    $translatePartialLoader.addPart('propertyType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ParkService', function($stateParams, ParkService) {
                    return ParkService.get({token : $stateParams.token}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'apps',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })   
		.state('park-detail.info',{
			url : '/info',
			views : {
				'info@park-detail' : {
                    templateUrl: 'app/entities/park/park-detail-info.html',
                    controller: 'ParkDetailInfoController',
                    controllerAs: 'vo'
				}
			},
			params: {
				tabs: 0
            }
		})       
		.state('park-detail.places',{
			url : '/places',
			views : {
				'places@park-detail' : {
                    templateUrl: 'app/entities/park/park-detail-places.html',
                    controller: 'ParkDetailPlacesController',
                    controllerAs: 'vo'
				}
			},
			params: {
				tabs: 1,
                pagey: {
                    value: '1',
                    squash: true
                },
                sorty: {
                    value: 'id,desc',
                    squash: true
                }
            },   
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pagey),
                        sort: $stateParams.sorty,
                        predicate: PaginationUtil.parsePredicate($stateParams.sorty),
                        ascending: PaginationUtil.parseAscending($stateParams.sorty)
                    };
                }]                  
            }
		}) 		        
        .state('park.new', {
            parent: 'park',
            url: '/new',
            views: {
                'content@': {
                    templateUrl: 'app/entities/park/park-edit.html',
                    controller: 'ParkEditController',
                    controllerAs: 'vm'
                }
            },   
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {;
                    $translatePartialLoader.addPart('validation');
                    $translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();
                }],         
                $uibModalInstance: function(){ return null},
                entity: function () {
                    return {
                        id: null
                    };
                }
            }            
        })
        .state('park.edit', {
            parent: 'park',
            url: '/{token}/edit',
            views: {
                'content@': {
                    templateUrl: 'app/entities/park/park-edit.html',
                    controller: 'ParkEditController',
                    controllerAs: 'vm'
                }
            },   
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {    
                	$translatePartialLoader.addPart('validation');
                	$translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();     
                }],
                entity: ['$stateParams', 'ParkService', function($stateParams, ParkService) {
                    return ParkService.get({token : $stateParams.token}).$promise;
                }]
            }            
        })
        .state('park.delete', {
            parent: 'park',
            url: '/{token}/delete',
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/park/park-delete-dialog.html',
                    controller: 'ParkDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ParkService', function(ParkService) {
                            return ParkService.get({token : $stateParams.token}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('park', null, { reload: 'park' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
