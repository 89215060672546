(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        	.state('invitation-link', {
	            url: '/public/account/invitation/{token}',
	            data: {
	                authorities: [],
	                pageTitle: 'register.title'
	            },
	            views: {
	                'global@': {
	                    templateUrl: 'app/public/invitation-link/invitation-link.html',
	                    controller: 'InvitationLinkController',
	                    controllerAs: 'vm'
	                },
	                'step@invitation-link':{
	                		templateUrl: 'app/public/invitation-link/invitation-link-step1.html' 	 
	                }
	                
	            },
	            data:{
                    redirect: ['UserAccountModel', '$stateParams',function(UserAccountModel, $stateParams){
                    	// just check that firstName is in, if not return the state where this is filled
	                    if(!UserAccountModel.firstName || !UserAccountModel.lastName || !UserAccountModel.email) {
	                    	return 'invitation-link.step1';
	                    }else if(!UserAccountModel.password || !UserAccountModel.company) {
	                    	return 'invitation-link.step2';
	                    }else if(!UserAccountModel.userType ) {
	                    	return 'invitation-link.step3';
	                    }
                    }]
                },
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');
	                    $translatePartialLoader.addPart('invitation');
	                    $translatePartialLoader.addPart('register');
	                    return $translate.refresh();
	                }],
	            	invitation: ['$stateParams', 'Account', function($stateParams, Account) {
	                    return Account.getInvitationLinkByToken({token : $stateParams.token}).$promise.then(function(invitation){
                    		return invitation;
	                    });
                	
	            	}],
	            	entity: function () {
                        return {
                        	email:null,
                            firstName: null,
                            lastName: null,
                            password: null,
                            confirmPassword: null,
                            terms: null
                        };
                    }	            	
	            },
	            onEnter:  ['invitation', '$state', function(invitation, $state){
	                if (invitation.status == "INACTIVE") {
	                		$state.go('not-found'); // redirect to 404 in case no invitation has been found with the token
	                }
	            }]
	        })
		    .state("invitation-link.step1", {
			      url: '/user',
			      views: {
				      'step@invitation-link':{
	              			templateUrl: 'app/public/invitation-link/invitation-link-step1.html' 	 
				      }	    	  
			      },
		          data: {
		               redirect: undefined
		           }	      
			 })
		    .state("invitation-link.step2", {
			      url: '/company',
			      views: {
				      'step@invitation-link':{
	              			templateUrl: 'app/public/invitation-link/invitation-link-step2.html' 	 
				      }	    	  
			      },
                  data:{
                      redirect: ['UserAccountModel',function(UserAccountModel){
                    	  // just check that firstName is in, if not return the state where this is filled
                    	  console.log('goToStep2');
                    	  console.log(UserAccountModel);
                    	  if(!UserAccountModel.userAccount.firstName || !UserAccountModel.userAccount.lastName || !UserAccountModel.userAccount.email) {
                    		  console.log('redirecting step1');
                    		  return 'invitation-link.step1';
                          }
                       }]
                  }
			 })			 
		    .state("invitation-link.step3", {
			      url: '/phone',
			      views: {
				      'step@invitation-link':{
	              			templateUrl: 'app/public/invitation-link/invitation-link-step3.html' 	 
				      }	    	  
			      },
                  data:{
                      redirect: ['UserAccountModel',function(UserAccountModel){
                    	  		  // just check that firstName is in, if not return the state where this is filled
                               if(!UserAccountModel.userAccount.firstName || !UserAccountModel.userAccount.lastName || !UserAccountModel.userAccount.email) {
                                    return 'invitation-link.step1';
                               }else if(!UserAccountModel.userAccount.password || !UserAccountModel.userAccount.company) {
                                    return 'invitation-link.step2';
                               }
                       }]
                  }
			})			 
		    .state('invitation-link-success', {
	            url: '/public/account-invitation/success',
	            data: {
	                authorities: [],
	                pageTitle: 'invitation-link.title'
	            },
	            views: {
	                'global@': {
	                    templateUrl: 'app/public/invitation-link/invitation-link-success.html',
	                    controller: 'InvitationLinkSuccessController',
	                    controllerAs: 'vm'
	                }
	            },
                data:{
                    redirect: ['UserAccountModel',function(UserAccountModel){
                  	  		  // just check that firstName is in, if not return the state where this is filled
                         if(!UserAccountModel.userAccount.firstName || !UserAccountModel.userAccount.lastName || !UserAccountModel.userAccount.email) {
                              return 'invitation-link.step1';
                         }else if(!UserAccountModel.userAccount.password || !UserAccountModel.userAccount.company) {
                              return 'invitation-link.step2';
                         }else if(!UserAccountModel.userAccount.userType ) {
                              return 'invitation-link.step3';
                         }
                     }]
                },
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');
	                    $translatePartialLoader.addPart('invitation');
	                    return $translate.refresh();
	                }]               
	            }
	        }
		    
		    );  	        
        		
    }
})();
