(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('SpeciesDetailGalleryController', SpeciesDetailGalleryController);

    SpeciesDetailGalleryController.$inject = ['$timeout', '$state', '$scope', '$stateParams', '$rootScope', 'SpeciesService', 'pagingParams', 'paginationConstants', '$mdToast', 'previousState'];

    function SpeciesDetailGalleryController ($timeout, $state, $scope, $stateParams, $rootScope, SpeciesService, pagingParams, paginationConstants, $mdToast, previousState) {

        var vo = this;
        
		if($stateParams.tabs){
			$scope.$parent.vm.currentTab = $stateParams.tabs;
		}
		
		vo.species = $scope.$parent.vm.species;
        vo.images = [];
        
        vo.itemsSelected = [];
        vo.loading = false;
        
        vo.page = pagingParams.page - 1;
        vo.reset = reset ;

        vo.selectAll = selectAll;
        vo.unSelectAll = unSelectAll;
        vo.toggleItem = toggleItem;
        vo.isChecked = isChecked;
        vo.syncCheck = syncCheck;
        vo.checkActive = false;
        vo.checkOnlyOneActive = false;
        vo.hasNextPage = true;

        vo.showEmpty = false;	
        
        function toggleItem(item) {
            var index = vo.itemsSelected.indexOf(item);
            syncCheck(!isChecked(item),item);
        }

        function isChecked(item){
            for (var i = 0; i < vo.itemsSelected.length; i++){
                if (vo.itemsSelected[i].id == items.id){
                    return true;
                }
            }
            return false;
        }
          
        function syncCheck(bool, item) {
            if(bool){
                // add item
                vo.itemsSelected.push(item);
              } else {
                // remove item
                for (var i = 0; i < vo.itemsSelected.length; i++){
                  if(vo.itemsSelected[i].id == item.id){
                	  vo.itemsSelected.splice(i,1);
                  }
                }      
              }
            vo.checkActive = vo.itemsSelected.length>0;
            vo.checkOnlyOneActive = vo.itemsSelected.length===1;
        }

        function selectAll() {
            vo.itemsSelected = [];
            for (var i = 0; i < vo.images.length; ++i) {
                vo.itemsSelected.push(vo.images[i]);
            }

            vo.checkActive = vo.itemsSelected.length>0;
            vo.checkOnlyOneActive = vo.itemsSelected.length===1;
        }

        function unSelectAll() {
            vo.itemsSelected = [];
            vo.checkActive = false;
            vo.checkOnlyOneActive = false;
        }
              
        function sort () {
            var result = [vo.predicate + ',' + (vo.reverse ? 'asc' : 'desc')];
            if (vo.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        
        function reset () {
            vo.images = [];
            vo.showEmpty = false;
        }
        
        //-- modal con información de la planta y ubicación
        function goToDetail(entity){
    		$state.go('species-image-detail', { id:entity.id });
        } 
    }
})();