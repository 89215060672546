(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('BusinessSignoutLegalController', BusinessSignoutLegalController);

    BusinessSignoutLegalController.$inject = ['$scope', '$state', '$stateParams', '$uibModalInstance'];

    function BusinessSignoutLegalController($scope, $state, $stateParams, $uibModalInstance) {

        var vm = this;

        vm.showTerms = false;
        vm.shoPrivacy = false;
        vm.clear = clear;
        vm.accept = accept;


        vm.isSaving = false;
        vm.state = $state.current;
   
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
   		
        function accept () {
        		$scope.$emit('arbolesDashboardApp:registerLegalAccept');
		
        		vm.isSaving = false;
        		$uibModalInstance.close(vm.operation);
        }
    }
})();
