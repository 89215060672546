(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('Password', Password);

    Password.$inject = ['$resource', 'SERVER_API_URL'];

    function Password($resource, SERVER_API_URL) {
        var service = $resource(SERVER_API_URL + 'api/account/change_password', {}, {});

        return service;
    }
})();
