(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('registerInterceptor', registerInterceptor);

    registerInterceptor.$inject = ['$rootScope', '$state', '$injector', '$location'];

    function registerInterceptor ($rootScope, $state, $injector, $location) {
        var injector = {
            request: request
        };

        return injector;

        function request (config) {
        		console.log('registerInterceptor');
            $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
                if (toState.data && toState.data.redirect) {
                  var redirectTo = $injector.invoke(toState.data.redirect, this, {
                    toStateParams: toParams,
                    toState: toState
                  });
                  console.log(redirectTo);

                  if (redirectTo) {
                    event.preventDefault();
                    $state.transitionTo(redirectTo);
                  }
                }
            });
        }
    }
})();
