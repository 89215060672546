(function () {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .service('ProspectionModel', function () {
        		this.prospection = {};
        })
        .factory('Prospection', Prospection);
    
    
Prospection.$inject = ['$resource', 'SERVER_API_URL'];

function Prospection ($resource, SERVER_API_URL) {
	var baseUrl = SERVER_API_URL + 'api/v1/prospection';
    var service = $resource(baseUrl, {}, {
        'checkEmail': {
            method: 'GET',
            url: baseUrl + '/email',
            isArray: false,
        	headers: {
        		'Content-Type': 'application/json'
        	}
        },
        'getProspection': {
            method: 'GET',
            url: baseUrl + '/:token',
            isArray: false,
        	headers: {
        		'Content-Type': 'application/json'
        	}
        },        
        /* CREATE PROSPECTION */
        'createProspection': {
            url: baseUrl + '/signout',
            method: 'POST',
            isArray: false,
            headers: {
            	'Content-Type': 'application/json'
            }            	
        },
        'confirmProspection': {
            url: baseUrl + '/activate',
            method: 'POST',
            isArray: false,
            headers: {
            	'Content-Type': 'application/json'
            }            	
        }         
    });

    return service;
}

})();
