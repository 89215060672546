(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('ProductItemDeleteController',ProductItemDeleteController);

    ProductItemDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'business', 'Business'];

    function ProductItemDeleteController($scope, $uibModalInstance, entity, business, Business) {
        var vm = this;

        vm.items = entity;
        vm.business = business;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.listOfItems = false;
        var itemsIds = [];
        if(vm.items.length > 1){
        	vm.listOfItems = true;
        }
        
        init();

        function init() {
        	itemsIds = vm.items.map(item => item.id);
        }        

        function clear () {
            $uibModalInstance.close();
        }

        function confirmDelete () {
            Business.deleteItems({ id:vm.business.id, items: itemsIds }, onSuccessDelete, onError);
             
        }
        
        function onSuccessDelete() {
            $scope.$emit('arbolesDashboardApp:itemDelete', itemsIds);
            clear();
        }

        function onError(err) {
            console.error(err);
        }        
    }
})();
