(function () {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('User', User);

    User.$inject = ['$resource', 'SERVER_API_URL'];

    function User ($resource, SERVER_API_URL) {
        var service = $resource(SERVER_API_URL + 'api/users/:login', {}, {
            'query': {method: 'GET',
            	headers: {
            		'Content-Type': 'application/json'
            	}, isArray: true},
            'get': {
                method: 'GET',
            	headers: {
            		'Content-Type': 'application/json'
            	},
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { 
            	method:'POST',
            	headers: {
            		'Content-Type': 'application/json'
            	}
            },
            'update': { 
            	method:'PUT',
            	headers: {
            		'Content-Type': 'application/json'
            	}
            },
            'delete':{ method:'DELETE'}
        });

        return service;
    }
})();
