(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('InvoiceController', InvoiceController);

   InvoiceController.$inject = ['$timeout', '$state', '$scope', '$stateParams', '$rootScope', 'Invoice', 'pagingParams', 'paginationConstants', '$mdToast'];

    function InvoiceController ($timeout, $state, $scope, $stateParams, $rootScope, Invoice, pagingParams, paginationConstants, $mdToast) {

        var vm = this;

		
        vm.invoices = [];
        
        vm.itemsSelected = [];
        vm.loading = false;
        vm.filter = {};
        
        vm.page = pagingParams.page - 1;
        vm.reset = reset ;
        vm.loadPage = loadPage;

        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        
        vm.showFilter = showFilter;
        vm.closeFilter = closeFilter;
        vm.noCloseFilter = noCloseFilter;
        vm.doFilter = doFilter;
		vm.cleanFilter = cleanFilter;

        vm.selectAll = selectAll;
        vm.unSelectAll = unSelectAll;
        vm.toggleItem = toggleItem;
        vm.isChecked = isChecked;
        vm.syncCheck = syncCheck;
        vm.checkActive = false;
        vm.checkOnlyOneActive = false;
        vm.hasNextPage = true;

        vm.showEmpty = false;
        vm.removeTag = removeTag;
        
        vm.goToDetail = goToDetail;

        angular.element(document).ready(function () { 
        	loadPage(0);
        });


        /*----------------------------- LOADS APPs --------------------------------*/
        function loadInvoices() {
        	
            if (vm.filter.data) {
            	Invoice.filter({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()},
                    vm.filter, onSuccess, onError);
            }else {
            	
            	Invoice.query({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            
        }
        
        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.hasNextPage = headers('X-Has-Next-Page') === "true";
            vm.queryCount = vm.totalItems;
            
            for (var i = 0; i < data.length; i++) {
                vm.invoices.push(data[i]);
            }
            vm.showEmpty = true;
        	vm.loading = false;   
        }

        function onError(error) {
            AlertService.error(error.data.message);
            vm.loading = false;
            vm.showEmpty = true;
        }

		
        
        function toggleItem(item) {
            var index = vm.itemsSelected.indexOf(item);
            syncCheck(!isChecked(item),item);
        }

        function isChecked(item){
            for (var i = 0; i < vm.itemsSelected.length; i++){
                if (vm.itemsSelected[i].id == items.id){
                    return true;
                }
            }
            return false;
        }
        
        function syncCheck(bool, item) {
            if(bool){
                // add item
                vm.itemsSelected.push(item);
              } else {
                // remove item
                for (var i = 0; i < vm.itemsSelected.length; i++){
                  if(vm.itemsSelected[i].id == item.id){
                	  vm.itemsSelected.splice(i,1);
                  }
                }      
              }
            vm.checkActive = vm.itemsSelected.length>0;
            vm.checkOnlyOneActive = vm.itemsSelected.length===1;
        }

        function selectAll() {
            vm.itemsSelected = [];
            for (var i = 0; i < vm.invoices.length; ++i) {
                vm.itemsSelected.push(vm.invoices[i]);
            }

            vm.checkActive = vm.itemsSelected.length>0;
            vm.checkOnlyOneActive = vm.itemsSelected.length===1;
        }

        function unSelectAll() {
            vm.itemsSelected = [];
            vm.checkActive = false;
            vm.checkOnlyOneActive = false;
        }
        
        function filterById(userBusiness) {
            return vm.filter[userBusiness.id] || noFilter(vm.filter);
        }
        
        function showFilter(){
        		vm.showFilters = true;        	
        }
        
        function closeFilter(){
        		vm.showFilters = false;        	
        }
        
        function noCloseFilter($event) {
			if (window.event) {
				window.event.stopPropagation();
			}
		}
        
        function doFilter() {
			vm.filter.data = true;
			reset();
			closeFilter();
		}
		
		function cleanFilter() {
			vm.filter = {};
			vm.filter.data = false;

			reset();
			closeFilter();
		}		
		
	     
                
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        
        function reset () {
            vm.invoices = [];
            vm.showEmpty = false;
            vm.page = 1;
            loadPage(vm.page);
            unSelectAll();
        }

        function loadPage(page) {
            if (vm.loading) return;
            
            vm.loading = true;
            vm.page = page;
            loadInvoices(); 
        } 
        
        function goToDetail(entity){
    		$state.go('invoice-detail', { id:entity.id });
        }

        function removeTag(tag) {
            vm.filter = {}
            reset();
        }    
    }
})();