(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('BusinessActivateController', BusinessActivateController);

    BusinessActivateController.$inject = ['$window', '$stateParams', '$scope', '$rootScope', '$timeout', '$state', 'entity', 'Prospection', '$uibModal'];

    function BusinessActivateController ($window, $stateParams, $scope, $rootScope, $timeout, $state, entity, Prospection, $uibModal) {
        var vm = this;
        
        $window.loading_screen.finish();
        
        vm.isSaving = false;
        vm.acceptLegal = acceptLegal;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;        
        vm.prospection = entity;
        vm.prospectionConfirm = vm.prospection;

        $timeout(function (){angular.element('#password').focus();});
        
        $scope.$watch("vm.confirmPassword",function(newValue, oldValue) {
        	validatePassword(newValue);
        });        
        
        function validatePassword( confirmPassword ){
        	if (confirmPassword === vm.password){
        		$scope.form.confirmPassword.$setValidity('checkPassword', true);
        	}else{
        		$scope.form.confirmPassword.$setValidity('checkPassword', false);
        	}
        }
        /*
        Auth.activateAccount({key: $stateParams.key}).then(function () {
            vm.error = null;
            vm.success = 'OK';
        }).catch(function () {
            vm.success = null;
            vm.error = 'ERROR';
        });
*/
        // vm.login = LoginService.open;
        
        var unsubscribe = $rootScope.$on('arbolesDashboardApp:registerLegalAccept', function(event, result) {
            //--- registro del usuario y 
        	console.log('confirmacion de condiciones');
        	console.log(vm.prospectionConfirm);
        	changePassword();
        });    
        
        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;

                vm.prospectionConfirm.password = vm.password;
                vm.prospectionConfirm.token = vm.prospection.token;
                vm.prospectionConfirm.appKey = vm.prospection.appKey;
                console.log(vm.prospectionConfirm);
                console.log('token ' + vm.prospection.token);
                console.log('app ' + vm.prospection.appKey);
                Prospection.confirmProspection(vm.prospectionConfirm,onSaveSuccess,onSaveError);
            }
        }     
        
        
        function onSaveSuccess(){
            vm.error = null;
            vm.success = 'OK';
            $state.transitionTo('business-activate-success', {token:  $stateParams.token})        	
        }
        
        function onSaveError(){
            vm.success = null;
            vm.error = 'ERROR';        	
        }
        function acceptLegal(form){
        	validatePassword(vm.confirmPassword);
    		if (form.$invalid){ 
    			return; 
    		}   
    		showLegalModal();
        }        
        
		//-- Modal aceptación de condiciones
        function showLegalModal(){
			
			if (window.event) {
				window.event.stopPropagation();
			}
			
			var modalInstance = $uibModal
					.open({
						templateUrl : 'app/public/business/business-signout-legal-dialog.html',
						controller : 'BusinessSignoutLegalController',
						controllerAs : 'vm',
						scope : $scope, // passed current scope to the modal
						backdrop : 'static',
						size : 'lg',
						resolve : {
							dataState : function() {
								return {
									createLabel : 'arbolesDashboardApp.invitation.accept'
								};
							},
							translatePartialLoader : [
									'$translate',
									'$translatePartialLoader',
									function($translate,$translatePartialLoader) {
										$translatePartialLoader.addPart('register');
										return $translate.refresh();
									} ]
						}									
					});
		}        
    }
})();
