(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('NewsDeleteController',NewsDeleteController);

    NewsDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'News'];

    function NewsDeleteController($scope, $uibModalInstance, entity, News) {
        var vm = this;

        vm.news = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.listOfNews = false;
        var newsIds = [];
        if(vm.news.length > 1){
        	vm.listOfNews = true;
        }
        
        init();

        function init() {
        	newsIds = vm.news.map(news => news.id);
        }        

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            News.deleteNews({news: newsIds},
                function () {
                    $uibModalInstance.close(true);
                });
                
            if(vm.news.length > 1) {
            	Category.deleteNews({ news: newsIds }, onSuccessDelete, onError);
            }else {
            	Category.delete({ id: vm.news.id }, onSuccessDelete, onError);
            }                
        }
        
        function onSuccessDelete() {
            $scope.$emit('arbolesDashboardApp:newsDelete');
            clear();
        }

        function onError(err) {
            console.error(err);
        }        
    }
})();
