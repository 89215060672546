(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('BusinessSignoutSimpleController', BusinessSignoutSimpleController);
    
    
    BusinessSignoutSimpleController.$inject = ['$translate', '$window', '$timeout', 'Register', 'entity', '$state', '$scope', '$rootScope', '$browser', '$location', 'ProspectionModel', 'Prospection', 'registerInterceptor', 'StripeService', '$injector', '$uibModal', '$stateParams'];

    function BusinessSignoutSimpleController ($translate, $window, $timeout, Register, entity, $state, $scope, $rootScope, $browser, $location, ProspectionModel, Prospection, registerInterceptor, StripeService, $injector, $uibModal, $stateParams) {
        var vm = this;

        vm.loadingPage = true;
        $window.loading_screen.finish();
        
        vm.fullScreen = true;
        ProspectionModel.prospection = entity;
        vm.prospection = entity;
        vm.licenseType = null;

        vm.state = $state.current;
        vm.goStep1 = goStep1;
        
        vm.doNotMatch = null;
        vm.error = false;
        vm.errorUserExists = false;
        vm.doNotMatch = false;
        vm.goToSuccess = goToSuccess;
        vm.acceptLegal = acceptLegal;
        vm.success = false;
        vm.isSaving = false;
        vm.valid = false;

        vm.rateLabel = 'register.form.no_iva';
		vm.licenses = [
			{name:'PREMIUM',price:150},
			{name:'VIP',price:300}];


        $timeout(function (){ angular.element('.form-group:eq(1)>input').focus();});

        var unsubscribe = $rootScope.$on('arbolesDashboardApp:registerLegalAccept', function(event, result) {
            //--- registro del usuario y 
        	register();
        });    
        
        
        var stripe = StripeService.initStripe();
        vm.card;
        vm.iban;
        
        var style = {
        		  base: {
        			    color: '#32325d',
        			    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        			    fontSmoothing: 'antialiased',
        			    fontSize: '16px',
        			    '::placeholder': {
        			      color: '#aab7c4'
        			    }
        			  },
        			  invalid: {
        			    color: '#fa755a',
        			    iconColor: '#fa755a'
        			  }        			  
        };
        
        //$scope.init = function(){
	    angular.element(document).ready(function () {
	    	 $timeout(function (){
	        	console.log('init prospection');
	        	console.log(stripe);
	        	checkDataBetweenSteps();
	        	
	            // I'd like to run this on document ready
	         	var elements = stripe.elements();	
	         
	         	console.log('stripe elements ');
	         	console.log(elements);
	         	
	         	// Create an instance of the card Element.
	         	var card = elements.create('card', {style: style, hidePostalCode: true});
	         	vm.card = card;
	         	
	         	console.log('card');
	         	console.log(card);
	         	
	         	// Add an instance of the card Element into the `card-element` <div>.
	         	card.mount('#card-element');
	         	
	         	card.addEventListener('change', cardChange);
	         	
	         	
	         	//-- Create an instance of the iban Element
	         	var options = {
	         			style: style,
	         			supportedCountries: ['SEPA'],
	         			  // If you know the country of the customer, you can optionally pass it to
	         			  // the Element as placeholderCountry. The example IBAN that is being used
	         			  // as placeholder reflects the IBAN format of that country.         			
	         			placeholderCountry: 'ES'
	         	}
	         	
	         	var iban = elements.create('iban', options);
	         	vm.iban = iban;
	         	console.log(iban);
	         	iban.mount('#iban-element');
	         	iban.addEventListener('change', ibanChange);    
	         	
	         	//vm.loadingPage = false;
	         });
	    });

        //$scope.init(); //
        
        function cardChange(e) {
        	console.log('cardChange ');
        	console.log(e);
        	vm.cardErrors = e.error ? e.error.message : ''
/*  		  var displayError = document.getElementById('card-errors');
  		  if (event.error) {
  		    displayError.textContent = event.error.message;
  		  } else {
  		    displayError.textContent = '';
  		  }*/
        }    
        
        function ibanChange(e) {
        	console.log('ibanChange ');
        	console.log(e);
        	vm.ibanErrors = e.error ? e.error.message : ''
/*  		  var displayError = document.getElementById('card-errors');
  		  if (event.error) {
  		    displayError.textContent = event.error.message;
  		  } else {
  		    displayError.textContent = '';
  		  }*/
        }   
        
		
        $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {	
            if (toState.data && toState.data.redirect) {
              var redirectTo = $injector.invoke(toState.data.redirect, this, {
                toStateParams: toParams,
                toState: toState
              });

              if (redirectTo) {
                event.preventDefault();
                $state.transitionTo(redirectTo);
              }
            }
        });
        
        $scope.$watch('vm.prospection.iva', function (){
        	if (vm.prospection.iva){
        		vm.rateLabel='register.form.iva';
        	}else{
        		vm.rateLabel='register.form.no_iva';
        	}
        });
        
        
        function checkDataBetweenSteps(){
      		if (vm.state.name!='business-signout-simple.step'){
      			if (!vm.prospection.firstName || vm.valid===false){
      				ProspectionModel.prospection = vm.prospection;
      				goStep1();
      			}
      		}
        }
        
        function acceptLegal(form){
	    		if (form.$invalid){ 
	    			return; 
	    		}   
	    		showLegalModal();
        }
        
        function register () { 
        		vm.isSaving = true;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                
                //--- establecemos los datos adicionales para la información de pago
                var additionalData = {
                	      name: vm.prospection.paymentName ? vm.prospection.paymenName : undefined
                };
                
                if (vm.prospection.paymentMethod == 'CREDIT_CARD' || vm.prospection.paymentMethod == 'IBAN'){
                	var type = 'card';
                	vm.paymentData = vm.card;
                	
                	if (vm.prospection.paymentMethod == 'IBAN'){
                		vm.paymentData = vm.iban;
                		type = 'sepa_debit';
                	}
                	
                /*
                	stripe.createToken(vm.payment, additionalData).then(function(result) {
	            	    if (result.error) {
	            	      // Inform the customer that there was an error.
	            	      var errorElement = $document[0].getElementById('card-errors');
	            	      errorElement.textContent = result.error.message;
	            	    } else {
	            	      // Send the token to your server.
	            	      stripeTokenHandler(result.token);
	            	    }
	            	  });
                */	
                	var sourceData = {
            			    //livemode:false,
            			    type: type,
            			    currency: 'eur',               
            			    usage: 'reusable',
                			owner: {
                			    name: vm.prospection.paymentName,
                			    email: vm.prospection.email,
                			    address: {
                			      line1: vm.prospection.addressStreet,
                			      city: vm.prospection.addressCity,
                			      postal_code: vm.prospection.addressZip,
                			      country: 'ES',
                			    }
                			  },
                			};
                	
                	 stripe.createSource(vm.paymentData, sourceData).then(function(result) {
                		    if (result.error) {
                		      // Inform the user if there was an error
                		      var errorElement = document.getElementById('card-errors');
                		      errorElement.textContent = result.error.message;
                		    } else {
                		      // Send the source to your server
                		      stripeSourceHandler(result.source);
                		    }
                	 });                	
                	
                }else{
                    ProspectionModel.prospection = vm.prospection;

                    Prospection.createProspection(vm.prospection,onSaveSuccess,onSaveError);
                }
        }
        
        function stripeTokenHandler(token){
            ProspectionModel.prospection = vm.prospection;

            vm.prospection.stripeToken = token;
            Prospection.createProspection(vm.prospection,onSaveSuccess,onSaveError);
        }
        
        function stripeSourceHandler(source) {
            ProspectionModel.prospection = vm.prospection;

            vm.prospection.stripeSource = source.id;        	
            Prospection.createProspection(vm.prospection,onSaveSuccess,onSaveError);
        }
        
        
        function onSaveSuccess(result){
        	vm.success = 'OK';
            vm.isSaving = false;
            $state.transitionTo('business-signout-simple-success');
        }
        
        function onSaveError(response){
        	vm.isSaving = false;
            vm.success = null;
            if (response.status === 400 && response.data === 'login already in use') {
                vm.errorUserExists = 'ERROR';
            } else if (response.status === 400 && response.data === 'email address already in use') {
                vm.errorEmailExists = 'ERROR';
            }
                
        }
        
        function goStep1(){
        		ProspectionModel.prospection = vm.prospection;
        		$state.transitionTo('business-signout-simple.step',{token:$stateParams.token}).then(function() {
        			vm.state = $state.current;
        		});
        }                      

        
        function goToSuccess() {
            $state.go('business-signout-simple-success');
        }
          
		
		$scope.$watch("vm.prospection.email", function(newValue, oldValue){
			//-- compobar que no exista ya un usuario u otra prospección con este correo electrónico
			if (newValue){
				Prospection.checkEmail({email: vm.prospection.email, appKey: vm.prospection.appKey})
					.$promise.then(function (result) {
	    			
						$scope.form.email.$setValidity('checkProspection', true);
	    			/*    				if (result.response == 'ALREADY_USER'){
	    			    					$scope.editFormOperation.email.$setValidity('checkProspection', false);
	    			    					return;
	    			    				}else{
	    			    					return;
	    			    				}
	    			    				*/
	    			        			// Account.inviteAccountMember(vm.invitation, onSaveSuccess, onSaveError);
	    			    	        }).catch(function (response) {
	    			    	        		console.log('error comprobación correo electrónico');
	    			    	        		$scope.form.email.$setValidity('checkProspection', false);
	    			    	        });
			}
			//$scope.form.email.$setValidity('checkProspection', true);
		});		

		$scope.$watch("vm.licenseType", function (newValue, oldValue){
			//-- comprobamos si ha habido algun cambio de valor
			// console.log('cambio licencia');
			if (newValue != oldValue){
				// console.log(newValue);
				//--identificamos que valor de licencia se ha seleccionado
				var license = vm.licenses.filter(function(item) { return item.name === newValue; });
				// console.log(license);
				// console.log(license.length);
				if (license && license.length>0){
					vm.prospection.price = license[0].price;
					vm.prospection.type = license[0].name;
				}else if (newValue){
					vm.prospection.price = newValue.price;
					vm.prospection.type = newValue.name;		

				}
			}
		
		});
		
		
		//-- Modal aceptación de condiciones
		
        function showLegalModal(){
			
			if (window.event) {
				window.event.stopPropagation();
			}
			
			var modalInstance = $uibModal
					.open({
						templateUrl : 'app/public/business/business-signout-legal-dialog.html',
						controller : 'BusinessSignoutLegalController',
						controllerAs : 'vm',
						scope : $scope, // passed current scope to the modal
						backdrop : 'static',
						size : 'lg',
						resolve : {
							dataState : function() {
								return {
									createLabel : 'arbolesDashboardApp.invitation.accept'
								};
							},
							translatePartialLoader : [
									'$translate',
									'$translatePartialLoader',
									function($translate,$translatePartialLoader) {
										$translatePartialLoader.addPart('register');
										return $translate.refresh();
									} ]
						}									
					});
		}			
    }
})();
