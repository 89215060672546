(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('ProductItemDetailController', ProductItemDetailController);

    ProductItemDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'business', 'Product', '$state', '$uibModalInstance', '$uibModal'];

    function ProductItemDetailController($scope, $rootScope, $stateParams, DataUtils, entity, business, Product, $state, $uibModalInstance, $uibModal) {
        var vm = this;

        vm.product = entity;
        vm.business = business;


        vm.updatingStatus = false;     
        vm.showUpdateStatus = showUpdateStatus;
        vm.updateStatus = updateStatus;        
        vm.statusToUpdate = vm.product.status;
        vm.goToEdit = goToEdit;
        vm.clear = clear;
     
//        var productDelete = $rootScope.$on('arbolesDashboardApp:productDelete', function(event, result) {
//			reset();
//        });   
//        
//        var unsubscribe = $rootScope.$on('arbolesDashboardApp:productImageDelete', function(event, result) {
//			reset();
//        });   
//        
//        var productAddImage = $rootScope.$on('arbolesDashboardApp:productAddImage', function(event, result) {
//	    	console.log('added subbusiness to business');
//	    	vm.images.push(result);
//        });           
                
//        var afterDeleted = $scope.$on('arbolesDashboardApp:deleteImages', function(event, result) {
//        	$state.transitionTo('business');
//        }); 
//        
//        $scope.$on('$destroy', unsubscribe);
//        $scope.$on('$destroy', productAddImage);
//        $scope.$on('$destroy', productDelete);
//        
//  
        function goToEdit(){
        	 $uibModalInstance.close(vm.product);  
        	 $scope.$emit('arbolesDashboardApp:itemEdit', vm.product);
        }
        
        
        function showUpdateStatus(show){
    		if (show){
    			vm.statusToUpdate = vm.product.status;
    		}
    		vm.updatingStatus = show;
	    }
	    
	    function updateStatus(){
	    		if (vm.product.status != vm.statusToUpdate){
	    			vm.product.status = vm.statusToUpdate;
	    			Product.updateStatus({id:vm.product.id}, vm.product, onUpdateStatusSuccess,onUpdateStatusError);
	    		}
	    }
	
	    function onUpdateStatusSuccess(data, headers) {
	    		showUpdateStatus(false);
	    }
	    
	    function onUpdateStatusError (data, headers){
	    		console.log('actualización de estado mal!!!');
	    }       
	    

        function clear () {
            $uibModalInstance.close();
         }
        
    
     }
})();
