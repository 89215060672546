(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('TermDetailPhotoController', TermDetailPhotoController);

    TermDetailPhotoController.$inject = ['$timeout', '$state', '$scope', '$stateParams', '$rootScope', 'TermService', 'pagingParams', 'paginationConstants', '$mdToast', '$uibModal', 'previousState'];

    function TermDetailPhotoController ($timeout, $state, $scope, $stateParams, $rootScope, TermService, pagingParams, paginationConstants, $mdToast, $uibModal, previousState) {

        var vm = this;
        vm.goToDetail = goToDetail;
        vm.addGallery = addGallery;
      

        vm.gallery = [];
        
        vm.itemsSelected = [];
        vm.loading = false;
        vm.filter = {};
        
        vm.page = pagingParams.page - 1;
        vm.reset = reset ;
        vm.loadPage = loadPage;

        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        
        vm.selectAll = selectAll;
        vm.unSelectAll = unSelectAll;
        vm.toggleItem = toggleItem;
        vm.isChecked = isChecked;
        vm.syncCheck = syncCheck;
        vm.checkActive = false;
        vm.checkOnlyOneActive = false;
        vm.hasNextPage = true;

        vm.showEmpty = false;
        

        

        angular.element(document).ready(function () { 
        	loadPage(0);
        });

		var photoUpdate = $scope.$on('arbolesDashboardApp:photoUpdate', function(event, result) {
			vm.photo = result.photo;
			reset();
			//addGallery();
        });
        $scope.$on('$destroy', photoUpdate);
        
        /*----------------------------- LOADS QUESTIONs --------------------------------*/
        function loadGallery() {
            	TermService.getImages({
            		id: $stateParams.id,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
        }
        
        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.hasNextPage = headers('X-Has-Next-Page') === "true";
            vm.queryCount = vm.totalItems;
            
            for (var i = 0; i < data.length; i++) {
                vm.gallery.push(data[i]);
            }
            vm.showEmpty = true;
        	vm.loading = false;   
        }

        function onError(error) {
            AlertService.error(error.data.message);
            vm.loading = false;
            vm.showEmpty = true;
        }

		
        
        function toggleItem(item) {
            var index = vm.itemsSelected.indexOf(item);
            syncCheck(!isChecked(item),item);
        }

        function isChecked(item){
            for (var i = 0; i < vm.itemsSelected.length; i++){
                if (vm.itemsSelected[i].id == item.id){
                    return true;
                }
            }
            return false;
        }
        
        function syncCheck(bool, item) {
            if(bool){
                // add item
                vm.itemsSelected.push(item);
              } else {
                // remove item
                for (var i = 0; i < vm.itemsSelected.length; i++){
                  if(vm.itemsSelected[i].id == item.id){
                	  vm.itemsSelected.splice(i,1);
                  }
                }      
              }
            vm.checkActive = vm.itemsSelected.length>0;
            vm.checkOnlyOneActive = vm.itemsSelected.length===1;
        }

        function selectAll() {
            vm.itemsSelected = [];
            for (var i = 0; i < vm.gallery.length; ++i) {
                vm.itemsSelected.push(vm.gallery[i]);
            }

            vm.checkActive = vm.itemsSelected.length>0;
            vm.checkOnlyOneActive = vm.itemsSelected.length===1;
        }

        function unSelectAll() {
            vm.itemsSelected = [];
            vm.checkActive = false;
            vm.checkOnlyOneActive = false;
        }
        
	     
                
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        
        function reset () {
        
            vm.showEmpty = false;
            vm.page = 1;
            loadPage(vm.page);
            unSelectAll();
        }

        function loadPage(page) {
            if (vm.loading) return;
            
            vm.loading = true;
            vm.page = page;
            loadGallery(); 
        } 
        
 

        function goToEdit(photo){
        	console.log('edit photo');
        }
        
        function showDeleteModal(items) {
			if (window.event) {
				window.event.stopPropagation();
            }
			
			var modalInstance = $uibModal
                .open({
                    templateUrl : 'app/entities/photo/photo-delete-dialog.html',
                    controller : 'PhotoDeleteController',
                    controllerAs : 'vm',
                    scope : $scope, // passed current scope to the modal
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        dataState : function() {
                            return {
                                createLabel : 'arbolesDashboardApp.invitation.accept'
                            };
                        },
                        translatePartialLoader : [
                                '$translate',
                                '$translatePartialLoader',
                                function($translate,$translatePartialLoader) {
                                    $translatePartialLoader.addPart('photo');
                                    return $translate.refresh();
                                } ],
                        entity: () => items
                    }
                });

		}   
        
        
        
        
        
        

		function addGallery() {
			var openAddPromoModalInstance = $uibModal
					.open({
						

						
						templateUrl 	: 'app/entities/photo/photo-edit-dialog.html',
						controller : 'PhotoEditController',
						controllerAs : 'vm',
						
						backdrop : 'static',
						size : 'xl',
						resolve : {
							translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
								$translatePartialLoader.addPart('photo');
								return $translate.refresh();
							}],    
			                entity: function () {
			                    return {
			                    	id: null,
			                        termId: $stateParams.id
			                    };
			                },    
			                term: function () {
			                    return $scope.$parent.vm.term;
			                }   			                
						}
					});
		}        
    }
})();