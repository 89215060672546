(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('TagDetailGalleryController', TagDetailGalleryController);

    TagDetailGalleryController.$inject = ['$timeout', '$state', '$scope', '$stateParams', '$rootScope', 'TagService', 'pagingParams', 'paginationConstants', '$mdToast', '$uibModal', 'previousState'];

    function TagDetailGalleryController ($timeout, $state, $scope, $stateParams, $rootScope, TagService, pagingParams, paginationConstants, $mdToast, $uibModal, previousState) {

        var vm = this;
        
		if($stateParams.tabs){
			$scope.$parent.vm.currentTab = $stateParams.tabs;
			loadPage(0);
		}
		
		vm.tag = $scope.$parent.vm.tag;
        vm.images = [];
        vm.filter = {};
        vm.loading = false;
        vm.showEmpty = true;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page - 1;
        vm.reset = reset ;
        vm.clear = clear;
        vm.loadPage = loadPage;
        vm.hasNextPage = true;
        vm.addGallery = addGallery;
        vm.removeGallery=removeGallery;        
        vm.itemsSelected = [];
        vm.loading = false;
        
        vm.page = pagingParams.page - 1;
        vm.reset = reset ;

        vm.selectAll = selectAll;
        vm.unSelectAll = unSelectAll;
        vm.toggleItem = toggleItem;
        vm.isChecked = isChecked;
        vm.syncCheck = syncCheck;
        vm.checkActive = false;
        vm.checkOnlyOneActive = false;
        vm.hasNextPage = true;

        vm.showEmpty = false;
        
   	 	$scope.image=vm.tag.image?vm.tag.image.url:'/content/images/factory.png';
   	 	
        var photoAdded = $scope.$on('arbolesDashboardApp:tagPhotoGalleryAdd', function(event, result){
        	console.log('photo added on gallery');
            reset();
        });  
        $scope.$on('$destroy',photoAdded);   	 	

        function loadImages() {
    		TagService.getGallery({
    			id: $stateParams.id,
                page: vm.page,
                size: vm.itemsPerPage
            }, onSuccess, onError);
        }
                
        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.hasNextPage = headers('X-Has-Next-Page') === "true";
            vm.queryCount = vm.totalItems;
            
            for (var i = 0; i < data.length; i++) {
                vm.images.push(data[i]);
            }
            vm.showEmpty = true;
        	vm.loading = false;   
        }

        function onError(error) {
            //AlertService.error(error.data.message);
            vm.loading = false;
            vm.showEmpty = true;
        }
        
        function loadPage(page) {
            if (vm.loading) return;
            
            vm.loading = true;
            vm.page = page;
            loadImages(); 
        }  
                        
        function toggleItem(item) {
            var index = vm.itemsSelected.indexOf(item);
            syncCheck(!isChecked(item),item);
        }

        function isChecked(item){
            for (var i = 0; i < vm.itemsSelected.length; i++){
                if (vm.itemsSelected[i].id == items.id){
                    return true;
                }
            }
            return false;
        }
        
        function syncCheck(bool, item) {
            if(bool){
                // add item
                vm.itemsSelected.push(item);
              } else {
                // remove item
                for (var i = 0; i < vm.itemsSelected.length; i++){
                  if(vm.itemsSelected[i].id == item.id){
                	  vm.itemsSelected.splice(i,1);
                  }
                }      
              }
            vm.checkActive = vm.itemsSelected.length>0;
            vm.checkOnlyOneActive = vm.itemsSelected.length===1;
        }

        function reset () {
            vm.images = [];
            vm.showEmpty = false;
            loadPage(0);
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function removeGallery() {
        	console.log('removeGallery', $stateParams.id);
            	TagService.deleteImages({
            		id: $stateParams.id,
            		 page: vm.page,
                     size: vm.itemsPerPage
                }, onSuccessDelete, onError); 	
        }
       
        
        function onSuccessDelete() {
            $scope.$emit('arbolesDashboardApp:tagGalleryDelete');
            clear();
        }

      
		function addGallery() {
			var openAddPromoModalInstance = $uibModal
					.open({
						templateUrl 	: 'app/entities/tag/tag-photo-edit-dialog.html',
						controller : 'TagPhotoEditController',
						controllerAs : 'vm',
						
						backdrop : 'static',
						size : 'xl',
						resolve : {
							translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
								$translatePartialLoader.addPart('photo');
								return $translate.refresh();
							}],    
			                entity: function () {
			                    return {
			                    	id: null,
			                        tagId: $stateParams.id
			                    };
			                },    
			                tag: function () {
			                    return vm.tag;
			                }   			                
						}
					});
		}
		
        function selectAll() {
            vm.itemsSelected = [];
            for (var i = 0; i < vm.images.length; ++i) {
                vm.itemsSelected.push(vm.images[i]);
            }

            vm.checkActive = vm.itemsSelected.length>0;
            vm.checkOnlyOneActive = vm.itemsSelected.length===1;
        }

        function unSelectAll() {
            vm.itemsSelected = [];
            vm.checkActive = false;
            vm.checkOnlyOneActive = false;
        }
              
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        
        //-- modal con información de la planta y ubicación
        function goToDetail(entity){
    		$state.go('tag-gallery-detail', { id:entity.id });
        }
    }
})();