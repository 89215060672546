(function() {
	'use strict';
	angular.module('arbolesDashboardApp').factory('TagService', TagService);

	TagService.$inject = [ '$resource', 'DateUtils', 'SERVER_API_URL' ];

	function TagService($resource, DateUtils, SERVER_API_URL) {
		var resourceUrl = SERVER_API_URL + 'api/tags/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				url : SERVER_API_URL + 'api/tags',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'getGallery':{ 
				method:'GET',
				url: SERVER_API_URL + 'api/tags/:id/images',
				isArray: true
            },         
			'deleteTag': { method: 'DELETE',
				  
				 headers : {
				'Content-Type' : 'application/json'
					}
			  
			 },
			'update' : {
				method : 'PUT',
				headers : {
					'Content-Type' : 'application/json'
				}
			},
			'saveImage': {
				method: 'PUT',
				url: SERVER_API_URL + 'api/tags/:id/image',
				headers: {
					'Content-Type': 'application/json'
				}
			},
			'saveGallery': {
				method: 'PUT',
				url: SERVER_API_URL + 'api/tags/:id/gallery',
				headers: {
					'Content-Type': 'application/json'
				}
			},			
			'save' : {
				method : 'POST',
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		});
	}
})();