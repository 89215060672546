(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('SpeciesDetailController', SpeciesDetailController);


    SpeciesDetailController.$inject = ['$scope', '$translate', '$stateParams', 'entity', '$state', 'previousState'];

    function SpeciesDetailController ($scope, $translate, $stateParams, entity, $state, previousState ) {
        var vm = this;
        
        vm.species = entity;
        vm.previousState = previousState.name;
        vm.tabSelected = tabSelected;
        vm.selectInfo = selectInfo;
        vm.selectPlants = selectPlants;
        vm.selectGallery = selectGallery;

		vm.tabInfo = 0;
		vm.tabPlants = 1;
		vm.tabGallery = 2;

		 
		if($stateParams.tabs){
			vm.currentTab = $stateParams.tabs;
		}else{
			vm.currentTab = vm.tabInfo;	
		}
		
	    vm.state = $state.current;
	    
	    function tabSelected(){
	    	console.log('tabSelected');
	    	console.log(vm.currentTab);
	    }
	    
	    function selectPlants(){
	    	$state.go('species-detail.plants');
	    }
	    
	    function selectGallery(){
	    	$state.go('species-detail.gallery');
	    }	    
	    
	    
	    function selectInfo() {
	    	$state.go('species-detail.info');
	    }
    }
})();
