(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('CategoryAddSubcategoryController', CategoryAddSubcategoryController);

    CategoryAddSubcategoryController.$inject = ['Category', 'Principal', 'entity', 'AlertService', '$scope', '$state', '$stateParams', '$uibModalInstance'];

    function CategoryAddSubcategoryController(Category, Principal, entity, AlertService, $scope, $state, $stateParams, $uibModalInstance) {

        var vm = this;
        
        vm.category = entity;
       
        vm.subcategoryDetail = {
        		category: vm.category
        };
        
		vm.select = {
				subcategory : {
					list:[],
					searchText:'',
					querySearch : querySearchCategory
				}
		};        
        
        
        vm.categories = Category.query({page:0, size:500, sort: 'name,asc'}); 
        vm.clear = clear;
        vm.addSubcategory = addSubcategory;

        vm.isSaving = false;
        vm.state = $state.current;
   
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
   
		function querySearchCategory(query) {
			return query ? vm.categories.filter(createFilterForCategory(query))
					: vm.categories;
		}
		
		function createFilterForCategory(query) {
			var lowercaseQuery = angular.lowercase(query);

			return function filterFn(category) {
				// —- solo se está buscando por la propiedad nombre del producto
				return (angular.lowercase(subcategory.name).indexOf(lowercaseQuery) === 0);
			};
		}			
		
        function addSubcategory () {
        		vm.isSaving = true;
        		vm.subcategoryDetail.productId = vm.select.category.selectionSubcategory.id;
        		Category.addSubcategory(vm.subcategoryDetail, onSaveSuccess, onSaveError);
        }
    
        function onSaveSuccess(result) {
        		$scope.$emit('arbolesDashboardApp:categoryAddSubcategory', result);
		
        		vm.isSaving = false;
        		$uibModalInstance.close(vm.operation);
        }

        function onSaveError () {
        		vm.isSaving = false;
        }
    }
})();
