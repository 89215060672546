(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('MemberMySuffixDetailController', MemberMySuffixDetailController);

    MemberMySuffixDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Member', 'UserApp', 'UserGroup'];

    function MemberMySuffixDetailController($scope, $rootScope, $stateParams, previousState, entity, Member, UserApp, UserGroup) {
        var vm = this;

        vm.member = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('arbolesDashboardApp:memberUpdate', function(event, result) {
            vm.member = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
