(function() {
    'use strict';
   
    angular
        .module('arbolesDashboardApp')
        .controller('TermDeleteController',TermDeleteController);

    TermDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'TermService'];

    function TermDeleteController($scope, $uibModalInstance, entity, TermService) {
        var vm = this;
        
        vm.items = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        let itemIds = [];

        init();

        function init() {
            itemIds = vm.items.map(item => item.id);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

     
       
        function confirmDelete() {
            if(itemIds.length > 1) {
            	TermService.deleteTerm({ items: itemIds }, onSuccessDelete, onError);
            }else {
            	TermService.delete({ id: itemIds[0] }, onSuccessDelete, onError);
            }
        }
       
        
        
        function onSuccessDelete() {
            $scope.$emit('arbolesDashboardApp:termDelete');
            clear();
        }

        function onError(err) {
            console.error(err);
        }
    }
})();
