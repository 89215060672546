(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('QuizEditController', QuizEditController);


    QuizEditController.$inject = ['$timeout', '$scope', '$rootScope', '$state','entity', 'QuizService', '$mdToast'];

    function QuizEditController ($timeout, $scope, $rootScope, $state, entity, QuizService, $mdToast) {
        var vm = this;

        vm.state = $state.current;
        vm.quiz = entity;
        vm.clear = clear;
        vm.save = save;
		vm.closeValidationErrors = closeValidationErrors;
		vm.showValidationErrors = showValidationErrors;

        $scope.image=vm.quiz.image?vm.quiz.image.url:'/content/images/factory.png';
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });


        function clear () {
        	if (vm.quiz.id){
        		$state.transitionTo('quiz-detail',{token: vm.quiz.token});
        	}else{
        		$state.transitionTo('quiz');
        	}            
        }
        

        function save () {
            vm.isSaving = true;
            if (vm.quiz.id !== null) {
                QuizService.update(vm.quiz, onSaveSuccess, onSaveError);
            } else {
            	QuizService.save(vm.quiz, onSaveSuccess, onSaveError);
            }
        }
        

        function onSaveSuccess (item) {
            vm.isSaving = false;
            vm.quiz.id = item.id;
	        if ($scope.quizImage){        		
	        	QuizService.saveImage({id : item.id}, {'file': $scope.quizImage,'fileName':$scope.quizImageFilename}, onSaveImgSuccess, onSaveImgError);
	        }else{        
	        	$state.transitionTo('quiz-detail',{id: item.id});
	        }            
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        function onSaveImgSuccess(){
        	$state.transitionTo('quiz-detail',{token: vm.quiz.token});
        }
        
        
        function onSaveImgError(){
        	AlertService.error(error.data.message);
        }

        $scope.$watch('files', function () {
            if ($scope.files && $scope.files[0] != null){
              $scope.image = $scope.files[0];

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.image);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.quizImage = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.quizImageFilename = $scope.image.name;
              };
            }
        });
        
        $scope.$watch('file', function () {
            if ($scope.file != null) {
                    $scope.files = [$scope.file]; 
                }
         });
        
        function showValidationErrors() {
            angular.forEach($scope.editForm.$error.required, function(field) {
				field.$setDirty();
			});

            var parentEl = angular.element(document).find('.layout-content-body');

            $mdToast.show({
                  hideDelay   : 5000,
                  toastClass: 'form-validation',
                  position    : 'top left',
                  templateUrl : 'app/entities/quiz/quiz-edit-validation.html',
                  controller: 'FormValidationController',
                  locals: {
                	  editForm : $scope.editForm
                  },
                  parent: parentEl,
                }).finally(function () {

                });
        } 
        
    	function closeValidationErrors () {
             $mdToast
               .hide()
               .then(function() {
                 isDlgOpen = false;
               });
     	}           

    }
    
     
})();
