(function() {
	'use strict';
	angular.module('arbolesDashboardApp').factory('AnswerService',
			AnswerService);

	AnswerService.$inject = [ '$resource', 'DateUtils', 'SERVER_API_URL' ];

	function AnswerService($resource, DateUtils, SERVER_API_URL) {
		var resourceUrl = SERVER_API_URL + 'api/answers/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				url : SERVER_API_URL + 'api/answers',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'deleteAnswer' : {
				method : 'DELETE',
				isArray : true
			},
			'update' : {
				method : 'PUT',
				headers : {
					'Content-Type' : 'application/json'
				}
			},
			'updateStatus' : {
				method : 'PUT',
				url : SERVER_API_URL + 'api/answers/:id/status',
				headers : {
					'Content-Type' : 'application/json'
				}
			},
			
			
			'save' : {
				method : 'POST',
				headers : {
					'Content-Type' : 'application/json'
				}
			},
			'saveImage' : {
				method : 'PUT',
				url : SERVER_API_URL + 'api/answers/:id/image',
				headers : {
					'Content-Type' : 'application/json'
				}
			}
		});
	}
})();
