(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('HeaderController', HeaderController);

    HeaderController.$inject = ['$rootScope','$scope', '$state', '$timeout', '$window', 'AuthServerProvider', 'Auth', 'Principal', 'ProfileService', 'LoginService'];

    function HeaderController ($rootScope, $scope, $state, $timeout, $window, AuthServerProvider, Auth, Principal, ProfileService, LoginService) {
        var vm = this;
        
        var def = ["layout", "layout-header-fixed"];

        vm.account = null;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });
        
        angular.element(document).ready(function () {
        	$timeout(function(){ initPageComplete(); }, 5000);
        });
        
        function initPageComplete(){
        	$window.loading_screen.finish();
        }
        
        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;         
            });
        }
        
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        //vm.login = login;
        vm.logout = logout;
        vm.$state = $state;

        /*
        function login() {
            collapseNavbar();
            LoginService.open();
        }*/

        function logout() {
            //collapseNavbar();
            Auth.logout();
            $state.go('login');
        }
    }
})();
