(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .directive('dateAfter', ['amMoment','moment', function (amMoment,moment) {

        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc (scope, element, attrs, ngModel) {
            if (!ngModel) {
                return;
            }

                var isInclusive = attrs.dateOrEquals ? scope.$eval(attrs.dateOrEquals) : false,
                    validate = function (val1, val2) {
                        if ((val1 === undefined || val2 === undefined)  || (!val1 || !val2)) {
							//ngModel.$setValidity('dateAfter',true);
							return;
						}
                        var isArray = val2 instanceof Array;
                        var isValid = true;
                        
                        var date1 = moment(val1,'DD/MM/YYYY HH:mm').toDate();
                        if (isArray && val2.length > 0) {
                            for (var i = 0; i < val2.length; i++) {
                                if (val2[i] !== undefined) {
                                    var date2 = new Date(val2[i]);
                                    isValid = isValid && (isInclusive ? date1 >= date2 : date1 > date2);
                                }
                                if (!isValid)
                                    break;
                            }
                        }
                        else {
                            if (val2 !== undefined) {
                                var date2 = new Date(val2);
                                isValid = isInclusive ? date1 >= date2 : date1 > date2;
                            }
                        }
                        ngModel.$setValidity('dateAfter', isValid);
                    };
                // Watch the value to compare - trigger validate()
                scope.$watch(attrs.dateAfter, function () {
                    validate(ngModel.$viewValue, scope.$eval(attrs.dateAfter));
                });
     
                ngModel.$parsers.unshift(function (value) {
                    validate(value, scope.$eval(attrs.dateAfter));
                    return value;
                })
                
         
        }
    }]);

})();
