(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('QuizDetailQuestionsController', QuizDetailQuestionsController);

    QuizDetailQuestionsController.$inject = ['$timeout', '$state', '$scope', '$stateParams', '$rootScope', 'QuizService', 'pagingParams', 'paginationConstants', '$mdToast', '$uibModal', 'previousState'];

    function QuizDetailQuestionsController ($timeout, $state, $scope, $stateParams, $rootScope, QuizService, pagingParams, paginationConstants, $mdToast, $uibModal, previousState) {

        var vo = this;
        vo.goToDetail = goToDetail;
        vo.goToEdit = goToEdit;
        vo.openAddQuestion = openAddQuestion;

        vo.questions = [];
        
        vo.itemsSelected = [];
        vo.loading = false;
        vo.filter = {};
        
        //console.log('quizdetailQuestionsController --- pagingParams ',pagingParams.page)
        vo.page = pagingParams.page - 1;
        vo.reset = reset ;
        vo.loadPage = loadPage;

        vo.itemsPerPage = paginationConstants.itemsPerPage;
        vo.predicate = pagingParams.predicate;
        vo.reverse = pagingParams.ascending;
        
        vo.selectAll = selectAll;
        vo.unSelectAll = unSelectAll;
        vo.toggleItem = toggleItem;
        vo.isChecked = isChecked;
        vo.syncCheck = syncCheck;
        vo.checkActive = false;
        vo.checkOnlyOneActive = false;
        vo.hasNextPage = true;

        vo.showEmpty = false;
        

        vo.openShowQuestion = openShowQuestion;
        vo.openEditQuestion = openEditQuestion;
        vo.openDeleteQuestion = openDeleteQuestion;
        vo.editChecked = editChecked;

        angular.element(document).ready(function () { 
        	loadPage(0);
        });

		var questionUpdate = $scope.$on('arbolesDashboardApp:questionUpdate', function(event, result) {
			console.log('arbolesDashboardApp:questionUpdate');
			vo.question = result.question;
			reset();
			//addQuestion();
        });
        $scope.$on('$destroy', questionUpdate);
        
        /*----------------------------- LOADS QUESTIONs --------------------------------*/
        function loadQuestions() {
            	QuizService.getQuestions({
            		token: $stateParams.token,
                    page: vo.page,
                    size: vo.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
        }
        
        function onSuccess(data, headers) {
            vo.totalItems = headers('X-Total-Count');
            vo.hasNextPage = headers('X-Has-Next-Page') === "true";
            vo.queryCount = vo.totalItems;
            
            for (var i = 0; i < data.length; i++) {
                vo.questions.push(data[i]);
            }
            vo.showEmpty = true;
        	vo.loading = false;   
        }

        function onError(error) {
            AlertService.error(error.data.message);
            vo.loading = false;
            vo.showEmpty = true;
        }

		
        
        function toggleItem(item) {
            var index = vo.itemsSelected.indexOf(item);
            syncCheck(!isChecked(item),item);
        }

        function isChecked(item){
            for (var i = 0; i < vo.itemsSelected.length; i++){
                if (vo.itemsSelected[i].token == item.token){
                    return true;
                }
            }
            return false;
        }
        
        function syncCheck(bool, item) {
            if(bool){
                // add item
                vo.itemsSelected.push(item);
              } else {
                // remove item
                for (var i = 0; i < vo.itemsSelected.length; i++){
                  if(vo.itemsSelected[i].token == item.token){
                	  vo.itemsSelected.splice(i,1);
                  }
                }      
              }
            vo.checkActive = vo.itemsSelected.length>0;
            vo.checkOnlyOneActive = vo.itemsSelected.length===1;
        }

        function selectAll() {
            vo.itemsSelected = [];
            for (var i = 0; i < vo.questions.length; ++i) {
                vo.itemsSelected.push(vo.questions[i]);
            }

            vo.checkActive = vo.itemsSelected.length>0;
            vo.checkOnlyOneActive = vo.itemsSelected.length===1;
        }

        function unSelectAll() {
            vo.itemsSelected = [];
            vo.checkActive = false;
            vo.checkOnlyOneActive = false;
        }
        
	     
                
        function sort () {
            var result = [vo.predicate + ',' + (vo.reverse ? 'asc' : 'desc')];
            if (vo.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        
        function reset () {
            vo.questions = [];
            vo.showEmpty = false;
            vo.hasNextPage = true;
            vo.page = 0;
            loadPage(vo.page);
            unSelectAll();
        }

        function loadPage(page) {
            if (vo.loading) return;
            
            if (!vo.hasNextPage) return;
            
            vo.loading = true;
            vo.page = page;
            loadQuestions(); 
        } 
        
        //-- modal con información de la pregunta
        function goToDetail(entity){
    		//$state.go('quiz-detail', { token:entity.token });
        }

        function goToEdit(question){
        	console.log('edit question');
        }
        
        function showDeleteModal(items) {
			if (window.event) {
				window.event.stopPropagation();
            }
			
			var modalInstance = $uibModal
                .open({
                    templateUrl : 'app/entities/question/question-delete-dialog.html',
                    controller : 'QuestionDeleteController',
                    controllerAs : 'vm',
                    scope : $scope, // passed current scope to the modal
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        dataState : function() {
                            return {
                                createLabel : 'arbolesDashboardApp.invitation.accept'
                            };
                        },
                        translatePartialLoader : [
                                '$translate',
                                '$translatePartialLoader',
                                function($translate,$translatePartialLoader) {
                                    $translatePartialLoader.addPart('question');
                                    return $translate.refresh();
                                } ],
                        entity: () => items
                    }
                });

		}   
        
        //-- new question in quiz
		function openAddQuestion() {
			var openAddPromoModalInstance = $uibModal
					.open({
						templateUrl : 'app/entities/question/question-edit.html',
						controller : 'QuestionEditController',
						controllerAs : 'vmm',
						scope : $scope, // passed current scope to the modal
						//backdrop : 'static',
						size : 'xl',
						resolve : {
							translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
								$translatePartialLoader.addPart('question');
								return $translate.refresh();
							}],    
			                entity: function () {
			                    return {
			                    	id: null,
			                        question: null,
			                        description: null,
			                        quizToken: $stateParams.token,
			                        points:5,
			                        image: null
			                    };
			                },    
			                quiz: function () {
			                    return $scope.$parent.vm.quiz;
			                }   			                
						}
					});
		}    
		
		function openShowQuestion(question) {
			var openAddPromoModalInstance = $uibModal
					.open({
						templateUrl : 'app/entities/question/question-detail.html',
						controller : 'QuestionDetailController',
						controllerAs : 'vmm',
						scope : $scope, // passed current scope to the modal
						//backdrop : 'static',
						size : 'xl',
						resolve : {
							translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
								$translatePartialLoader.addPart('quiz');
								$translatePartialLoader.addPart('question');
								$translatePartialLoader.addPart('answer');
								return $translate.refresh();
							}],    
							entity: function() {
								return QuizService.getQuestion({token: $stateParams.token, tokenQuestion: question.token}).$promise;
			                },  
			                quiz: function () {
			                    return $scope.$parent.vm.quiz;
			                }   			                
						}
					});
		}  
		
		function editChecked(){
			openEditQuestion(vo.itemsSelected[0].token);
		}
		
		function openEditQuestion(tokenQuestion) {
			console.log('openEditQuestion',tokenQuestion);
			var openAddPromoModalInstance = $uibModal
					.open({
						templateUrl : 'app/entities/question/question-edit.html',
						controller : 'QuestionEditController',
						controllerAs : 'vmm',
						scope : $scope, // passed current scope to the modal
						//backdrop : 'static',
						size : 'xl',
						resolve : {
							translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
								$translatePartialLoader.addPart('question');
								return $translate.refresh();
							}],    
							entity: ['$stateParams', 'QuizService', function($stateParams, QuizService) {
			                    return QuizService.getQuestion({token: $stateParams.token, tokenQuestion: tokenQuestion}).$promise;
			                }],  
			                quiz: function () {
			                    return $scope.$parent.vm.quiz;
			                }   			                
						}
					});
		}  	
		
		function openDeleteQuestion(tokenQuestion) {
			var openAddPromoModalInstance = $uibModal
					.open({
						templateUrl : 'app/entities/question/question-delete-dialog.html',
						controller : 'QuestionDeleteController',
						controllerAs : 'vmm',
						scope : $scope, // passed current scope to the modal
						//backdrop : 'static',
						size : 'xl',
						resolve : {
							translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
								$translatePartialLoader.addPart('question');
								return $translate.refresh();
							}],    
							entity: ['$stateParams', 'QuizService', function($stateParams, QuizService) {
			                    return QuizService.getQuestion({token: $stateParams.token, tokenQuestion: tokenQuestion}).$promise;
			                }],  
			                quiz: function () {
			                    return $scope.$parent.vm.quiz;
			                }   			                
						}
					});
		}  			
    }
})();
