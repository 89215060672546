(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('ProductMenuItemEditController', ProductMenuItemEditController);

    ProductMenuItemEditController.$inject = ['$timeout', '$scope', '$stateParams', '$document', 'entity', 'business', 'Business', 'Product', 'Photo', 'Category', '$mdToast', '$uibModalInstance'];

    function ProductMenuItemEditController ($timeout, $scope, $stateParams, $document, entity, business, Business, Product, Photo, Category, $mdToast, $uibModalInstance) {
        var vm = this;

        vm.product = entity;
        vm.business = business;
        vm.clear = clear;
        vm.save = save;
		vm.closeValidationErrors = closeValidationErrors;
		vm.showValidationErrors = showValidationErrors;
        
        $scope.image=vm.product.image?vm.product.image.url:'/content/images/restaurant.png';

        function clear () {
           $uibModalInstance.close();
        }

        function save () {
            vm.isSaving = true;
            if (vm.product.id !== null) {
            	console.log('update Item');
                Business.updateMenuItem({id: vm.business.id}, vm.product, onSaveSuccess, onSaveError);
            } else {
            	console.log('save Item');
                Business.saveMenuItem({id: vm.business.id}, vm.product, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.product.id = result.id;
            
	        if ($scope.productImage){        		
	        	Product.saveImage({id : result.id}, {'file': $scope.productImage,'fileName':$scope.productImageFilename, 'type':'banner'}, onSaveImgSuccess, onSaveImgError);
	        }else{        
	            $scope.$emit('arbolesDashboardApp:productNew', result);
	            $uibModalInstance.close(result);
	        }
        }
                

        function onSaveError () {
            vm.isSaving = false;
        }

        function onSaveImgSuccess(){
	           $scope.$emit('arbolesDashboardApp:productNew', vm.product);
	           $uibModalInstance.close(vm.product);        		
        }
        
        
        function onSaveImgError(){
        		AlertService.error(error.data.message);
        }   
        
        $scope.$watch('files', function () {
            if ($scope.files && $scope.files[0] != null){
              $scope.image = $scope.files[0];
              //console.log('imagen capturada, preview');

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.image);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.productImage = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.productImageFilename = $scope.image.name;
              };
            }
        });

    	function showValidationErrors(){
    		angular.forEach($scope.editForm.$error.required, function(field) {
    		    field.$setDirty();
    		});
    		
    		var parentEl = angular.element(document).find('.layout-content-body');
    		
    		$mdToast.show({
    	          hideDelay   : 10000,
    	          toastClass: 'form-validation',
    	          position    : 'top left',
    	          templateUrl : 'app/entities/product-item/product-menu-item-edit-validation.html',
    	          controller: 'FormValidationController',
    	          locals: {
    	        	  	editForm : $scope.editForm
    	          },
    	          parent: parentEl
    	        }).finally(function () {
    	        	 	// isDlgOpen = false;
    			});
    	}
    	
    	function closeValidationErrors () {
            $mdToast
              .hide()
              .then(function() {
                isDlgOpen = false;
              });
    	}
	

    }
})();
