(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('business-activate', {
            url: '/public/business/activate/{token}',
            data: {
                authorities: [],
                pageTitle: 'activate.title'
            },
            views: {
            	'global@': {
	                    templateUrl: 'app/public/business-activate/activate.html',
	                    controller: 'BusinessActivateController',
	                    controllerAs: 'vm'
            	}
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('global');
                	$translatePartialLoader.addPart('activate');
                	$translatePartialLoader.addPart('password');
                    $translatePartialLoader.addPart('register');
                    return $translate.refresh();
                }],
            	entity: ['$stateParams', 'Prospection', function($stateParams, Prospection) {
                    return Prospection.getProspection({token : $stateParams.token}).$promise.then(function(entity){
                		return entity;
                    });
            	
            	}],                
            },
            /*
            onEnter:  ['entity', '$state', function(entity, $state){
            	console.log('onEnter activate prospection' );
            	console.log(entity);
                if (!entity) {
                	$state.go('not-found'); // redirect to 404 in case no invitation has been found with the token
                }
            }]
            */
        })
		.state('business-activate-success', {
			url: '/public/business/activate/{token}/success',
	        data: {
	        	authorities: [],
	            pageTitle: 'register.prospection'
	        },
	        views: {
	        	'global@': {
	        		templateUrl: 'app/public/business-activate/activate-success.html',
	                controller: 'BusinessActivateSuccessController',
	                    controllerAs: 'vm'
	                }
	            },
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');
	                	$translatePartialLoader.addPart('activate');
	                    return $translate.refresh();
	                }]               
	            }
	        })        
        ;
    }
})();
