(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('ParkEditController', ParkEditController);


    ParkEditController.$inject = ['$timeout', '$scope', '$rootScope', '$state','entity', 'ParkService', '$mdToast'];

    function ParkEditController ($timeout, $scope, $rootScope, $state, entity, ParkService, $mdToast) {
        var vm = this;

        vm.state = $state.current;
        vm.park = entity;
        vm.clear = clear;
        vm.save = save;
		vm.closeValidationErrors = closeValidationErrors;
		vm.showValidationErrors = showValidationErrors;

        $scope.image=vm.park.image?vm.park.image.url:'/content/images/factory.png';
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });


        function clear () {
//            $state.transitionTo('park');	
        	if (vm.park.token){
        		$state.transitionTo('park-detail',{token: vm.park.token});
        	}else{
        		$state.transitionTo('parks');
        	}            
        }
        
        function clear () {
        	if (vm.park.token){
        		$state.transitionTo('park-detail',{token: vm.park.token});
        	}else{
        		$state.transitionTo('parks');
        	}
        }

        function save () {
            vm.isSaving = true;
            if (vm.park.token !== null) {
                ParkService.update(vm.park, onSaveSuccess, onSaveError);
            } else {
                ParkService.save(vm.park, onSaveSuccess, onSaveError);
            }
        }
        

        function onSaveSuccess (item) {
            vm.isSaving = false;
            vm.park.token = item.token;
	        if ($scope.parkImage){        		
	        	ParkService.saveImage({token : item.token}, {'file': $scope.parkImage,'fileName':$scope.parkImageFilename}, onSaveImgSuccess, onSaveImgError);
	        }else{        
	        	$state.transitionTo('park-detail',{token: item.token});
	        }            
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        function onSaveImgSuccess(){
        	$state.transitionTo('park-detail',{token: vm.park.token})
        }
        
        
        function onSaveImgError(){
        	AlertService.error(error.data.message);
        }

        $scope.$watch('files', function () {
            if ($scope.files && $scope.files[0] != null){
              $scope.image = $scope.files[0];

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.image);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.parkImage = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.parkImageFilename = $scope.image.name;
              };
            }
        });
        
        $scope.$watch('file', function () {
            if ($scope.file != null) {
                    $scope.files = [$scope.file]; 
                }
         });
        
        function showValidationErrors() {
            angular.forEach($scope.editForm.$error.required, function(field) {
				field.$setDirty();
			});

            var parentEl = angular.element(document).find('.layout-content-body');

            $mdToast.show({
                  hideDelay   : 5000,
                  toastClass: 'form-validation',
                  position    : 'top left',
                  templateUrl : 'app/entities/park/park-validation.html',
                  controller: 'FormValidationController',
                  locals: {
                	  editForm : $scope.editForm
                  },
                  parent: parentEl,
                }).finally(function () {

                });
        } 
        
    	function closeValidationErrors () {
             $mdToast
               .hide()
               .then(function() {
                 isDlgOpen = false;
               });
     	}           

    }
    
     
})();
