(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('UserGroupMySuffixDialogController', UserGroupMySuffixDialogController);

    UserGroupMySuffixDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'UserGroup', 'Photo', 'Member', 'Request', 'UserApp', 'Product'];

    function UserGroupMySuffixDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, UserGroup, Photo, Member, Request, UserApp, Product) {
        var vm = this;

        vm.userGroup = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.banners = Photo.query({filter: 'usergroup-is-null'});
        $q.all([vm.userGroup.$promise, vm.banners.$promise]).then(function() {
            if (!vm.userGroup.bannerId) {
                return $q.reject();
            }
            return Photo.get({id : vm.userGroup.bannerId}).$promise;
        }).then(function(banner) {
            vm.banners.push(banner);
        });
        vm.members = Member.query();
        vm.requests = Request.query();
        vm.userapps = UserApp.query();
        vm.products = Product.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.userGroup.id !== null) {
                UserGroup.update(vm.userGroup, onSaveSuccess, onSaveError);
            } else {
                UserGroup.save(vm.userGroup, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('arbolesDashboardApp:userGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.modifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
