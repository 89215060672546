(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('LoginController', LoginController);

    
    
    LoginController.$inject = ['$rootScope', '$state', '$window', '$timeout', 'Auth'];

    function LoginController ($rootScope, $state, $window, $timeout, Auth) {
        var vm = this;
        
        $window.loading_screen.finish();

        vm.$state = $state;
        vm.year = new Date().getFullYear();
        vm.showPassword = false;
        vm.togglePassword = togglePassword;

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $timeout(function (){angular.element('#username').focus();});

        function togglePassword(){
        	vm.showPassword = !vm.showPassword;
        }
        
        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
        }

        function login (event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                /*
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }
                */

                $state.go('home');
                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                vm.authenticationError = true;
                console.log('error');
            });
        }

        function requestResetPassword () {
            $state.go('requestReset');
        }
    }
    
    
})();
