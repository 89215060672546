(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('SpeciesDetailPlantsController', SpeciesDetailPlantsController);

    SpeciesDetailPlantsController.$inject = ['$timeout', '$state', '$scope', '$stateParams', '$rootScope', 'SpeciesService', 'pagingParams', 'paginationConstants', '$mdToast', 'previousState'];

    function SpeciesDetailPlantsController ($timeout, $state, $scope, $stateParams, $rootScope, SpeciesService, pagingParams, paginationConstants, $mdToast, previousState) {

        var vo = this;
        
		if($stateParams.tabs){
			$scope.$parent.vm.currentTab = $stateParams.tabs;
		}
		
        vo.plants = [];
        
        vo.itemsSelected = [];
        vo.loading = false;
        vo.filter = {};
        
        vo.page = pagingParams.page - 1;
        vo.reset = reset ;
        vo.loadPage = loadPage;

        vo.itemsPerPage = paginationConstants.itemsPerPage;
        vo.predicate = pagingParams.predicate;
        vo.reverse = pagingParams.ascending;
        
        vo.showFilter = showFilter;
        vo.closeFilter = closeFilter;
        vo.noCloseFilter = noCloseFilter;
        vo.doFilter = doFilter;
		vo.cleanFilter = cleanFilter;

        vo.selectAll = selectAll;
        vo.unSelectAll = unSelectAll;
        vo.toggleItem = toggleItem;
        vo.isChecked = isChecked;
        vo.syncCheck = syncCheck;
        vo.checkActive = false;
        vo.checkOnlyOneActive = false;
        vo.hasNextPage = true;

        vo.showEmpty = false;
        vo.removeTag = removeTag;
        
        vo.goToDetail = goToDetail;

        angular.element(document).ready(function () { 
        	loadPage(0);
        });


        /*----------------------------- LOADS APPs --------------------------------*/
        function loadPlants() {
        	
            if (vo.filter.data) {
            	SpeciesService.filter({
                    page: vo.page - 1,
                    size: vo.itemsPerPage,
                    sort: sort()},
                    vo.filter, onSuccess, onError);
            }else {
            	console.log($stateParams.token);
            	SpeciesService.getPlants({
            		token: $stateParams.token,
                    page: vo.page - 1,
                    size: vo.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            
        }
        
        function onSuccess(data, headers) {
            vo.totalItems = headers('X-Total-Count');
            vo.hasNextPage = headers('X-Has-Next-Page') === "true";
            vo.queryCount = vo.totalItems;
            
            for (var i = 0; i < data.length; i++) {
                vo.plants.push(data[i]);
            }
            vo.showEmpty = true;
        	vo.loading = false;   
        }

        function onError(error) {
            AlertService.error(error.data.message);
            vo.loading = false;
            vo.showEmpty = true;
        }

		
        
        function toggleItem(item) {
            var index = vo.itemsSelected.indexOf(item);
            syncCheck(!isChecked(item),item);
        }

        function isChecked(item){
            for (var i = 0; i < vo.itemsSelected.length; i++){
                if (vo.itemsSelected[i].id == items.id){
                    return true;
                }
            }
            return false;
        }
        
        function syncCheck(bool, item) {
            if(bool){
                // add item
                vo.itemsSelected.push(item);
              } else {
                // remove item
                for (var i = 0; i < vo.itemsSelected.length; i++){
                  if(vo.itemsSelected[i].id == item.id){
                	  vo.itemsSelected.splice(i,1);
                  }
                }      
              }
            vo.checkActive = vo.itemsSelected.length>0;
            vo.checkOnlyOneActive = vo.itemsSelected.length===1;
        }

        function selectAll() {
            vo.itemsSelected = [];
            for (var i = 0; i < vo.plants.length; ++i) {
                vo.itemsSelected.push(vo.plants[i]);
            }

            vo.checkActive = vo.itemsSelected.length>0;
            vo.checkOnlyOneActive = vo.itemsSelected.length===1;
        }

        function unSelectAll() {
            vo.itemsSelected = [];
            vo.checkActive = false;
            vo.checkOnlyOneActive = false;
        }
        
        function filterById(userBusiness) {
            return vo.filter[userBusiness.id] || noFilter(vo.filter);
        }
        
        function showFilter(){
        		vo.showFilters = true;        	
        }
        
        function closeFilter(){
        		vo.showFilters = false;        	
        }
        
        function noCloseFilter($event) {
			if (window.event) {
				window.event.stopPropagation();
			}
		}
        
        function doFilter() {
			vo.filter.data = true;
			reset();
			closeFilter();
		}
		
		function cleanFilter() {
			vo.filter = {};
			vo.filter.data = false;

			reset();
			closeFilter();
		}		
		
	     
                
        function sort () {
            var result = [vo.predicate + ',' + (vo.reverse ? 'asc' : 'desc')];
            if (vo.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        
        function reset () {
            vo.plants = [];
            vo.showEmpty = false;
            vo.page = 1;
            loadPage(vo.page);
            unSelectAll();
        }

        function loadPage(page) {
            if (vo.loading) return;
            
            vo.loading = true;
            vo.page = page;
            loadPlants(); 
        } 
        
        //-- modal con información de la planta y ubicación
        function goToDetail(entity){
    		$state.go('invoice-detail', { id:entity.id });
        }

        function removeTag(tag) {
            vo.filter = {}
            reset();
        }    
    }
})();