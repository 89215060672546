(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('TagDetailInfoController', TagDetailInfoController);

    TagDetailInfoController.$inject = ['$scope', '$rootScope', '$stateParams', '$state', 'previousState', '$uibModal'];

    function TagDetailInfoController($scope, $rootScope, $stateParams, $state, previousState, $uibModal) {
        var vm = this;

        vm.tag = $scope.$parent.vm.tag;
        vm.previousState = previousState.name;
        vm.showDeleteModal = showDeleteModal;
        vm.goToEdit = goToEdit;
        

        function goToEdit() {
            $state.transitionTo('tag.edit', {id:vm.tag.id});
        }

        function showDeleteModal() {
			if (window.event) {
				window.event.stopPropagation();
            }
			
			var modalInstance = $uibModal
                .open({
                    templateUrl : 'app/entities/tag/tag-delete-dialog.html',
                    controller : 'TagDeleteController',
                    controllerAs : 'vm',
                    scope : $scope, // passed current scope to the modal
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        dataState : function() {
                            return {
                                createLabel : 'arbolesDashboardApp.invitation.accept'
                            };
                        },
                        translatePartialLoader : [
                                '$translate',
                                '$translatePartialLoader',
                                function($translate,$translatePartialLoader) {
                                    $translatePartialLoader.addPart('tag');
                                    return $translate.refresh();
                                } ],
                        entity: () => [vm.tag]
                    }
                });

        }
        
        $scope.$on('arbolesDashboardApp:appDelete', function(event, result) {
        	$state.transitionTo('app');
        });
        
        function goToDelete(tag){
        	if (window.event) {
				window.event.stopPropagation();
			}
        
			var modalInstance = $uibModal
			.open({
				templateUrl : 'app/entities/tag/tag-delete-dialog.html',
				controller : 'TagDeleteController',
				controllerAs : 'vm',
				scope : $scope, // passed current scope to the modal
				backdrop : 'static',
				size : 'lg',
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                    $translatePartialLoader.addPart('tag');
	                    return $translate.refresh();
	                }],
	                entity: function() {
						return [tag];
					}
	            }
			});
		
        }
        
        var afterDeleted = $scope.$on('arbolesDashboardApp:deleteTag', function(event, result) {
        	$state.transitionTo('tag');
        });  
    }
})();

