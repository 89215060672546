(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('ParkDetailController', ParkDetailController);


    ParkDetailController.$inject = ['$scope', '$translate', '$stateParams', 'entity', '$state', 'previousState'];

    function ParkDetailController ($scope, $translate, $stateParams, entity, $state, previousState ) {
        var vm = this;
        
        vm.park = entity;
        vm.previousState = previousState.name;
        vm.tabSelected = tabSelected;
        vm.selectInfo = selectInfo;
        vm.selectPlaces = selectPlaces;

		vm.tabInfo = 0;
		vm.tabPlaces = 1;

		 
		if($stateParams.tabs){
			vm.currentTab = $stateParams.tabs;
		}else{
			vm.currentTab = vm.tabInfo;	
		}
		
	    vm.state = $state.current;
	    
	    function tabSelected(){
	    	console.log('tabSelected');
	    	console.log(vm.currentTab);
	    }
	    
	    function selectPlaces(){
	    	$state.go('park-detail.places');
	    }
	    
	    function selectInfo() {
	    	$state.go('park-detail.info');
	    }
    }
})();
