(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('BusinessSignoutSuccessController', BusinessSignoutSuccessController);


    BusinessSignoutSuccessController.$inject = ['$translate', '$window', '$timeout', '$state', 'ProspectionModel'];

    function BusinessSignoutSuccessController ($translate, $window, $timeout, $state, ProspectionModel) {
        var vm = this;
        $window.loading_screen.finish();
        
        vm.email = ProspectionModel.prospection.email;
    }
})();
