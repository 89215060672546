(function () {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('Register', Register);

    Register.$inject = ['$resource', 'SERVER_API_URL'];

    function Register ($resource, SERVER_API_URL) {
        return $resource(SERVER_API_URL + 'api/v1/register', {}, {});
    }
})();
