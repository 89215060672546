(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('StatisticsController', StatisticsController);

    StatisticsController.$inject = ['DataUtils', '$q', '$translate','$scope', '$state', 'pagingParams', 'paginationConstants'];

    function StatisticsController(DataUtils, $q, $translate, $scope, $state, pagingParams, paginationConstants) {

        var vm = this;

        vm.activities = [];
        
        vm.page = pagingParams.page - 1;
        vm.loadPage = loadPage;
        vm.toggleActivity = false;
                
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.showActivities = showActivities;
        vm.showChart = showChart;
        
        vm.hasNextPage = true;

        vm.showEmpty = false;

        vm.goToDetail = goToDetail;
        
        function showActivities(){
        	vm.toggleActivity = true;
        }
        
        function showChart(){
        	vm.toggleActivity = false;
        }
        
        
        vm.chart= {
        	      series: ["Promos"],
        	      data: [
        	        [954, 970, 1018, 1073, 1134, 1280, 1323]
        	        
        	      ],
        	      labels: ["Lunes", "Martes", "Miércoles", "Jueves", "Vier", "Sab", "Dom"],
        	      colors: [{
        	        backgroundColor: "#6a747b75",
        	        borderColor: "#2e3c54",
        	        pointBackgroundColor: "#2e3c54"
        	      },      
        	      {
          	        backgroundColor: "#6a747b75",
          	        borderColor: "#2e3c54",
          	        pointBackgroundColor: "#2e3c54"
          	      },             	      
        	      {
        	        backgroundColor: "#7f9c6a75",
        	        borderColor: "#2e5437",
        	        pointBackgroundColor: "#2e5437"
        	      }],

        	      options: {
        	        animation: true,
        	        responsive: true,
        	        legend: {
        	          display: false
        	        },
        	        scales: {
        	          xAxes: [{
        	            gridLines: {
        	              display: false
        	            }
        	          }]
        	        },
        	        tooltips: {
        	          mode: "label"
        	        }
        	      }
        	    };
        
	      /*
	      datasetOverride: [
	    	  {
	    	        label: "Line chart",
	    	        borderWidth: 3,
	    	        hoverBackgroundColor: "rgba(255,99,132,0.4)",
	    	        hoverBorderColor: "rgba(255,99,132,1)",
	    	        type: 'line'
	    	      },        	    	  
	    	  {
	    	        label: "Line chart",
	    	        borderWidth: 3,
	    	        hoverBackgroundColor: "rgba(255,99,132,0.4)",
	    	        hoverBorderColor: "rgba(255,99,132,1)",
	    	        type: 'line'
	    	  }
	      ],
	      */
        

        /*----------------------------- LOAD PAGE --------------------------------*/
        function loadActivities() {

        	var data=[
        		{id:1, date:'25/08/2018 12:54', nick:'Alfonso Rodríguez',email:'alfonsorodriguez@hotmail.com', business_id:1, business:'Bar Roma', type:'Ven en grupo', imageUrl:'/content/images/avatar/013-personas-3.png'},
        		{id:2, date:'24/08/2018 21:21', nick:'Verónica Fernández',email:'vfernandez@gmail.com', business_id:1, business:'Tasca Fernando', type:'Pasaporte cañas 10+1', imageUrl:'/content/images/avatar/007-personas-8.png'},
        		{id:3, date:'24/08/2018 20:12', nick:'Carlos Benito',email:'cbenito_30253@yahoo.com', business_id:1, business:'Bar Rincón', type:'Pasaporte cañas 10+1', imageUrl:'/content/images/avatar/004-personas-11.png'},
        		{id:4, date:'24/08/2018 20:01', nick:'Astrid Mata',email:'amata@gmail.com', business_id:1, business:'Bar Rincón', type:'Pasaporte cañas 10+1'},
        		{id:5, date:'24/08/2018 19:58', nick:'Paco Grande',email:'pagrandeyo@mail.com', business_id:1, business:'Bar Rincón', type:'Pasaporte cañas 10+1', imageUrl:'/content/images/avatar/010-personas-6.png'},
        		{id:6, date:'24/08/2018 17:35', nick:'Daniel Expósito',email:'dexposit_o34@mail.com', business_id:1, business:'La Luisa', type:'Pasaporte cañas 10+1', imageUrl:'/content/images/avatar/011-personas-5.png'},
        		{id:7, date:'24/08/2018 15:23', nick:'Carlos Yuste',email:'carlos_yust@hotmail.com', business_id:1, business:'Tasca Fernando', type:'Pasaporte cañas 10+1'}];
        		onSuccess(data);
        }
        
       function onSuccess(data) {
            vm.hasNextPage = false;
            vm.queryCount = data.length;
            
            for (var i = 0; i < data.length; i++) {
                if(!data[i].deleted) {
                    vm.activities.push(data[i]);
                }
            }
            vm.showEmpty = true;
        	vm.loading = false;            
        }

        function onError(error) {
            AlertService.error(error.data.message);
            vm.loading = false;
            vm.showEmpty = true;
        }
 

        function loadPage(page) {
            if (vm.loading) return;
            
            vm.loading = true;
            vm.page = page;
            loadActivities(); 
        } 

        function goToDetail(entity){
    		$state.go('statistics-detail-business', { id:entity.business_id});
        }

        
    }
})();
