(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('InvitationLinkSuccessController', InvitationLinkSuccessController);


    InvitationLinkSuccessController.$inject = ['$translate', '$window', '$timeout', '$state', 'UserAccountModel'];

    function InvitationLinkSuccessController ($translate, $window, $timeout, $state, UserAccountModel) {
        var vm = this;
        $window.loading_screen.finish();
        vm.invitation = UserAccountModel.userAccount;
    }
})();
