(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$state', '$scope','$rootScope','Auth', 'Principal', 'ProfileService'];

    function SidebarController ($state, $scope, $rootScope, Auth, Principal, ProfileService) {
        var vm = this;

        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });
        

        vm.$state = $state;          
    }
})();
