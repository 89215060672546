(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('SpeciesEditController', SpeciesEditController);

    SpeciesEditController.$inject = ['$timeout', '$scope', '$rootScope', '$state','entity', 'SpeciesService', '$mdToast'];

    function SpeciesEditController ($timeout, $scope, $rootScope, $state, entity, SpeciesService, $mdToast) {
        var vm = this;

        vm.state = $state.current;
        vm.species = entity;
        vm.clear = clear;
        vm.save = save;
		vm.closeValidationErrors = closeValidationErrors;
		vm.showValidationErrors = showValidationErrors;
		        
        $scope.image=vm.species.image?vm.species.image.url:'/content/images/factory.png';
       
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
        	if (vm.species.token){
        		$state.transitionTo('species-detail',{token: vm.species.token});
        	}else{
        		$state.transitionTo('species');
        	}
        }

        function save () {
            vm.isSaving = true;
            if (vm.species.token !== null) {
				SpeciesService.update(vm.news, onSaveSuccess, onSaveError);
            } else {
                SpeciesService.save(vm.news, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (item) {
            vm.isSaving = false;
            vm.species.token = item.token;
	        if ($scope.speciesImage){        		
	        	SpeciesService.saveImage({token : species.token}, {'file': $scope.newsImage,'fileName':$scope.speciesImageFilename}, onSaveImgSuccess, onSaveImgError);
	        }else{        
	        	$state.transitionTo('species-detail',{token: item.token});
	        }            
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function onSaveImgSuccess(){
        	$state.transitionTo('species-detail',{token: vm.species.token})
        }
        
        
        function onSaveImgError(){
        	AlertService.error(error.data.message);
        }

        $scope.$watch('files', function () {
            if ($scope.files && $scope.files[0] != null){
              $scope.image = $scope.files[0];

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.image);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.speciesImage = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.speciesImageFilename = $scope.image.name;
              };
            }
        });
        
        $scope.$watch('file', function () {
          if ($scope.file != null) {
			$scope.files = [$scope.file]; 
              }
        });
        
        function showValidationErrors() {
            angular.forEach($scope.editForm.$error.required, function(field) {
				field.$setDirty();
			});

            var parentEl = angular.element(document).find('.layout-content-body');

            $mdToast.show({
                  hideDelay   : 5000,
                  toastClass: 'form-validation',
                  position    : 'top left',
                  templateUrl : 'app/entities/species/species-validation.html',
                  controller: 'FormValidationController',
                  locals: {
                	  editForm : $scope.editForm
                  },
                  parent: parentEl,
                }).finally(function () {
                });
        } 
        
    	function closeValidationErrors () {
            $mdToast
              .hide()
              .then(function() {
                isDlgOpen = false;
              });
    	}           

    }
    
     
})();
