(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('LayoutBarService', LayoutBarService);

    LayoutBarService.$inject = ['$rootScope'];

    function LayoutBarService($rootScope) {

        var _app = {};
        
        _app.cssClases = "layout layout-header-fixed layout-sidebar-fixed";
	    _app.navbar = {};
	    _app.navbar.isCollapsed = true;
	    _app.navbar.toggle = function toggle() {
	      this.isCollapsed = !this.isCollapsed;
	    };
	
	    _app.search = {};
	    _app.search.isCollapsed = true;
	    _app.search.toggle = function toggle() {
	      this.isCollapsed = !this.isCollapsed;
	    };
	
	    _app.sidebar = {};
	    _app.sidebar.isCollapsed = false;
	    _app.sidebar.isFixed = true;
	    _app.sidebar.toggle = function toggle() {
	    	console.log('sidebar toggle ');
	    	this.isCollapsed = !this.isCollapsed;
	    };
	    
	    
	
	    _app.sidebar.xs = {};
	    _app.sidebar.xs.isCollapsed = true;
	    _app.sidebar.xs.isFixed = true;
	    _app.sidebar.xs.toggle = function toggle() {
	      console.log('sidebar toggle ');
	      this.isCollapsed = !this.isCollapsed;
	    };
	    
        var service = {
            getNavbar: getNavbar,
            getSidebar: getSidebar,
            getSearch: getSearch,
            setApp: setApp
        };

        return service;

        function setStatus(appStatus){
        	_app = appStatus;
        }
        
        function getNavbar() {
    	    return _app.navbar;
        }
        
        function getSidebar() {
    	    return _app.sidebar;
        }
        
        function getSearch() {
    	    return _app.search;
        }        
        
        
        function setApp(app){
        	_app = app;
        }
        
        function getApp(){
        	return _app;
        }
        
    }
})();