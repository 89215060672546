(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('QuestionDetailAnswersController', QuestionDetailAnswersController);

    QuestionDetailAnswersController.$inject = ['$timeout', '$state', '$scope', '$stateParams', '$rootScope'];

    function QuestionDetailAnswersController ($timeout, $state, $scope, $stateParams, $rootScope) {

        var vo = this;
    
        vo.answers = [];
        

        
      
    }
})();