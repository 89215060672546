(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('InvitationSignoutSuccessController', InvitationSignoutSuccessController);


    InvitationSignoutSuccessController.$inject = ['$translate', '$window', '$timeout', '$state', 'UserAccountModel'];

    function InvitationSignoutSuccessController ($translate, $window, $timeout, $state, UserAccountModel) {
        var vm = this;
        $window.loading_screen.finish();
        vm.invitation = UserAccountModel.userAccount;
    }
})();
