(function() {
    'use strict';
    angular
        .module('arbolesDashboardApp')
        .factory('ParkService', ParkService);

    ParkService.$inject = ['$resource', 'DateUtils', 'SERVER_API_URL'];

    function ParkService ($resource, DateUtils, SERVER_API_URL) {
        var resourceUrl =  SERVER_API_URL + 'api/park/:token';

        return $resource(resourceUrl, {}, {
            'query': { 
            		method: 'GET', 
            		url:  SERVER_API_URL + 'api/parks',
            		isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'deletePark': { method: 'DELETE',
            	 headers : {
     				'Content-Type' : 'application/json'
     					},
            	
            	
            	isArray: true },
            'update': { 
            	method:'PUT',
		        headers: {
		        	'Content-Type': 'application/json'
		        } 
            },
            'savePropertyImage': {
            		method: 'PUT',
            		url: SERVER_API_URL + 'api/park/:token/image',
            		isArray: false,
                	headers: {
                		'Content-Type': 'application/json'
                	}            	
            },             
            'getPlaces':{ 
				method:'GET',
				url: SERVER_API_URL + 'api/park/:token/places',
				isArray: true
            },               
            'updateStatus': { 
            		method:'PUT', 
            		url:SERVER_API_URL + 'api/park/:token/status',
                	headers: {
                		'Content-Type': 'application/json'
                	} 
            },
            'saveImage': {
            	method: 'PUT',
            	url: SERVER_API_URL + 'api/park/:token/image',
            	headers: {
            		'Content-Type': 'application/json'
            	}
            }
        });
    }
})();
