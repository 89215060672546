(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('CategorySearch', CategorySearch);

    CategorySearch.$inject = ['$resource', 'SERVER_API_URL'];

    function CategorySearch($resource, SERVER_API_URL) {
        var resourceUrl =  SERVER_API_URL + 'api/_search/categories/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
