(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('QuestionEditController', QuestionEditController);


    QuestionEditController.$inject = ['$timeout', '$scope', '$rootScope', '$state','entity', 'quiz', 'QuestionService', '$mdToast', '$uibModalInstance'];

    function QuestionEditController ($timeout, $scope, $rootScope, $state, entity, quiz, QuestionService, $mdToast, $uibModalInstance) {
        var vmm = this;

        vmm.state = $state.current;
        vmm.question = entity;
        vmm.clear = clear;
        vmm.save = save;
		vmm.closeValidationErrors = closeValidationErrors;
		vmm.showValidationErrors = showValidationErrors;

        $scope.image=vmm.question.image?vmm.question.image.url:'/content/images/factory.png';
        vmm.tinymceOptions = {
        	    toolbar: 'bold italic | alignleft aligncenter alignright | code',
        	    skin: 'lightgray',
        	    theme : 'modern'
        	  };
        //vmm.question.description = 'aaaaaaa';
        
        /*
        var events = ['trixInitialize', 'trixChange', 'trixSelectionChange', 'trixFocus', 'trixBlur', 'trixFileAccept', 'trixAttachmentAdd', 'trixAttachmentRemove'];

        for (var i = 0; i < events.length; i++) {
        	console.log('init events' + events[i]);
            $scope[events[i]] = function(e, editor) {
                console.info('Event type:', e.type);
            }
        };
        */        

        /*
        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });
        */



        function clear () {
        	$uibModalInstance.dismiss('cancel');	//-- closing modal window         
        }
        

        function save () {
            vmm.isSaving = true;
            if (vmm.question.id !== null) {
                QuestionService.update(vmm.question, onSaveSuccess, onSaveError);
            } else {
            	QuestionService.save(vmm.question, onSaveSuccess, onSaveError);
            }
            
        }
        

        function onSaveSuccess (item) {
            vmm.isSaving = false;
	        if ($scope.questionImage){        		
	        	QuestionService.saveImage({token : item.token}, {'file': $scope.questionImage,'fileName':$scope.questionImageFilename}, onSaveImgSuccess, onSaveImgError);
	        }else{        
	        	//$state.transitionTo('question-detail',{id: item.id});
	            $scope.$emit('arbolesDashboardApp:questionUpdate', item);
	            clear();	        	
	        }            
        }

        function onSaveError () {
            vmm.isSaving = false;
        }
        
        function onSaveImgSuccess(){
        	//$state.transitionTo('question-detail',{id: vmm.question.id});
            $scope.$emit('arbolesDashboardApp:questionUpdate', vmm.question);
            clear();
        }
        
        
        function onSaveImgError() {
        	AlertService.error(error.data.message);
        }

        $scope.$watch('files', function () {
        	console.log('adding file to question');
            if ($scope.files && $scope.files[0] != null){
              $scope.image = $scope.files[0];

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.image);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.questionImage = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.questionImageFilename = $scope.image.name;
              };
            }
        });
        
        $scope.$watch('file', function () {
            if ($scope.file != null) {
                    $scope.files = [$scope.file]; 
                }
         });
        $scope.tinymceOptions = {
        	    onChange: function(e) {
        	      // put logic here for keypress and cut/paste changes
        	    	console.log('aaaaa');
        	    },
        	    inline: false,
        	    plugins : 'advlist autolink link image lists charmap print preview',
        	    skin: 'lightgray',
        	    theme : 'modern'
        };
    	
        function showValidationErrors() {
            angular.forEach($scope.editQuestionForm.$error.required, function(field) {
				field.$setDirty();
			});

            var parentEl = angular.element(document).find('.layout-content-body');

            $mdToast.show({
                  hideDelay   : 5000,
                  toastClass: 'form-validation',
                  position    : 'top left',
                  templateUrl : 'app/entities/question/question-edit-validation.html',
                  controller: 'FormValidationController',
                  locals: {
                	  editForm : $scope.editQuestionForm
                  },
                  parent: parentEl,
                }).finally(function () {

                });
        } 
        
    	function closeValidationErrors () {
             $mdToast
               .hide()
               .then(function() {
                 isDlgOpen = false;
               });
     	}           

    }
    
     
})();


