(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('InvitationSignoutController', InvitationSignoutController);


    InvitationSignoutController.$inject = ['$translate', '$window', '$timeout', 'Auth', 'LoginService', 'entity', '$state', '$stateParams', '$scope', '$rootScope', '$browser', '$location', 'UserAccountModel', 'registerInterceptor', '$injector', '$uibModal', 'Register'];

    function InvitationSignoutController ($translate, $window, $timeout, Auth, LoginService, entity, $state, $stateParams, $scope, $rootScope, $browser, $location, UserAccountModel, registerInterceptor, $injector, $uibModal, Register) {
        var vm = this;

        $window.loading_screen.finish();
        
        UserAccountModel.userAccount = entity;
        
        vm.init=init;

        vm.userAccount = UserAccountModel.userAccount;
        vm.state = $state.current;
        vm.goStep1 = goStep1;
        vm.goStep2 = goStep2;
        vm.goStep3 = goStep3;       
         
        
        vm.doNotMatch = null;
        vm.error = false;
        vm.errorUserExists = false;
        vm.login = login;
        vm.register = register;
        vm.acceptLegal = acceptLegal;

        vm.success = false;
        vm.isSaving = false;
        vm.valid = false;

        $timeout(function (){ angular.element('.form-group:eq(1)>input').focus();});

        var unsubscribe = $rootScope.$on('arbolesDashboardApp:registerLegalAccept', function(event, result) {
            //--- registro del usuario y 
        	register();
        });    
        
        init();
		
        $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {	
            if (toState.data && toState.data.redirect) {
              var redirectTo = $injector.invoke(toState.data.redirect, this, {
                toStateParams: toParams,
                toState: toState
              });

              if (redirectTo) {
                event.preventDefault();
                $state.transitionTo(redirectTo,{token:$stateParams.token});
              }
            }
        });
        
        
        function init(){
        		checkDataBetweenSteps();
        }
        
        function checkDataBetweenSteps(){
      		if (vm.state.name!='invitation-signout.step1'){

      			if (!vm.userAccount.firstName || !vm.userAccount.lastName || !vm.userAccount.email){
      				UserAccountModel.userAccount = vm.userAccount;
      				goStep1();
      			}
      		}
        }
        
        function acceptLegal(form){
	    		if (form.$invalid){ 
	    			form.userType.$dirty = true;
	    			form.userTypeList.$dirty = true;
	    			form.phone.$dirty = true;
	    			return; 
	    		}   
	    		showLegalModal();
        }
        
        function register () { 
        		vm.isSaving = true;
                vm.userAccount.langKey = $translate.use();
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                vm.userAccount.login = vm.userAccount.email;

                
                Register.registerInvitation(vm.userAccount).$promise.then(function () {
                    vm.success = 'OK';
                    vm.isSaving = false;
                    $state.transitionTo('invitation-signout-success');
                }).catch(function (response) {
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        vm.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'email address already in use') {
                        vm.errorEmailExists = 'ERROR';
                    } else {
                        vm.error = 'ERROR';
                    }
                });
        }
        
        function goStep2(form){
        		if (form.$invalid){ 
        			form.firstName.$dirty = true;
        			form.lastName.$dirty= true;
        			form.email.$dirty = true;
        			return; 
        		}
        		
        		vm.valid = true;
        		UserAccountModel.userAccount = vm.userAccount;
        		$state.transitionTo('invitation-signout.step2',{token:$stateParams.token}).then(function($stateParams) {
        			vm.state = $state.current;
        		});
        }
        
        function goStep1(){
        		UserAccountModel.userAccount = vm.userAccount;
        		$state.transitionTo('invitation-signout.step1',{token:$stateParams.token}).then(function() {
        			vm.state = $state.current;
        		});
        }           

        function goStep3(form){
    			if (form.$invalid){
        			form.password.$dirty = true;
        			form.company.$dirty= true;    				
    				return; 
    			}
    			vm.valid = true;
    			UserAccountModel.userAccount = vm.userAccount;
	    		$state.transitionTo('invitation-signout.step3',{token:$stateParams.token}).then(function() {
	    			vm.state = $state.current;
	    			vm.valid = false;
	    		});
	    }           

        
        function login() {
            $state.go('login');
        }
        
        
        
        //--- STEP2 --- 
        vm.inputPasswordType = 'password';
        vm.hideShowPassword = hideShowPassword;      
        
        function hideShowPassword (){
        		vm.inputPasswordType = vm.inputPasswordType == 'password'?'text':'password';
        }
        
		$scope.$watch("vm.userAccount.isFreelance", function(newValue, oldValue){
			setFreelanceName();
		});


		function setFreelanceName(){
		    if(vm.userAccount.isFreelance){
		    		vm.userAccount.company = (vm.userAccount.firstName?vm.userAccount.firstName:'') + (vm.userAccount.lastName?' ' + vm.userAccount.lastName:'');
			}
		}    
		
		
		//-- STEP3 ---
		vm.roles = ['SHIPPER','CARRIER','OPERATOR'];
		
		//-- Modal aceptación de condiciones
		
        function showLegalModal(){
			
			if (window.event) {
				window.event.stopPropagation();
			}
			
			var modalInstance = $uibModal
					.open({
						templateUrl : 'app/public/invitation/invitation-legal-dialog.html',
						controller : 'InvitationLegalController',
						controllerAs : 'vm',
						scope : $scope, // passed current scope to the modal
						backdrop : 'static',
						size : 'lg',
						resolve : {
							dataState : function() {
								return {
									createLabel : 'visiocargoApp.invitation.accept'
								};
							},
							translatePartialLoader : [
									'$translate',
									'$translatePartialLoader',
									function($translate,$translatePartialLoader) {
										$translatePartialLoader.addPart('register');
										return $translate.refresh();
									} ]
						}									
					});
		}			
    }
})();
