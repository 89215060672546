(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        	.state('business-signout', {
	            url: '/public/registro/negocio',
	            data: {
	                authorities: [],
	                pageTitle: 'register.prospection'
	            },
	            views: {
	                'global@': {
	                    templateUrl: 'app/public/business/business-signout.html',
	                    controller: 'BusinessSignoutController',
	                    controllerAs: 'vm'
	                },
	                'step@business-signout':{
	                		templateUrl: 'app/public/business/business-signout-edit-step.html' 	 
	                }
	            },
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');
	                	$translatePartialLoader.addPart('register');
	                    return $translate.refresh();
	                }],
	            	entity: function () {
                        return {
                        	email:null,
                            firstName: null,
                            lastName: null,
                            phone:null,
                            type:null,
                            companyName:null,
                            companyCif:null,
                            addressStreet:null,
                            addressCity:null,
                            addressZip:null,
                            price:null,
                            terms: null,
                            iva:false,
                            appKey: 'sm5qqVnv11rM5vwLNIXW7f'
                        };
                    }	            	
	            }
	        })
        	.state('business-signout-simple', {
	            url: '/public/registro/negocio/basico',
	            data: {
	                authorities: [],
	                pageTitle: 'register.prospection'
	            },
	            views: {
	                'global@': {
	                    templateUrl: 'app/public/business/business-signout.html',
	                    controller: 'BusinessSignoutSimpleController',
	                    controllerAs: 'vm'
	                },
	                'step@business-signout-simple':{
	                		templateUrl: 'app/public/business/business-signout-edit-simple-step.html' 	 
	                }
	            },
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');
	                	$translatePartialLoader.addPart('register');
	                    return $translate.refresh();
	                }],
	            	entity: function () {
                        return {
                        	email:null,
                            firstName: null,
                            lastName: null,
                            phone:null,
                            type:null,
                            companyName:null,
                            companyCif:null,
                            addressStreet:null,
                            addressCity:null,
                            addressZip:null,
                            price:null,
                            terms: null,
                            iva:false,
                            appKey: 'sm5qqVnv11rM5vwLNIXW7f'
                        };
                    }	            	
	            }
	        })	        
		    .state("business-signout.step", {
			      url: '/acceso',
			      views: {
				      'step@business-signout':{
	              			templateUrl: 'app/public/business/business-signout-edit-step.html' 	 
				      }	    	  
			      },
		          data: {
		               redirect: undefined
		           }	      
			 })
		    .state("business-signout-simple.step", {
			      url: '/acceso',
			      views: {
				      'step@business-signout-simple':{
	              			templateUrl: 'app/public/business/business-signout-edit-simple-step.html' 	 
				      }	    	  
			      },
		          data: {
		               redirect: undefined
		           }	      
			 })			 
		    .state('business-signout-success', {
	            url: '/public/registro/negocio/completo',
	            data: {
	                authorities: [],
	                pageTitle: 'register.prospection'
	            },
	            views: {
	                'global@': {
	                    templateUrl: 'app/public/business/business-signout-success.html',
	                    controller: 'BusinessSignoutSuccessController',
	                    controllerAs: 'vm'
	                }
	            },
	            /*
                data:{
                    redirect: ['ProspectionModel',function(ProspectionModel){
                  	  		  // just check that firstName is in, if not return the state where this is filled
                             if(!ProspectionModel.prospection.firstName || !ProspectionModel.prospection.lastName || !ProspectionModel.prospection.email) {
                                  return 'business-signout.step';
                             }
                     }]
                },*/
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');
	                    return $translate.refresh();
	                }]               
	            }
	        })
		    .state('business-signout-simple-success', {
	            url: '/public/registro/negocio/basico/completo',
	            data: {
	                authorities: [],
	                pageTitle: 'register.prospection'
	            },
	            views: {
	                'global@': {
	                    templateUrl: 'app/public/business/business-signout-success.html',
	                    controller: 'BusinessSignoutSuccessController',
	                    controllerAs: 'vm'
	                }
	            },
	            /*
                data:{
                    redirect: ['ProspectionModel',function(ProspectionModel){
                  	  		  // just check that firstName is in, if not return the state where this is filled
                             if(!ProspectionModel.prospection.firstName || !ProspectionModel.prospection.lastName || !ProspectionModel.prospection.email) {
                                  return 'business-signout.step';
                             }
                     }]
                },*/
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');
	                    return $translate.refresh();
	                }]               
	            }
	        });  	        
        		
    }
})();
