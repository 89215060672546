(function() {
    'use strict';
    angular
        .module('arbolesDashboardApp')
        .factory('Product', Product);

    Product.$inject = ['$resource', 'DateUtils', 'SERVER_API_URL'];

    function Product ($resource, DateUtils, SERVER_API_URL) {
        var resourceUrl =  SERVER_API_URL + 'api/products/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.expireDate = DateUtils.convertDateTimeFromServer(data.expireDate);
                    }
                    return data;
                }
            },
            'update': { 
            	method:'PUT',
            	headers: {
            		'Content-Type': 'application/json'
            	}
            },
            'updateStatus': { 
            	method:'PUT', 
            	url: SERVER_API_URL + 'api/products/:id/status',
            	headers: {
            		'Content-Type': 'application/json'
            	}
            },         
            'saveImage': {
            	method: 'PUT',
            	url: SERVER_API_URL + 'api/products/:id/image',
            	headers: {
            		'Content-Type': 'application/json'
            	}
            }            
        });
    }
})();
