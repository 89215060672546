(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('CategoryEditController', CategoryEditController);

    CategoryEditController.$inject = ['$timeout', '$scope', '$state', '$stateParams', '$q', 'entity', 'Category', 'Product', 'Photo', 'AuthServerProvider', '$mdToast'];

    function CategoryEditController ($timeout, $scope, $state, $stateParams, $q, entity, Category, Product, Photo, AuthServerProvider, $mdToast) {
        var vm = this;

        vm.category = entity;
        vm.clear = clear;
        vm.save = save;
		vm.closeValidationErrors = closeValidationErrors;
		vm.showValidationErrors = showValidationErrors;
		vm.app = AuthServerProvider.getApp();
		        
        $scope.image=vm.category.banner?vm.category.banner.url:'/content/images/category.icon.png';
        $scope.imageIcon=vm.category.icon?vm.category.icon.url:'/content/images/category.icon.png';
        

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
        	if (vm.category.id){
        		$state.transitionTo('category-detail',{id: vm.category.id});
        	}else{
        		$state.transitionTo('category');
        	}
        }

        function save () {
            vm.isSaving = true;
            if (vm.category.id !== null) {
                Category.update(vm.category, onSaveSuccess, onSaveError);
            } else {
                Category.save(vm.category, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('arbolesDashboardApp:categoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        
        function onSaveSuccess (category) {
            vm.isSaving = false;
            vm.category.id = category.id;
//            console.log('categoryImage')
//            console.log($scope.categoryImage);
//            
//            console.log('categoryIcon')
//            console.log($scope.categoryIcon);            
            
	        if ($scope.categoryImage){        		
	        	Category.saveImage({id : category.id}, {'file': $scope.categoryImage,'fileName':$scope.categoryImageFilename, 'type':'category'}, onSaveImgSuccess, onSaveImgError);
	        }else{        
    	        if ($scope.categoryIcon){        		
    	        	Category.saveImage({id : category.id}, {'file': $scope.categoryIcon,'fileName':$scope.categoryIconFilename, 'type':'icon'}, onSaveIconSuccess, onSaveIconError);
    	        }else{        
    	        	$state.transitionTo('category-detail',{id: category.id});
    	        }    	        	
	        }
        }
                

        function onSaveError () {
            vm.isSaving = false;
        }

        function onSaveImgSuccess(){
        		//$state.go('campaign-detail',{id: campaign.id}, { reload: true, inherit: false });
        		$state.transitionTo('category-detail',{id: vm.category.id})
    	        if ($scope.categoryIcon){        		
    	        	Category.saveImage({id : category.id}, {'file': $scope.categoryIcon,'fileName':$scope.categoryIconFilename, 'type':'icon'}, onSaveIconSuccess, onSaveIconError);
    	        }else{        
    	        	$state.transitionTo('category-detail',{id: category.id});
    	        }        		
        }
        
        
        function onSaveImgError(){
        		AlertService.error(error.data.message);
        }   
        
        function onSaveIconSuccess(){
    		//$state.go('campaign-detail',{id: campaign.id}, { reload: true, inherit: false });
    		$state.transitionTo('category-detail',{id: vm.category.id})
	    }
	    
	    
	    function onSaveIconError(){
	    		AlertService.error(error.data.message);
	    }  
    
        
        $scope.$watch('files', function () {
            if ($scope.files && $scope.files[0] != null){
              $scope.image = $scope.files[0];
              //console.log('imagen capturada, preview');

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.image);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.categoryImage = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.categoryImageFilename = $scope.image.name;
              };


            }
        });
        
        $scope.$watch('icon', function () {
            if ($scope.icon && $scope.icon[0] != null){
              $scope.imageIcon = $scope.icon[0];
              //console.log('imagen capturada, preview');

              var fileReader = new FileReader();
              fileReader.readAsDataURL($scope.imageIcon);
              fileReader.onload = function (e) {
                var dataUrl = e.target.result;
                $scope.categoryIcon = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
                $scope.categoryIconFilename = $scope.imageIcon.name;
              };


            }
        });        
        
//        $scope.$watch('file', function () {
//          if ($scope.file != null) {
//                  $scope.files = [$scope.file]; 
//              }
//        });            
                

    	function showValidationErrors(){
    		//var parentEl = angular.element(document.querySelector( '#'));
    		
    		angular.forEach($scope.editForm.$error.required, function(field) {
    		    field.$setDirty();
    		});
    		
    		var parentEl = angular.element(document).find('.layout-content-body');
    		console.log('parentEl');
    		console.log(parentEl);
    		console.log($mdToast);
    		
    		$mdToast.show({
    	          hideDelay   : 10000,
    	          toastClass: 'form-validation',
    	          position    : 'top left',
    	          templateUrl : 'app/entities/category/category-edit-validation.html',
    	          controller: 'FormValidationController',
    	          locals: {
    	        	  	editForm : $scope.editForm
    	          },
    	          parent: parentEl,
    	          //scope: $scope
    	        }).finally(function () {
    	        	 	// isDlgOpen = false;
    			});
    	}
    	
    	function closeValidationErrors () {
           // if (isDlgOpen) return;

            $mdToast
              .hide()
              .then(function() {
                isDlgOpen = false;
              });
    	}               
    }
})();
