(function() {
    'use strict';
    angular
        .module('arbolesDashboardApp')
        .factory('Invoice', Invoice);

   Invoice.$inject = ['$resource', 'DateUtils', 'SERVER_API_URL'];

    function Invoice ($resource, DateUtils, SERVER_API_URL) {
        var resourceUrl =  SERVER_API_URL + 'api/invoice/:id';
        
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                },
            	headers: {
            		'Content-Type': 'application/json'
            	}                
            }
        });
    }
})();
