(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('UserAppDialogController', UserAppDialogController);

    UserAppDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'UserApp', 'Photo', 'Rental', 'Product', 'Tag', 'RentalValoration'];

    function UserAppDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, UserApp, Photo, Rental, Product, Tag, RentalValoration) {
        var vm = this;

        vm.userApp = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.avatars = Photo.query({filter: 'userapp-is-null'});
        $q.all([vm.userApp.$promise, vm.avatars.$promise]).then(function() {
            if (!vm.userApp.avatar || !vm.userApp.avatar.id) {
                return $q.reject();
            }
            return Photo.get({id : vm.userApp.avatar.id}).$promise;
        }).then(function(avatar) {
            vm.avatars.push(avatar);
        });
        vm.rentals = Rental.query();
        vm.products = Product.query();
        vm.userapps = UserApp.query();
        vm.tags = Tag.query();
        vm.rentalvalorations = RentalValoration.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.userApp.id !== null) {
                UserApp.update(vm.userApp, onSaveSuccess, onSaveError);
            } else {
                UserApp.save(vm.userApp, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('arbolesDashboardApp:userAppUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;
        vm.datePickerOpenStatus.birthDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
