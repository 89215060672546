(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('FormValidationController', FormValidationController);

    FormValidationController.$inject = ['$rootScope', '$scope','editForm', '$mdToast'];

    function FormValidationController($rootScope, $scope, editForm, $mdToast) {
        var vm = this;
        
        
        $scope.editForm = editForm;   
        $scope.closeValidationErrors = closeValidationErrors;
        
	    	function closeValidationErrors () {
	
	            $mdToast
	              .hide()
	              .then(function() {
	                //isDlgOpen = false;
	              });
	    	}         
    }
})();
