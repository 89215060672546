(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        	.state('invitation-signout', {
        		url: '/public/invitation/signout/{token}',
        		data: {
        			authorities: [],
        			pageTitle: 'invitation-signout.title'
        		},
        		views: {
        			'global@': {
        				templateUrl: 'app/public/invitation/invitation-signout.html',
        				controller: 'InvitationSignoutController',
        				controllerAs: 'vm'
	                },
	                'step@invitation-signout':{
	                		templateUrl: 'app/public/invitation/invitation-signout-step1.html' 	 
	                }
	            },
	            data:{
                    redirect: ['UserAccountModel', '$stateParams', 'Invitation',function(UserAccountModel, $stateParams, Invitation){
                    	
                    			//-- comprobamos que el usuario no está activo para iniciar el proceso de registro 
                    			Invitation.isUserActive({token : $stateParams.token}).$promise.then(function(){
                    				//-- redireccionamos a aceptar invitación por usuario registrado
                    				return 'invitation-accept';
                    			}).catch(function(){
	                  	  		  // just check that firstName is in, if not return the state where this is filled
	                             if(!UserAccountModel.firstName || !UserAccountModel.lastName || !UserAccountModel.email) {
	                                  return 'invitation-signout.step1';
	                             }else if(!UserAccountModel.password || !UserAccountModel.company) {
	                                  return 'invitation-signout.step2';
	                             }else if(!UserAccountModel.userType ) {
	                                  return 'invitation-signout.step3';
	                             }
                    			});
	                             
                    }]
                },
	            resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');
	                    $translatePartialLoader.addPart('invitation');
	                    $translatePartialLoader.addPart('register');
	                    return $translate.refresh();
	                }],
	                entity: ['$stateParams', 'Invitation', function($stateParams, Invitation) {
	                    return Invitation.getByToken({token : $stateParams.token}).$promise.then(function(invitation){
	                    		var registerAccount = {
	                    			email: invitation.email,
                    				firstName: invitation.firstName,
	                                lastName: invitation.lastName,
	                                company: invitation.company,
	                                phone: invitation.phone,
	                                urlToken: $stateParams.token,
	                                isFreelance: invitation.isFreelance,
	                                status: invitation.statusTarget
	                    		};
	                    		
	                    		return registerAccount;
	                    });
	                	
	                }],                
	            },
	            onEnter:  ['entity', '$state', function(entity, $state){
	                if (entity.status == "ACCEPTED" || entity.status == "REJECTED") {
	                		$state.go('not-found'); // redirect to 404 in case no invitation has been found with the token
	                }
	            }]
        })
        .state("invitation-signout.step1", {
			      url: '/user',
			      views: {
				      'step@invitation-signout':{
	              			templateUrl: 'app/public/invitation/invitation-signout-step1.html' 	 
				      }	    	  
			      },
		          data: {
		               redirect: undefined
		           }	      
			 })
		    .state("invitation-signout.step2", {
			      url: '/company',
			      views: {
				      'step@invitation-signout':{
	              			templateUrl: 'app/public/invitation/invitation-signout-step2.html' 	 
				      }	    	  
			      },
                  data:{
                      redirect: ['UserAccountModel',function(UserAccountModel){
                    	  		  // just check that firstName is in, if not return the state where this is filled
                    	  		  
                    	  		  if(!UserAccountModel.userAccount.firstName || !UserAccountModel.userAccount.lastName || !UserAccountModel.userAccount.email) {
                                    return 'invitation-signout.step1';
                               }
                       }]
                  }
			 })			 
		    .state("invitation-signout.step3", {
			      url: '/phone',
			      views: {
				      'step@invitation-signout':{
	              			templateUrl: 'app/public/invitation/invitation-signout-step3.html' 	 
				      }	    	  
			      },
                  data:{
                      redirect: ['UserAccountModel',function(UserAccountModel){
                    	  		  // just check that firstName is in, if not return the state where this is filled
                               if(!UserAccountModel.userAccount.firstName || !UserAccountModel.userAccount.lastName || !UserAccountModel.userAccount.email) {
                                    return 'invitation-signout.step1';
                               }else if(!UserAccountModel.userAccount.password || !UserAccountModel.userAccount.company) {
                                    return 'invitation-signout.step2';
                               }
                       }]
                  }
			})
        .state('invitation-reject', {
            url: '/public/invitation/reject/{token}',
            data: {
                authorities: [],
                pageTitle: 'register.title'
            },
            views: {
                'global@': {
                    templateUrl: 'app/public/invitation/invitation-reject.html',
                    controller: 'InvitationPublicRejectController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('invitation');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Invitation', function($stateParams, Invitation) {
                    return Invitation.rejectByToken({token : $stateParams.token}).$promise.then(function (invitation) { return invitation; },
                    		  function () {return null;});
                    	
                }],                
            },
            onEnter:  ['entity', '$state', function(entity, $state){
                if (!entity) {
                		$state.go('not-found'); // redirect to 404 in case no invitation has been found with the token
                }
            }]
        })
        
	    .state('invitation-signout-success', {
            url: '/public/invitation-signout/success',
            data: {
                authorities: [],
                pageTitle: 'invitation-signout.title'
            },
            views: {
                'global@': {
                    templateUrl: 'app/public/invitation/invitation-signout-success.html',
                	controller: 'InvitationSignoutSuccessController',
                    controllerAs: 'vm'
                }
            },
            data:{
            	redirect: ['UserAccountModel',function(UserAccountModel){
              	  		  // just check that firstName is in, if not return the state where this is filled
                         if(!UserAccountModel.userAccount.firstName || !UserAccountModel.userAccount.lastName || !UserAccountModel.userAccount.email) {
                              return 'invitation-signout.step1';
                         }else if(!UserAccountModel.userAccount.password || !UserAccountModel.userAccount.company) {
                              return 'invitation-signout.step2';
                         }else if(!UserAccountModel.userAccount.userType ) {
                              return 'invitation-signout.step3';
                         }
                 }]
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('invitation');
                    return $translate.refresh();
                }]               
            }
        })
        .state('invitation-accept', {
            url: '/public/invitation/accept/{token}',
            data: {
                authorities: [],
                pageTitle: 'register.title'
            },
            views: {
                'global@': {
                    templateUrl: 'app/public/invitation/invitation-accept.html',
                    controller: 'InvitationPublicAcceptController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('invitation');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Invitation', function($stateParams, Invitation) {
                    return Invitation.getByToken({token : $stateParams.token}).$promise.then(invitation => invitation);
                    	
                }],                
            }
        });
    }
})();
