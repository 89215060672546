(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('PhotoSearch', PhotoSearch);

    PhotoSearch.$inject = ['$resource', 'SERVER_API_URL'];

    function PhotoSearch($resource, SERVER_API_URL) {
        var resourceUrl =  SERVER_API_URL + 'api/_search/photos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
