(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('TermDetailInfoController', TermDetailInfoController);

    TermDetailInfoController.$inject = ['$scope', '$rootScope', '$stateParams', '$state', 'previousState', '$uibModal', 'TermService'];

    function TermDetailInfoController($scope, $rootScope, $stateParams, $state, previousState, $uibModal, TermService) {
        var vm = this;

        vm.term = $scope.$parent.vm.term;
        vm.previousState = previousState.name;
        vm.showDeleteModal = showDeleteModal;
        vm.goToEdit = goToEdit;
        vm.updatingStatus = false;   
        vm.showUpdateStatus = showUpdateStatus;
        vm.updateStatus = updateStatus;        

        
        function goToEdit() {
            $state.transitionTo('term.edit', {id:vm.term.id});
        }  
        
        function showDeleteModal() {
			if (window.event) {
				window.event.stopPropagation();
            }
			
			var modalInstance = $uibModal
                .open({
                    templateUrl : 'app/entities/term/term-delete-dialog.html',
                    controller : 'TermDeleteController',
                    controllerAs : 'vm',
                    scope : $scope, // passed current scope to the modal
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        dataState : function() {
                            return {
                                createLabel : 'arbolesDashboardApp.invitation.accept'
                            };
                        },
                        translatePartialLoader : [
                                '$translate',
                                '$translatePartialLoader',
                                function($translate,$translatePartialLoader) {
                                    $translatePartialLoader.addPart('term');
                                    return $translate.refresh();
                                } ],
                        entity: () => [vm.term]
                    }
                });

        }
        
        $scope.$on('arbolesDashboardApp:termDelete', function(event, result) {
        	$state.transitionTo('term');
        });
        
        
        function showUpdateStatus(show){
    		if (show){
    			vm.statusToUpdate = vm.term.status;
    		}
    		vm.updatingStatus = show;
	    }
	    
	    function updateStatus(){
	    		if (vm.term.status != vm.statusToUpdate){
	    			vm.term.status = vm.statusToUpdate;
	    			TermService.updateStatus({id:vm.term.id},vm.term,onUpdateStatusSuccess,onUpdateStatusError);
	    		}
	    }
	
	    function onUpdateStatusSuccess(data, headers) {
	    		showUpdateStatus(false);
	    }
	    
	    function onUpdateStatusError (data, headers){
	    		console.log('actualización de estado mal!!!');
	    }	                 
    }
})();

