(function() {
    'use strict';
    angular
        .module('arbolesDashboardApp')
        .factory('Category', Category);

    Category.$inject = ['$resource', 'DateUtils', 'SERVER_API_URL'];

    function Category ($resource, DateUtils, SERVER_API_URL) {
        var resourceUrl =  SERVER_API_URL + 'api/categories/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                    }
                    return data;
                }
            },
            'getSubcategories': {
                method: 'GET',            
                url: SERVER_API_URL + 'api/category/:id/subcategories',
                isArray: true
            },        
            'getBasicSubcategories': {
                method: 'GET',            
                url: SERVER_API_URL + 'api/subcategories',
                isArray: true            	
            },
            'count': { 
				method:'GET',
				url: SERVER_API_URL + 'api/categories/count',
				isArray: false
            },                
            'update': { 
            	method:'PUT',
            	headers: {
            		'Content-Type': 'application/json'
            	}
            },
            'saveImage': {
            	method: 'PUT',
            	headers: {
            		'Content-Type': 'application/json'
            	},            	
            	url: SERVER_API_URL + 'api/category/:id/image'
            },               
            'addSubcategory':{
        		method: 'POST',
            	headers: {
            		'Content-Type': 'application/json'
            	},                    		
        		url: SERVER_API_URL + 'api/category/subcategory'
            },                
            'deleteCategories': { method: 'DELETE', isArray: true },
            'deleteSubcategory':{
        		method: 'DELETE',
        		url: SERVER_API_URL + 'api/category/:id/subcategory',
        		isArray: true
            }                
        });
    }
})();
