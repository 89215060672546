(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('species', {
            parent: 'entity',
            url: '/species',
            data: {
                authorities: ['ROLE_USER'],            
                pageTitle: 'arbolesDashboardApp.species.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/species/species.html',
                    controller: 'SpeciesController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                }
            },            
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],                 
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('species');
                    return $translate.refresh();
                }]
            }
        })
        .state('species-detail', {
            parent: 'species',
            url: '/{token}/detail',
            data: {
                pageTitle: 'arbolesDashboardApp.species.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/species/species-detail.html',
                    controller: 'SpeciesDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('species');
                    $translatePartialLoader.addPart('publishStatus');
                    $translatePartialLoader.addPart('propertyType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SpeciesService', function($stateParams, SpeciesService) {
                    return SpeciesService.get({token : $stateParams.token}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'apps',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })   
		.state('species-detail.info',{
			url : '/info',
			views : {
				'info@species-detail' : {
                    templateUrl: 'app/entities/species/species-detail-info.html',
                    controller: 'SpeciesDetailInfoController',
                    controllerAs: 'vo'
				}
			},
			params: {
				tabs: 0
            }
		})       
		.state('species-detail.plants',{
			url : '/plants',
			views : {
				'plants@species-detail' : {
                    templateUrl: 'app/entities/species/species-detail-plants.html',
                    controller: 'SpeciesDetailPlantsController',
                    controllerAs: 'vo'
				}
			},
			params: {
				tabs: 1,
                pagey: {
                    value: '1',
                    squash: true
                },
                sorty: {
                    value: 'id,desc',
                    squash: true
                }
            },   
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pagey),
                        sort: $stateParams.sorty,
                        predicate: PaginationUtil.parsePredicate($stateParams.sorty),
                        ascending: PaginationUtil.parseAscending($stateParams.sorty)
                    };
                }]                  
            }
		}) 	
		.state('species-detail.gallery',{
			url : '/gallery',
			views : {
				'gallery@species-detail' : {
                    templateUrl: 'app/entities/species/species-detail-gallery.html',
                    controller: 'SpeciesDetailGalleryController',
                    controllerAs: 'vo'
				}
			},
			params: {
				tabs: 2,
                pagey: {
                    value: '1',
                    squash: true
                },
                sorty: {
                    value: 'id,desc',
                    squash: true
                }
            },   
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.pagey),
                        sort: $stateParams.sorty,
                        predicate: PaginationUtil.parsePredicate($stateParams.sorty),
                        ascending: PaginationUtil.parseAscending($stateParams.sorty)
                    };
                }]                  
            }
		}) 			
        .state('species.new', {
            parent: 'species',
            url: '/new',
            views: {
                'content@': {
                    templateUrl: 'app/entities/species/species-edit.html',
                    controller: 'SpeciesEditController',
                    controllerAs: 'vm'
                }
            },   
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {;
                    $translatePartialLoader.addPart('validation');
                    $translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();
                }],         
                $uibModalInstance: function(){ return null},
                entity: function () {
                    return {
                        id: null
                    };
                }
            }            
        })
        .state('species.edit', {
            parent: 'species',
            url: '/{token}/edit',
            views: {
                'content@': {
                    templateUrl: 'app/entities/species/species-edit.html',
                    controller: 'SpeciesEditController',
                    controllerAs: 'vm'
                }
            },   
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {    
                	$translatePartialLoader.addPart('validation');
                	$translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();     
                }],
                entity: ['$stateParams', 'SpeciesService', function($stateParams, SpeciesService) {
                    return SpeciesService.get({token : $stateParams.token}).$promise;
                }]
            }            
        })
        .state('species.delete', {
            parent: 'species',
            url: '/{token}/delete',
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/species/species-delete-dialog.html',
                    controller: 'SpeciesDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SpeciesService', function(SpeciesService) {
                            return SpeciesService.get({token : $stateParams.token}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('species', null, { reload: 'species' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
