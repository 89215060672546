(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('tag', {
			parent : 'entity',
			url : '/tag',
			data : {
				authorities : [ 'ROLE_ADMIN' ]
			},
			params : {
				page : {
					value : '1',
					squash : true
				},
				sort : {
					value : 'level,asc',
					squash : true
				}
			},
			views : {
				'content@' : {
					templateUrl : 'app/entities/tag/tag.html',
					controller : 'TagController',
					controllerAs : 'vm'
				}
			},
			resolve : {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],               	
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tag');
                    return $translate.refresh();
                }]
			}
         })
		.state('tag.new',{
			parent : 'tag',
			url : '/new',
			views : {
				'content@' : {
					templateUrl : 'app/entities/tag/tag-edit.html',
					controller : 'TagEditController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : ['$translate','$translatePartialLoader', function($translate, $translatePartialLoader) {
											$translatePartialLoader.addPart('validation');
											$translatePartialLoader.addPart('publishStatus');
											return $translate.refresh();
				} ],
                $uibModalInstance: function(){ return null},
                entity: function () {
                    return {
                        id: null,
                        level:1
					};
				}
			}
		})
		
		.state('tag.edit', {
            parent: 'tag',
            url: '/{id}/edit',
            views: {
                'content@': {
                    templateUrl: 'app/entities/tag/tag-edit.html',
                    controller: 'TagEditController',
                    controllerAs: 'vm'
                }
            },   
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {    
                	$translatePartialLoader.addPart('validation');
                	$translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();     
                }],
                entity: ['$stateParams', 'TagService', function($stateParams, TagService) {
                    return TagService.get({id : $stateParams.id}).$promise;
                }]
            }            
        })
		
        .state('tag-detail', {
            parent: 'tag',
            url: '/{id}',
            data: {
                pageTitle: 'arbolesDashboardApp.tag.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tag/tag-detail.html',
                    controller: 'TagDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tag');
                    $translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TagService', function($stateParams, TagService) {
                    return TagService.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tag',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
		.state('tag-detail.info',{
			url : '/info',
			views : {
				'info@tag-detail' : {
                    templateUrl: 'app/entities/tag/tag-detail-info.html',
                    controller: 'TagDetailInfoController',
                    controllerAs: 'vm'
				}
			},
			params: {
				tabs: 0
            }
		})       	
		.state('tag-detail.gallery',{
			url : '/gallery',
			views : {
				'gallery@tag-detail' : {
                    templateUrl: 'app/entities/tag/tag-detail-gallery.html',
                    controller: 'TagDetailGalleryController',
                    controllerAs: 'vm'
				}
			},
			params: {
				tabs: 1,
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                }
            },   
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }]                  
            }
		})         
        .state('tag.delete', {
	            parent: 'tag',
	            url: '/{id}/delete',
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/entities/tag/tag-delete-dialog.html',
	                    controller: 'TagDeleteController',
	                    controllerAs: 'vm',
	                    size: 'md',
	                    resolve: {
	                        entity: ['TagService', function(TagService) {
	                            return TagService.get({id : $stateParams.id}).$promise;
	                        }]
	                    }
	                }).result.then(function() {
	                    $state.go('tag', null, { reload: 'tag' });
	                }, function() {
	                    $state.go('^');
	                });
	            }]
	        });
		 
	}

})();
