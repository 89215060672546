(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('SpeciesDeleteController',SpeciesDeleteController);

    SpeciesDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'SpeciesService'];

    function SpeciesDeleteController($scope, $uibModalInstance, entity, SpeciesService) {
        var vm = this;
        
        vm.items = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        let itemIds = [];

        init();

        function init() {
            itemIds = vm.items.map(item => item.id);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete() {
            if(appIds.length > 1) {
            	SpeciesService.deleteSpecies({ items: itemIds }, onSuccessDelete, onError);
            }else {
            	SpeciesService.delete({ id: itemIds[0] }, onSuccessDelete, onError);
            }
        }

        function onSuccessDelete() {
            $scope.$emit('arbolesDashboardApp:speciesDelete');
            clear();
        }

        function onError(err) {
            console.error(err);
        }
    }
})();
