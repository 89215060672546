(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngSanitize',
            'ngAnimate',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
//            'ui.sortable',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'btorfs.multiselect',
            'ngIntlTelInput',
            'ui-leaflet',
            'dragularModule',
            'ngMaterial',
            'oi.select',
            'angularMoment',
            'ui.tree',
            'ngclipboard',
            'toggle-switch',
            'chart.js',
            'angularTrix',
            'ui.tinymce'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler', '$rootScope', '$state', '$stateParams', '$templateCache'];

    function run(stateHandler, translationHandler, $rootScope, $state, $stateParams, $templateCache) {
        stateHandler.initialize();
        translationHandler.initialize();
//        $templateCache.removeAll();
        
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;
        $rootScope.$on("$stateChangeSuccess",  function(event, toState, toParams, fromState, fromParams) {
            // to be used for back button //won't work when page is reloaded.
            $rootScope.previousState_name = fromState.name;
            $rootScope.previousState_params = fromParams;
        });        
    }
    
    config.$inject = ['ngIntlTelInputProvider','$locale'];
    
    function config(ngIntlTelInputProvider,localeServiceProvider, StripeElementsProvider) {
    	ngIntlTelInputProvider.set({initialCountry: 'es',
    					preferredCountries: [ 'es', 'it', 'fr', 'de', 'pt' ],
    					separateDialCode: false, 
    					nationalMode: false,
    					autoPlaceholder: 'polite',
    			        separateDialCode: false,    					
    			        onlyCountries: ['al', 'dz', 'ad', 'am', 'at', 'az', 'by', 'be', 'ba', 'bg', 'hr', 'cy', 'cz', 'dk', 'eg', 'ee', 'fi', 'fr', 'ge', 'de', 'el', 'hu', 'is', 'ir', 'iq', 'ie', 'il', 'it', 'jo', 'lv', 'lb', 'ly', 'li', 'lt', 'lu', 'mk', 'mt', 'mr', 'md', 'mc', 'me', 'ma', 'nl', 'no', 'xx', 'ps', 'pl', 'pt', 'ro', 'ru', 'sm', 'sa', 'rs', 'sk', 'si', 'es', 'se', 'ch', 'sy', 'tn', 'tr', 'ua', 'uk'],
    					autoHideDialCode:true, 
    					utilsScript:'app/components/form/intlTelInputUtils.js'});
    
    	
        localeServiceProvider.configure({
            doys: {'es-es': 4},
            lang: {'es-es': {month: 'Mes', weekNb: 'número de la semana', addNew: 'Añadir'}},
            localeLocationPattern: '/vendor/angular-i18n/angular-locale_{{locale}}.js'
        });
        
        StripeElementsProvider.setAPIKey('pk_test_SDwALt5rzN7jWAi7UawZs8IO');
    }
    
    angular.module('arbolesDashboardApp').constant('uiDatetimePickerConfig', {
        dateFormat: 'dd/MM/yyyy HH:mm',
        momentFormat: 'DD/MM/YYYY hh:mm',
        defaultTime: '09:00:00',
        html5Types: {
            date: 'dd/MM/yyyy',
            'datetime-local': 'dd/MM/yyyyTHH:mm:ss.sss',
            'month': 'MM/yyyy'
        },
        initialPicker: 'date',
        reOpenDefault: false,
        enableDate: true,
        enableTime: false,
        buttonBar: {
            show: true,
            now: {
                show: true,
                text: 'Ahora',
                cls: 'btn-sm btn-default'
            },
            today: {
                show: true,
                text: 'Hoy',
                cls: 'btn-sm btn-default'
            },
            clear: {
                show: false,
                text: 'Limpiar',
                cls: 'btn-sm btn-default'
            },
            date: {
                show: true,
                text: 'Fecha',
                cls: 'btn-sm btn-default'
            },
            time: {
                show: true,
                text: 'Hora',
                cls: 'btn-sm btn-default'
            },
            close: {
                show: true,
                text: 'Cerrar',
                cls: 'btn-sm btn-default'
            },
            cancel: {
                show: false,
                text: 'Cancelar',
                cls: 'btn-sm btn-default'
            }
        },
        closeOnDateSelection: true,
        closeOnTimeNow: true,
        appendToBody: false,
        altInputFormats: [],
        ngModelOptions: {},
        saveAs: false,
        readAs: false,
        meridians:null,
        showMeridian: false,
        showSpinners: false
    });
    
    
    angular.module('arbolesDashboardApp').constant('uibTimepickerConfig', {
    	  hourStep: 1,
    	  minuteStep: 1,
    	  secondStep: 1,
    	  showMeridian: false,
    	  showSeconds: false,
    	  meridians: null,
    	  readonlyInput: false,
    	  mousewheel: true,
    	  arrowkeys: true,
    	  showSpinners: true,
    	  templateUrl: 'uib/template/timepicker/timepicker.html'
    	}) ;
    	
    
    angular.module('arbolesDashboardApp').constant('url_maps','https://www.google.com/maps/dir'); 
})();
