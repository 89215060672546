(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('CategoryDeleteController',CategoryDeleteController);

    CategoryDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'Category'];

    function CategoryDeleteController($scope, $uibModalInstance, entity, Category) {
        var vm = this;

        vm.categories = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.listOfCategories = false;
        var categoriesIds = [];
        if(vm.categories.length > 1){
        	vm.listOfCategories = true;
        }
        
        init();

        function init() {
        	categoriesIds = vm.categories.map(category => category.id);
        }        

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Category.deleteCategories({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
                
            if(vm.categories.length > 1) {
            	Category.deleteCategories({ categories: categoriesIds }, onSuccessDelete, onError);
            }else {
            	Category.delete({ id: vm.categories.id }, onSuccessDelete, onError);
            }                
        }
        
        function onSuccessDelete() {
            $scope.$emit('arbolesDashboardApp:categoryDelete');
            clear();
        }

        function onError(err) {
            console.error(err);
        }        
    }
})();
