(function() {
    'use strict';
    angular
        .module('arbolesDashboardApp')
        .factory('SpeciesService', SpeciesService);

    SpeciesService.$inject = ['$resource', 'DateUtils', 'SERVER_API_URL'];

    function SpeciesService ($resource, DateUtils, SERVER_API_URL) {
        var resourceUrl =  SERVER_API_URL + 'api/species/:token';

        return $resource(resourceUrl, {}, {
            'query': { 
            		method: 'GET', 
            		url:  SERVER_API_URL + 'api/species',
            		isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'deleteSpecies': { method: 'DELETE', isArray: true },
            
            'update': { 
            	method:'PUT',
            	headers: {
            		'Content-Type': 'application/json'
            	}
            },
            'deleteProperty':{
            		method: 'DELETE',
            		url: SERVER_API_URL + 'api/species/:token/property',
            		isArray: true
            },
            'savePropertyImage': {
            		method: 'PUT',
            		url: SERVER_API_URL + 'api/species/:token/image',
            		isArray: false,
                	headers: {
                		'Content-Type': 'application/json'
                	}            	
            },             
            'getProperty':{ 
    				method:'GET',
    				url: SERVER_API_URL + 'api/species/:token/property',
    				isArray: false
            },    
            'getPlants':{ 
				method:'GET',
				url: SERVER_API_URL + 'api/species/:token/plants',
				isArray: true
            },   
            'getImages':{ 
				method:'GET',
				url: SERVER_API_URL + 'api/species/:token/images',
				isArray: true
            },             
            'updateStatus': { 
            		method:'PUT', 
            		url:SERVER_API_URL + 'api/species/:token/status',
                	headers: {
                		'Content-Type': 'application/json'
                	} 
            },
            'saveImage': {
            	method: 'PUT',
            	url: SERVER_API_URL + 'api/species/:token/image',
            	headers: {
            		'Content-Type': 'application/json'
            	}
            }
        });
    }
})();
