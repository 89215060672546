(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('invoices', {
            parent: 'entity',
            url: '/invoices',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_DASHBOARD_ADMIN'],
                pageTitle: 'arbolesDashboardApp.userBusiness.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice/invoices.html',
                    controller: 'InvoiceController',
                    controllerAs: 'vm'
                }
            },
			params: {
				tabs: 0,
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'invoiceNumber,desc',
                    squash: true
                }
            },               
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],            	
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('validation');
                    $translatePartialLoader.addPart('billing');
                    $translatePartialLoader.addPart('global');
                    
                    return $translate.refresh();
                }]
            }
        })
    }

})();
