(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('SignoutAccountSuccessController', SignoutAccountSuccessController);


    SignoutAccountSuccessController.$inject = ['$translate', '$window', '$timeout', '$state', 'UserAccountModel'];

    function SignoutAccountSuccessController ($translate, $window, $timeout, $state, UserAccountModel) {
        var vm = this;
        $window.loading_screen.finish();
        
        vm.invitation = UserAccountModel.userAccount;
    }
})();
