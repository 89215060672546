(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('UserGroupDeleteController',UserGroupDeleteController);

    UserGroupDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'UserGroup'];

    function UserGroupDeleteController($scope, $uibModalInstance, entity, UserGroup) {
        var vm = this;

        vm.groups = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.listOfGroups = false;
        var groupsIds = [];
        if(vm.groups.length > 1){
        	vm.listOfGroups = true;
        }
        
        init();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            UserGroup.deleteGroups({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
                
            if(vm.groups.length > 1) {
            	UserGroup.deleteGroups({ groups: groupsIds }, onSuccessDelete, onError);
            }else {
            	UserGroup.delete({ id: vm.groups.id }, onSuccessDelete, onError);
            }                  
        }
        
        
        function onSuccessDelete() {
            $scope.$emit('arbolesDashboardApp:groupsDelete');
            clear();
        }

        function onError(err) {
            console.error(err);
        }           
    }
})();
