(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('AnswerController', AnswerController);

    AnswerController.$inject = ['AnswerService','AuthServerProvider', '$q', '$translate','$scope', '$rootScope', '$state','$sce','AlertService', 'pagingParams', 'paginationConstants', '$uibModal'];

    function AnswerController(AnswerService, AuthServerProvider, $q, $translate, $scope, $rootScope, $state, $sce, AlertService, pagingParams, paginationConstants, $uibModal) {

        var vm = this;
        
        
        /*----------------------------- FUNCTIONS--------------------------------*/
        vm.answers = [];
        vm.itemsSelected = [];
        vm.loading = false;
        
        vm.page = pagingParams.page - 1;
        vm.reset = reset ;
        vm.loadPage = loadPage;

        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.editChecked = editChecked;

        vm.selectAll = selectAll;
        vm.unSelectAll = unSelectAll;
        vm.toggleItem = toggleItem;
        vm.isChecked = isChecked;
        vm.syncCheck = syncCheck;
        vm.checkActive = false;
        vm.checkOnlyOneActive = false;
        vm.hasNextPage = true;

        vm.showEmpty = false;
        
        vm.goToDetail = goToDetail;
        vm.showDeleteModal = showDeleteModal;
        vm.renderHtml = renderHtml;
        

        angular.element(document).ready(function () { 
        	loadPage(0);
        });


        /*----------------------------- LOADS APPs --------------------------------*/
        function loadItems() {
        		AnswerService.query({
                    page: vm.page,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
// }
            
        }
        
        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.hasNextPage = headers('X-Has-Next-Page') === "true";
            vm.queryCount = vm.totalItems;
            
            for (var i = 0; i < data.length; i++) {
                vm.answers.push(data[i]);
            }
            vm.showEmpty = true;
        	vm.loading = false;   
        }

        function onError(error) {
            AlertService.error(error.data.message);
            vm.loading = false;
            vm.showEmpty = true;
        }

        var answerDelete = $scope.$on('arbolesDashboardApp:answerDelete', function(event, result){
            reset();
        });  
        $scope.$on('$destroy',answerDelete);
        
		
        function toggleItem(item) {
            var index = vm.itemsSelected.indexOf(item);
            syncCheck(!isChecked(item),item);
        }

        function isChecked(item){
            for (var i = 0; i < vm.itemsSelected.length; i++){
                if (vm.itemsSelected[i].token == item.token){
                    return true;
                }
            }
            
            return false;
        }
        
        function syncCheck(bool, item) {
            if(bool){
                // add item
                vm.itemsSelected.push(item);
              } else {
                // remove item
                for (var i = 0; i < vm.itemsSelected.length; i++){
                  if(vm.itemsSelected[i].token == item.token){
                	  vm.itemsSelected.splice(i,1);
                  }
                }      
              }
            vm.checkActive = vm.itemsSelected.length>0;
            vm.checkOnlyOneActive = vm.itemsSelected.length===1;
        }

        function selectAll() {
            vm.itemsSelected = [];
            for (var i = 0; i < vm.parks.length; ++i) {
                vm.itemsSelected.push(vm.parks[i]);
            }

            vm.checkActive = vm.itemsSelected.length>0;
            vm.checkOnlyOneActive = vm.itemsSelected.length===1;
        }

        function unSelectAll() {
            vm.itemsSelected = [];
            vm.checkActive = false;
            vm.checkOnlyOneActive = false;
        }     
                
        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        
        function reset () {
            vm.answers = [];
            vm.showEmpty = false;
            vm.page = 1;
            loadPage(vm.page);
            unSelectAll();
        }

        function loadPage(page) {
            if (vm.loading) return;
            
            vm.loading = true;
            vm.page = page;
            loadItems(); 
        } 

        function editChecked(){
        	$state.go('answer.edit', { token: vm.itemsSelected[0].id });
        }
        
        function goToDetail(entity){
    		$state.go('answer-detail', { id:entity.id });
        }
        
        function showDeleteModal(items) {
			if (window.event) {
				window.event.stopPropagation();
            }
			
			var modalInstance = $uibModal
                .open({
                    templateUrl : 'app/entities/answer/answer-delete-dialog.html',
                    controller : 'AnswerDeleteController',
                    controllerAs : 'vm',
                    scope : $scope, // passed current scope to the modal
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        dataState : function() {
                            return {
                                createLabel : 'arbolesDashboardApp.invitation.accept'
                            };
                        },
                        translatePartialLoader : [
                                '$translate',
                                '$translatePartialLoader',
                                function($translate,$translatePartialLoader) {
                                    $translatePartialLoader.addPart('answer');
                                    return $translate.refresh();
                                } ],
                        entity: () => items
                    }
                });

		}
        
        function renderHtml(html_code){
        	return $sce.trustAsHtml(html_code);
        }      
    }
    
 
    
})();
