(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('NotFoundController', NotFoundController);

    NotFoundController.$inject = ['$window'];

    function NotFoundController ($window) {
        var vm = this;
        
        $window.loading_screen.finish();
    }
})();
