(function() {
	'use strict';
	angular.module('arbolesDashboardApp').factory('QuizService', QuizService);

	QuizService.$inject = [ '$resource', 'DateUtils', 'SERVER_API_URL' ];

	function QuizService($resource, DateUtils, SERVER_API_URL) {
		var resourceUrl = SERVER_API_URL + 'api/quizs/:token';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				url : SERVER_API_URL + 'api/quizs',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'deleteQuiz' : {
				method : 'DELETE',
				isArray : true
			},
			'update' : {
				method : 'PUT',
				headers : {
					'Content-Type' : 'application/json'
				}
			},
			'updateStatus' : {
				method : 'PUT',
				url : SERVER_API_URL + 'api/quizs/:token/status',
				headers : {
					'Content-Type' : 'application/json'
				}
			},
			
			'save' : {
				method : 'POST',
				headers : {
					'Content-Type' : 'application/json'
				}
			},
			'saveImage': {
				method: 'PUT',
				url: SERVER_API_URL + 'api/quizs/:token/image',
				headers: {
					'Content-Type': 'application/json'
				}
			},
			'getQuestions' : {
				method : 'GET',
				url : SERVER_API_URL + 'api/quizs/:token/questions',
				isArray : true
			},		
			'getQuestion' : {
				method : 'GET',
				url : SERVER_API_URL + 'api/quizs/:token/question/:tokenQuestion',
				isArray : false
			}
		});
	}
})();
