(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('statistics', {
            parent: 'entity',
            url: '/statistics',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'arbolesDashboardApp.product.home.title'
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                }
            },             
            views: {
                'content@': {
                    templateUrl: 'app/entities/statistics/statistics.html',
                    controller: 'StatisticsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],               	
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('statistics');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('statistics-detail-business', {
            parent: 'statistics',
            url: '/statistics/business/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'arbolesDashboardApp.product.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/statistics/statistics-detail-business.html',
                    controller: 'StatisticsDetailBusinessController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('statistics');
                    $translatePartialLoader.addPart('publishStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Business', function($stateParams, Business) {
                    return Business.get({id : $stateParams.id}).$promise;
                }]
            }
        });
    }

})();
