(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('Account', Account);

    Account.$inject = ['$resource', 'SERVER_API_URL'];

    function Account ($resource, SERVER_API_URL) {
        var service = $resource(SERVER_API_URL + 'api/account', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            },
            'getAccount': {
            	method: 'GET',
            	url: 'api/account/detail',
            	isArray: false
            },
            'getUserProfile': {
        		method: "GET",
        		url: 'api/user/profile',
        		isArray: false
	        },
	        'getUserProfileConfiguration': {
	    		method: "GET",
	    		url: 'api/user/profile/config',
	    		isArray: false
	        }          
        });

        return service;
    }
})();
