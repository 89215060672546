(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('CategoryDeleteSubcategoryController', CategoryDeleteSubcategoryController);

    CategoryDeleteSubcategoryController.$inject = ['Category', 'Principal', 'entity', 'category', 'AlertService', '$scope', '$state', '$stateParams', '$uibModalInstance'];

    function CategoryDeleteSubcategoryController(Category, Principal, entity, category, AlertService, $scope, $state, $stateParams, $uibModalInstance) {

        var vm = this;

        vm.category = category;
        vm.subcategories = entity;
        vm.clear = clear;
        vm.deleteSubcategory = deleteSubcategory;

        vm.isSaving = false;
        vm.state = $state.current;
   
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
   
        function deleteSubcategory () {
        		vm.isSaving = true;
        		vm.subcategoryIds = [];
        		
        		for(var index  =  0; index < vm.subcategories.length; index++){
        			vm.subcategoryIds.push(vm.subcategories[index].id);
        		}
        		
        		Category.deleteSubcategory({id:vm.category.id, subcategories:vm.subcategoryIds},onDeleteSuccess, onDeleteError);
        }
    
        function onDeleteSuccess(result) {
        		console.log('onDeleteSuccess');
        		$scope.$emit('arbolesDashboardApp:categorySubcategoryDelete', result);
		
        		vm.isSaving = false;
        		$uibModalInstance.close();
        }

        function onDeleteError () {
        		vm.isSaving = false;
        }
    }
})();
