(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage'];

    function authInterceptor ($rootScope, $q, $location, $localStorage, $sessionStorage) {
        var service = {
            request: request
        };

        return service;

        function request (config) {
            /*jshint camelcase: false */
            config.headers = config.headers || {};
            var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            }
            
            var app  = $localStorage.app || $sessionStorage.app;
            if (app) {	
            	config.headers.APP_KEY=app.appKey;
            }
            return config;
        }
    }
})();
