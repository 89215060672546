(function() {
    'use strict';

    angular
        .module('arbolesDashboardApp')
        .controller('SignoutAccountController', SignoutAccountController);
    
    
    SignoutAccountController.$inject = ['$translate', '$window', '$timeout', 'Register', 'entity', '$state', '$scope', '$rootScope', '$browser', '$location', 'UserAccountModel', 'registerInterceptor', '$injector', '$uibModal', '$stateParams'];

    function SignoutAccountController ($translate, $window, $timeout, Register, entity, $state, $scope, $rootScope, $browser, $location, UserAccountModel, registerInterceptor, $injector, $uibModal, $stateParams) {
        var vm = this;

        $window.loading_screen.finish();
        
        vm.init=init;
        UserAccountModel.userAccount = entity;

        vm.userAccount = UserAccountModel.userAccount;
        vm.state = $state.current;
        vm.goStep1 = goStep1;
        
        vm.doNotMatch = null;
        vm.error = false;
        vm.errorUserExists = false;
        vm.doNotMatch = false;
        vm.login = login;
        vm.acceptLegal = acceptLegal;
        vm.success = false;
        vm.isSaving = false;
        vm.valid = false;

        $timeout(function (){ angular.element('.form-group:eq(1)>input').focus();});

        var unsubscribe = $rootScope.$on('arbolesDashboardApp:registerLegalAccept', function(event, result) {
            //--- registro del usuario y 
        		register();
        });    
        
        init();
		
        $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {	
            if (toState.data && toState.data.redirect) {
              var redirectTo = $injector.invoke(toState.data.redirect, this, {
                toStateParams: toParams,
                toState: toState
              });

              if (redirectTo) {
                event.preventDefault();
                $state.transitionTo(redirectTo);
              }
            }
        });
        
        
        function init(){
        		checkDataBetweenSteps();
        }
        
        function checkDataBetweenSteps(){
      		if (vm.state.name!='account-signout.step'){
      			if (!vm.userAccount.firstName || vm.valid===false){
      				UserAccountModel.userAccount = vm.userAccount;
      				goStep1();
      			}
      		}
        }
        
        function acceptLegal(form){
	    		if (form.$invalid){ 
	    			return; 
	    		}   
	    		showLegalModal();
        }
        
        function register () { 
        		vm.isSaving = true;
                vm.userAccount.langKey = $translate.use();
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                vm.userAccount.urlToken = vm.invitation.token;
                UserAccountModel.userAccount = vm.userAccount;

                Register.registerMember(vm.userAccount,onSaveSuccess,onSaveError);
        }
        
        
        function onSaveSuccess(result){
        	vm.success = 'OK';
            vm.isSaving = false;
            $state.transitionTo('account-signout-success');
        }
        
        function onSaveError(response){
        	vm.isSaving = false;
            vm.success = null;
            if (response.status === 400 && response.data === 'login already in use') {
                vm.errorUserExists = 'ERROR';
            } else if (response.status === 400 && response.data === 'email address already in use') {
                vm.errorEmailExists = 'ERROR';
            }
                
        }
        
        function goStep1(){
        		UserAccountModel.userAccount = vm.userAccount;
        		$state.transitionTo('account-signout.step',{token:$stateParams.token}).then(function() {
        			vm.state = $state.current;
        		});
        }                      

        
        function login() {
            $state.go('login');
        }
        
        
        vm.inputPasswordType = 'password';
        vm.hideShowPassword = hideShowPassword;      
        
        function hideShowPassword (){
        		vm.inputPasswordType = vm.inputPasswordType == 'password'?'text':'password';
        }
        
		
		$scope.$watch("vm.userAccount.email", function(newValue, oldValue){
			$scope.form.email.$setValidity('check', true);
		});		

		
		
		//-- Modal aceptación de condiciones
		
        function showLegalModal(){
			
			if (window.event) {
				window.event.stopPropagation();
			}
			
			var modalInstance = $uibModal
					.open({
						templateUrl : 'app/public/account/signout-legal-dialog.html',
						controller : 'SignoutAccountLegalController',
						controllerAs : 'vm',
						scope : $scope, // passed current scope to the modal
						backdrop : 'static',
						size : 'lg',
						resolve : {
							dataState : function() {
								return {
									createLabel : 'arbolesDashboardApp.invitation.accept'
								};
							},
							translatePartialLoader : [
									'$translate',
									'$translatePartialLoader',
									function($translate,$translatePartialLoader) {
										$translatePartialLoader.addPart('register');
										return $translate.refresh();
									} ]
						}									
					});
		}			
    }
})();
